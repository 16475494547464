import { GeneralError } from '../constants/errors/authentication_errors';

export const getAuthenticationErrorMessage = (errorCode?: string) => {
  switch (errorCode) {
    case 'auth/too-many-requests':
      return GeneralError.tooManyAttempts;
    case 'auth/user-not-found':
    case 'auth/wrong-password':
      return GeneralError.incorrect;
    case 'auth/email-already-in-use':
      return GeneralError.alreadyTaken;
  }
};
