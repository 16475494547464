import React from 'react';
import { colors } from '../constants/colors';
import { IconProps } from './IconProps';

export const CheckIcon = ({ margin, color, size }: IconProps) => (
  <svg
    style={{ margin }}
    width={size || 16}
    height={size || 16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.3333 4L5.99996 11.3333L2.66663 8"
      stroke={color || colors.primaryText}
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
