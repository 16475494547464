import React from 'react';
import styled from 'styled-components';
import { colors } from '../../constants/colors';
import { Animations } from '../../constants/animations';

import { Overlay } from './Overlay';
import { Text } from './Text';
import { Button } from './Button';

import {
  useKeyboardShortcut,
  KeyboardShortcutKey
} from '../../hooks/useKeyboardShortcut';

type ModalProps = {
  children?: React.ReactNode;
  title?: string;
  description?: string;
  action: {
    title: string;
    handler: () => void;
    isDestructive?: boolean;
    isDisabled?: boolean;
  };
  secondaryAction?: {
    title: string;
    handler: () => void;
  };
  hideCancel?: boolean;
  hideSelf: () => void;
};

export const Modal = (props: ModalProps) => {
  useKeyboardShortcut(KeyboardShortcutKey.Escape, props.hideSelf);
  useKeyboardShortcut(KeyboardShortcutKey.Enter, props.action.handler);

  return (
    <styles.container>
      <Overlay onMouseDown={props.hideSelf} />
      <styles.modalBase>
        {props.title && (
          <Text wrapText size={'title'}>
            {props.title}
          </Text>
        )}
        {props.description && (
          <Text wrapText margin={'16px 0px 0px 0px'}>
            {props.description}
          </Text>
        )}
        {props.children}
        {props.action && (
          <styles.buttonContainer>
            <div>
              {props.secondaryAction && (
                <Text
                  color={'blueberry'}
                  cursor={'pointer'}
                  onClick={props.secondaryAction.handler}
                >
                  {props.secondaryAction.title}
                </Text>
              )}
            </div>
            <div>
              {!props.hideCancel && (
                <Button XL margin={'0px 10px 0px 0px'} onClick={props.hideSelf}>
                  Cancel
                </Button>
              )}
              <Button
                XL
                isDestructive={props.action?.isDestructive}
                isDisabled={props.action?.isDisabled}
                primary
                onClick={props.action.handler}
              >
                {props.action?.title}
              </Button>
            </div>
          </styles.buttonContainer>
        )}
      </styles.modalBase>
    </styles.container>
  );
};

const styles = {
  container: styled.div`
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 400;
  `,
  buttonContainer: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 32px;
  `,
  modalBase: styled.div`
    width: 100%;
    z-index: 400;
    max-width: 400px;
    max-height: calc(100% - 48px);
    margin: 24px;
    box-sizing: border-box;
    padding: 28px 32px 32px 32px;
    background: ${colors.base};
    border-radius: 12px;
    box-shadow: inset 0px 0px 0px 1px ${colors.W10},
      0px 8px 24px ${colors.dropShadow};
    overflow: auto;
    ${Animations.BounceIn}
  `
};
