import { PREVIEW_URL } from '../constants/urls';
import { IFiles, IDependencies } from '../declarations/Preview';
import { PreviewAction } from '../declarations/PreviewAction';

class PreviewManager {
  private _previewFrame: HTMLIFrameElement;
  private _activeFilePath: string = '';

  constructor(frame: HTMLIFrameElement) {
    this._previewFrame = frame;
  }

  updatePreview = (playgroundData: {
    files: IFiles;
    dependencies: IDependencies;
  }) => {
    const filesArr = Object.keys(playgroundData.files).map(fileKey => ({
      code: playgroundData.files[fileKey].code,
      path: fileKey
    }));

    if (PREVIEW_URL) {
      this._previewFrame.contentWindow?.postMessage(
        {
          type: PreviewAction.compile,
          files: filesArr,
          dependencies: playgroundData.dependencies,
          activeFilePath: this._activeFilePath
        },
        PREVIEW_URL
      );
    }
  };

  refreshPreview = () => {
    if (PREVIEW_URL) {
      this._previewFrame.contentWindow?.postMessage(
        {
          type: PreviewAction.refresh
        },
        PREVIEW_URL
      );
    }
  };

  setActiveFile = (activeFilePath: string) => {
    this._activeFilePath = activeFilePath;
  };
}

export default PreviewManager;
