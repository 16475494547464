import { PlaygroundInfo } from '../declarations/PlaygroundInfo';

const MINUTE_IN_SECONDS = 60;
const HOUR_IN_SECONDS = 3600;
const DAY_IN_SECONDS = 86400;
const MONTH_IN_SECONDS = 2592000;
const YEAR_IN_SECONDS = 31104000;

const convertToMinutes = (seconds: number) =>
  Math.floor(seconds / MINUTE_IN_SECONDS);
const convertToHours = (seconds: number) =>
  Math.floor(seconds / HOUR_IN_SECONDS);
const convertToDays = (seconds: number) => Math.floor(seconds / DAY_IN_SECONDS);
const convertToMonths = (seconds: number) =>
  Math.floor(seconds / MONTH_IN_SECONDS);

export const getHomePlaygroundRowDescription = (
  playgroundInfo: PlaygroundInfo,
  isRecents: boolean
) => {
  const lastViewed = playgroundInfo.lastViewed?.seconds ?? 0;
  const lastModified = playgroundInfo.lastModified?.seconds ?? 0;

  const UTCMilliseconds = Date.now();
  const lastViewedTimeInSeconds = UTCMilliseconds / 1000 - lastViewed;
  const lastModifiedTimeInSeconds = UTCMilliseconds / 1000 - lastModified;

  if (isRecents) {
    if (lastViewedTimeInSeconds < MINUTE_IN_SECONDS) {
      return `Just now`;
    }

    if (lastViewedTimeInSeconds < HOUR_IN_SECONDS) {
      const minutes = convertToMinutes(lastViewedTimeInSeconds);
      if (minutes < 2) {
        return `1 min ago`;
      }
      return `${minutes} mins ago`;
    }

    if (lastViewedTimeInSeconds < DAY_IN_SECONDS) {
      const hours = convertToHours(lastViewedTimeInSeconds);
      if (hours < 2) {
        return `1 hour ago`;
      }
      return `${hours} hours ago`;
    }

    if (lastViewedTimeInSeconds < MONTH_IN_SECONDS) {
      const days = convertToDays(lastViewedTimeInSeconds);
      if (days < 2) {
        return `Yesterday`;
      }
      return `${days} days ago`;
    }

    if (lastViewedTimeInSeconds < YEAR_IN_SECONDS) {
      const months = convertToMonths(lastViewedTimeInSeconds);
      if (months < 2) {
        return `1 month ago`;
      }
      return `${months} months ago`;
    }

    return `1 year ago`;
  } else {
    if (lastModifiedTimeInSeconds < MINUTE_IN_SECONDS) {
      return `Edited just now`;
    }

    if (lastModifiedTimeInSeconds < HOUR_IN_SECONDS) {
      const minutes = convertToMinutes(lastModifiedTimeInSeconds);
      if (minutes < 2) {
        return `Edited 1 min ago`;
      }
      return `Edited ${minutes} mins ago`;
    }

    if (lastModifiedTimeInSeconds < DAY_IN_SECONDS) {
      const hours = convertToHours(lastModifiedTimeInSeconds);
      if (hours < 2) {
        return `Edited 1 hour ago`;
      }
      return `Edited ${hours} hours ago`;
    }

    if (lastModifiedTimeInSeconds < MONTH_IN_SECONDS) {
      const days = convertToDays(lastModifiedTimeInSeconds);
      if (days < 2) {
        return `Edited yesterday`;
      }
      return `Edited ${days} days ago`;
    }

    if (lastModifiedTimeInSeconds < YEAR_IN_SECONDS) {
      const months = convertToMonths(lastModifiedTimeInSeconds);
      if (months < 2) {
        return `Edited 1 month ago`;
      }
      return `Edited ${months} months ago`;
    }

    return `Edited 1 year ago`;
  }
};
