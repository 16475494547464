import React from 'react';
import styled from 'styled-components';

import { colors } from '../../constants/colors';
import { Button } from '../general/Button';
import { Text } from '../general/Text';

type HomeTitleBarProps = {
  title: string;
  onCreateNewPlayground?: () => void;
};

export const HomeTitleBar = (props: HomeTitleBarProps) => {
  const onCreateNewPlayground = () => {
    props.onCreateNewPlayground && props.onCreateNewPlayground();
  };

  return (
    <styles.containerWrapper>
      <styles.container>
        <Text
          margin={'8px 16px 0px 0px'}
          ellipsis
          flexShrink={1}
          capitalize
          size={'titleXL'}
        >
          {props.title}
        </Text>
        {props.onCreateNewPlayground && (
          <Button
            margin={'10px 0px 0px 0px'}
            onClick={onCreateNewPlayground}
            primary
          >
            New
          </Button>
        )}
      </styles.container>
    </styles.containerWrapper>
  );
};

const styles = {
  containerWrapper: styled.div`
    width: calc(100vw - 240px);
    position: absolute;
    top: 0px;
    z-index: 1;
    min-height: 56px;
  `,
  container: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: ${colors.base};

    box-shadow: 18px 0px 0px ${colors.base}, -18px 0px 0px ${colors.base};

    padding-top: 48px;
    padding-bottom: 24px;
    margin-right: 80px;
    margin-left: 80px;

    @media (max-width: 840px) {
      margin-left: 13%;
      margin-right: 13%;
    }

    @media (max-width: 500px) {
      margin-left: 44x;
      margin-right: 44px;
    }
  `
};
