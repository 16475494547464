import {
  RECENTS_FOLDER_ID,
  DRAFTS_FOLDER_ID,
  INTERVIEWS_FOLDER_ID
} from '../constants/keys';
import { FileType } from '../declarations/FileType';

export const getBaseTeamFolders = ({
  draftsRef,
  recentsRef,
  interviewsRef
}: {
  draftsRef: firebase.firestore.Query;
  recentsRef: firebase.firestore.Query;
  interviewsRef?: firebase.firestore.Query;
}) => {
  const baseFolders = [
    {
      id: RECENTS_FOLDER_ID,
      name: RECENTS_FOLDER_ID,
      attributes: { isRecents: true },
      playgroundCollectionRef: recentsRef,
      templateCollectionRef: draftsRef.where('type', '==', FileType.Template)
    },
    {
      id: DRAFTS_FOLDER_ID,
      name: DRAFTS_FOLDER_ID,
      playgroundCollectionRef: draftsRef.where(
        'type',
        '==',
        FileType.Playground
      ),
      templateCollectionRef: draftsRef.where('type', '==', FileType.Template)
    }
  ];

  if (interviewsRef) {
    baseFolders.push({
      id: INTERVIEWS_FOLDER_ID,
      name: INTERVIEWS_FOLDER_ID,
      playgroundCollectionRef: interviewsRef.where(
        'type',
        '==',
        FileType.Playground
      ),
      templateCollectionRef: interviewsRef.where(
        'type',
        '==',
        FileType.Template
      )
    });
  }

  return baseFolders;
};
