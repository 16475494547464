import { useContext, useCallback } from 'react';

import { FirebaseContext } from '../Firebase';

import { PlaygroundInfo } from '../declarations/PlaygroundInfo';

const useDeletePlayground = () => {
  const firebase = useContext(FirebaseContext);

  const deletePlayground = useCallback(
    async (playground: PlaygroundInfo) => {
      if (!firebase) {
        return;
      }

      const firestore = firebase.firestore();

      return firestore.doc(`playgrounds/${playground.id}`).delete();
    },
    [firebase]
  );

  return deletePlayground;
};

export default useDeletePlayground;
