import React from 'react';
import styled from 'styled-components';

import { useToggle } from '../../../hooks/useToggle';
import { Avatar } from '../../general/Avatar';
import { PlaygroundFacepileDropdown } from './PlaygroundFacepileDropdown';

type PlaygroundFacepileAvatarProps = {
  image: string;
  index: number;
  color: string;
  name: string;
  isCurrentUser: boolean;
};

export const PlaygroundFacepileAvatar = (
  props: PlaygroundFacepileAvatarProps
) => {
  const {
    value: isTooltipVisible,
    setTrue: showTooltip,
    setFalse: hideTooltip
  } = useToggle(false);

  return (
    <styles.container index={props.index}>
      <Avatar
        name={props.name}
        onMouseEnter={showTooltip}
        onMouseLeave={hideTooltip}
        src={props.image}
        zIndex={200 - props.index}
      />
      {isTooltipVisible && (
        <PlaygroundFacepileDropdown
          isCurrentUser={props.isCurrentUser}
          color={props.color}
          name={props.name}
        />
      )}
    </styles.container>
  );
};

const styles = {
  container: styled.div<Pick<PlaygroundFacepileAvatarProps, 'index'>>`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0px -4px 0px 0px;
    height: 30px;
    width: 30px;
    z-index: ${props => 100 - props.index};
  `
};
