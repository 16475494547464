import React, { useRef } from 'react';
import {
  CardElement,
  injectStripe,
  ReactStripeElements
} from 'react-stripe-elements';
import useStripeFirestore from './hooks/useStripeFirestore';

const style = {
  base: {
    color: '#32325d',
    fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
    fontSmoothing: 'antialiased',
    fontSize: '16px',
    '::placeholder': {
      color: '#ffffff'
    }
  },
  invalid: {
    color: '#fa755a',
    iconColor: '#fa755a'
  }
};

type PaymentProps = ReactStripeElements.InjectedStripeProps & {
  userId: string;
};
const Payment = (props: PaymentProps) => {
  const { addPaymentMethod, purchaseSubscription } = useStripeFirestore();
  const cardRef = useRef<stripe.elements.Element | null>(null);

  const onSubmit = async () => {
    if (!cardRef.current) {
      return;
    }

    const paymentMethodResponse = await props.stripe?.createPaymentMethod({
      type: 'card',
      card: cardRef.current
    });

    const paymentMethod = paymentMethodResponse?.paymentMethod;
    const error = paymentMethodResponse?.error;
    if (error || !paymentMethod) {
      return { error: error || 'Error creating payment method' };
    }
    const res = await addPaymentMethod(paymentMethod.id);
  };

  const handlePurchase = async () => {
    console.log(
      'sub: ',
      await purchaseSubscription({
        teamId: 'ZCe9LD0dSPUkS82fbCrL', // TODO: pass in team id when upgrading
        paymentMethodId: 'pm_1GFv1fKF7Hj6DAAmkiF0TqO4'
      })
    );
  };

  return (
    <div style={{ width: 300, margin: 'auto' }}>
      <label>
        Hey
        <CardElement
          onReady={ref => {
            cardRef.current = ref;
          }}
          style={style}
        ></CardElement>
        <button onClick={onSubmit}>do it</button>
        <button onClick={handlePurchase}>upgade</button>
      </label>
    </div>
  );
};

export default injectStripe(Payment);
