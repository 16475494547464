import { editor as monaco, Range, Uri } from 'monaco-editor';

import { TextOperation, MonacoAdapter } from '.';
import { createModel } from '../utilities/EditorUtilities';

export default class MonacoFileAdapter {
  ignoreChanges;
  lastDocLines: Array<string>;

  callbacks;

  monacoModel?: monaco.ITextModel | null;

  constructor(fileName: string, contents?: string) {
    var existingModel: monaco.ITextModel | null | undefined = monaco.getModel(
      Uri.parse(`file:///${fileName}`)
    );

    if (!existingModel || existingModel.isDisposed()) {
      existingModel = createModel(fileName, contents || '');
    }

    this.monacoModel = existingModel;
    this.lastDocLines = [];
  }

  /**
   * @method detach - Clears an Instance of Editor Adapter
   */
  detach = () => {
    this.monacoModel = null;
    this.callbacks = {};
  };

  onFileNameChanged = (newName: string) => {
    const oldModel = this.monacoModel;
    var newModel: monaco.ITextModel | null | undefined = monaco.getModel(
      Uri.parse(`file:///${newName}`)
    );

    if (!newModel || newModel.isDisposed()) {
      newModel = createModel(newName, this.monacoModel?.getValue() || '');
    }

    this.monacoModel = newModel;
    oldModel?.dispose();
  };

  /**
   * Apply operation to model from Monaco
   * Send to server
   *
   * Called in bridge file
   * @method onChange - OnChange Event Handler
   * @param {Object} event - OnChange Event Delegate
   */
  onChange = (event: monaco.IModelContentChangedEvent) => {
    if (!this.ignoreChanges) {
      var content = this.lastDocLines.join(this.monacoModel?.getEOL() || '');

      /** If no change information recieved */
      if (!event.changes) {
        var op = new TextOperation().retain(content.length);
        // triggers change in FileEditorClient
        this.trigger('change', op, op);
      }

      const changes = MonacoAdapter.convertChangeEventToOperation(
        event,
        content
      );

      this.trigger('change', ...changes);
      /** Update Editor Content */
      this.lastDocLines = this.monacoModel?.getLinesContent() || [];
    }
  };

  /**
   * Apply operation to model from server
   *
   * @method applyOperation
   * @param {Operation} operation - OT.js Operation Object
   */
  applyOperation = (operation: TextOperation) => {
    if (!operation.isNoop()) {
      this.ignoreChanges = true;
    }

    /** Get Operations List */
    var opsList = operation.ops;
    var index = 0;

    opsList.forEach(op => {
      /** Retain Operation */
      if (op.isRetain()) {
        index += op.chars;
      } else if (op.isInsert()) {
        /** Insert Operation */
        if (this.monacoModel) {
          var pos = this.monacoModel.getPositionAt(index);

          this.monacoModel?.pushEditOperations(
            [],
            [
              {
                range: new Range(
                  pos.lineNumber,
                  pos.column,
                  pos.lineNumber,
                  pos.column
                ),
                text: op.text,
                forceMoveMarkers: true
              }
            ],
            () => []
          );
        }

        index += op.text.length;
      } else if (op.isDelete()) {
        /** Delete Operation */
        if (this.monacoModel) {
          var from = this.monacoModel.getPositionAt(index);
          var to = this.monacoModel.getPositionAt(index + op.chars);

          this.monacoModel.pushEditOperations(
            [],
            [
              {
                range: new Range(
                  from.lineNumber,
                  from.column,
                  to.lineNumber,
                  to.column
                ),
                text: '',
                forceMoveMarkers: true
              }
            ],
            () => []
          );
        }
      }
    });

    /** Update Editor Content and Reset Config */
    this.lastDocLines = this.monacoModel?.getLinesContent() || [];
    this.ignoreChanges = false;
  };

  /**
   * @method registerCallbacks - Assign callback functions to internal property
   * @param {function[]} callbacks - Set of callback functions
   */
  registerCallbacks = callbacks => {
    this.callbacks = Object.assign({}, this.callbacks, callbacks);
  };

  /**
   * @method trigger - Event Handler
   * @param {string} event - Event name
   * @param  {...any} args - Callback arguments
   */
  trigger = (event: string, ...args: Array<any>) => {
    if (!this.callbacks.hasOwnProperty(event)) {
      return;
    }

    var action = this.callbacks[event];

    // TODO: TS Fix this
    if (typeof action !== 'function') {
      return;
    }

    action(...args);
  };
}
