import { colors } from '../../constants/colors';
import styled from 'styled-components';

type ButtonProps = {
  margin?: string;
  primary?: boolean;
  isDisabled?: boolean;
  XL?: boolean;
  isDestructive?: boolean;
};

export const Button = styled.button<ButtonProps>`
  font-size: 13px;
  line-height: 18px;
  font-weight: ${props => (props.primary ? 700 : 600)};
  color: ${props => (props.primary ? colors.white : colors.primaryText)};
  border-radius: 40px;
  border: none;
  outline: none;
  background: ${props =>
    props.primary ? background('default', props.isDestructive) : colors.W4};
  padding: ${props => (props.XL ? '10px 16px' : '6px 13px')};
  margin: ${props => props.margin || 0};
  cursor: pointer;
  user-select: none;
  transition: all 0.12s;
  pointer-events: ${props => (props.isDisabled ? 'none' : 'default')};
  filter: ${props => (props.isDisabled ? 'saturate(0%)' : null)};
  background: ${props => (props.isDisabled ? colors.W8 : null)};
  opacity: ${props => (props.isDisabled ? 0.5 : 1)};
  flex-shrink: 0;

  :hover {
    background: ${props =>
      props.primary ? background('hover', props.isDestructive) : colors.W6};
  }

  :hover:active {
    background: ${props =>
      props.primary ? background('active', props.isDestructive) : colors.W8};
  }
`;

const background = (
  state: 'default' | 'hover' | 'active',
  isDestructive: boolean = false
) => {
  switch (state) {
    case 'default':
      return isDestructive ? colors.strawberry : colors.blueberry;
    case 'hover':
      return isDestructive ? colors.strawberryHover : colors.blueberryHover;
    case 'active':
      return isDestructive ? colors.strawberryPressed : colors.blueberryPressed;
  }
};
