import { useEffect, useMemo } from 'react';

import { PlaygroundInfo } from '../declarations/PlaygroundInfo';
import { Team } from '../declarations/Team';
import { PlaygroundPermissions } from '../declarations/PlaygroundPermissions';
import { getPlaygroundPermissions } from '../utilities/TeamPermissionsUtilities';

const useAutoJoinPlayground = ({
  currentUser,
  playgroundMembers,
  teamMembers,
  defaultPermissions = PlaygroundPermissions.View,
  updateMemberAccess
}: {
  currentUser: firebase.User;
  playgroundMembers: PlaygroundInfo['members'];
  defaultPermissions?: PlaygroundPermissions;
  teamMembers: Team['members'];
  updateMemberAccess: (
    userId: string,
    newPermissions: PlaygroundPermissions
  ) => void;
}) => {
  const permissionsToUpdate: PlaygroundPermissions | undefined = useMemo(() => {
    if (
      currentUser.isAnonymous ||
      !teamMembers ||
      teamMembers.length === 0 ||
      !playgroundMembers ||
      playgroundMembers.length === 0
    ) {
      return;
    }

    if (playgroundMembers.find(member => member.id === currentUser.uid)) {
      return;
    }

    const userTeamMember = teamMembers.find(
      member => member.id === currentUser.uid
    );

    return (
      (userTeamMember &&
        getPlaygroundPermissions(userTeamMember.permissions)) ||
      defaultPermissions ||
      PlaygroundPermissions.View
    );
  }, [
    currentUser.isAnonymous,
    currentUser.uid,
    teamMembers,
    playgroundMembers,
    defaultPermissions
  ]);

  useEffect(() => {
    if (!permissionsToUpdate) {
      return;
    }

    updateMemberAccess(currentUser.uid, permissionsToUpdate);
  }, [currentUser.uid, permissionsToUpdate, updateMemberAccess]);
};

export default useAutoJoinPlayground;
