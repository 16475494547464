import React, { useState, useCallback, useMemo } from 'react';

import { HomeSettingsEditAvatar } from '../home-settings/HomeSettingsEditAvatar';
import { HomeSettingsSection } from '../home-settings/HomeSettingsSection';
import { HomeSettingsEditNameModal } from '../home-settings/HomeSettingsEditNameModal';

import { Modal } from '../../general/Modal';

import { Team } from '../../../declarations/Team';
import {
  hasAdminPermissions,
  hasPermissionToEdit
} from '../../../utilities/TeamPermissionsUtilities';
import useUpdateTeam from '../../../hooks/useUpdateTeam';
import useDeleteTeam from '../../../hooks/useDeleteTeam';
import { useHistory } from 'react-router-dom';

type HomeTeamPageSettingsProps = {
  activeTeam: Team;
  currentUserId: string;
};

enum EditModal {
  Name = 'EditNameModal',
  EditPlan = 'EditPlanModal',
  DeleteTeam = 'DeleteTeamModal',
  LeaveTeam = 'LeaveTeamModal'
}

export const HomeTeamPageSettings = (props: HomeTeamPageSettingsProps) => {
  const { updateTeamName, updateTeamAvatar } = useUpdateTeam(
    props.activeTeam.id
  );
  const deleteTeam = useDeleteTeam(props.activeTeam.id);

  const [visibleModal, setVisibleModal] = useState<EditModal | undefined>(
    undefined
  );

  const history = useHistory();

  const hideModal = useCallback(() => setVisibleModal(undefined), []);

  const currentUserPermissions = useMemo(
    () =>
      props.activeTeam.members.find(member => member.id === props.currentUserId)
        ?.permissions,
    [props.activeTeam.members, props.currentUserId]
  );

  if (!hasPermissionToEdit(currentUserPermissions)) {
    return (
      <>
        {visibleModal === EditModal.LeaveTeam && (
          <Modal
            title={`Leave "${props.activeTeam.name}"`}
            description={
              "You will lose access to this team's playgrounds, folders, and templates."
            }
            action={{
              title: 'Leave team',
              handler: () => console.log('You left the team!'),
              isDestructive: true
            }}
            hideSelf={hideModal}
          />
        )}
        <HomeSettingsSection
          label={'Team'}
          actions={[
            {
              text: 'Leave team',
              handler: () => setVisibleModal(EditModal.LeaveTeam)
            }
          ]}
        />
      </>
    );
  }
  const handleDeleteTeam = async () => {
    await deleteTeam();
    history.push('/');
  };

  const isAdmin = hasAdminPermissions(currentUserPermissions);

  return (
    <>
      {isAdmin && visibleModal === EditModal.Name && (
        <HomeSettingsEditNameModal
          onSubmit={updateTeamName}
          onClose={hideModal}
          value={props.activeTeam.name}
        />
      )}
      {isAdmin && visibleModal === EditModal.DeleteTeam && (
        <Modal
          title={`Delete "${props.activeTeam.name}"`}
          description={
            'Permanently delete this team, including all playgrounds, folders, and templates inside.'
          }
          action={{
            title: 'Delete team',
            handler: handleDeleteTeam,
            isDestructive: true
          }}
          hideSelf={hideModal}
        />
      )}
      <HomeSettingsEditAvatar
        avatarUrl={props.activeTeam?.avatarUrl}
        name={props.activeTeam.name}
        onUpdateAvatar={updateTeamAvatar}
      />
      <HomeSettingsSection
        label={'Name'}
        value={props.activeTeam.name}
        actions={[
          {
            text: 'Change name',
            handler: () => setVisibleModal(EditModal.Name)
          }
        ]}
      />
      <HomeSettingsSection
        label={'Plan'}
        value={'Starter team'}
        actions={[
          {
            text: 'Upgrade (coming soon)',
            handler: () => console.log('Team plan changed!')
          }
        ]}
      />
      <HomeSettingsSection
        label={'Team'}
        actions={[
          {
            text: 'Delete team',
            handler: () => setVisibleModal(EditModal.DeleteTeam)
          }
        ]}
      />
    </>
  );
};
