import React, { useState } from 'react';

import { HomeTeamPageTabs } from './HomeTeamPageTabs';
import { HomeTeamPageMemberList } from './HomeTeamPageMemberList';
import { HomeTeamPageSettings } from './HomeTeamPageSettings';
import { Team } from '../../../declarations/Team';
import UpgradeProTeamModal from '../../upgrade-modals/UpgradeProTeamModal';
import { useToggle } from '../../../hooks/useToggle';

type HomeTeamPageProps = {
  currentUser: firebase.User;
  activeTeam: Team;
};

export const HomeTeamPage = (props: HomeTeamPageProps) => {
  const [activeTab, setActiveTab] = useState(0);
  const {
    value: isUpgradeModalVisible,
    setTrue: showUpgradeModal,
    setFalse: hideUpgradeModal
  } = useToggle(false);

  return (
    <>
      <HomeTeamPageTabs
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        tabs={[
          {
            title: 'Members'
          },
          {
            title: 'Settings'
          }
        ]}
      />
      {activeTab === 0 ? (
        <HomeTeamPageMemberList
          currentUser={props.currentUser}
          activeTeam={props.activeTeam}
          showUpgradeModal={showUpgradeModal}
        />
      ) : (
        <HomeTeamPageSettings
          activeTeam={props.activeTeam}
          currentUserId={props.currentUser.uid}
        />
      )}
      <UpgradeProTeamModal
        onHide={hideUpgradeModal}
        visible={isUpgradeModalVisible}
      />
    </>
  );
};
