import React from 'react';
import { colors } from '../constants/colors';
import { IconProps } from './IconProps';

export const LinkIcon = ({ margin, color, size }: IconProps) => (
  <svg
    style={{ margin }}
    width={size || 24}
    height={size || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.5366 15.1021L12.6093 14.0294C11.9238 13.9595 11.3906 13.7183 11.0224 13.3502C9.97504 12.3028 9.97504 10.8365 11.0161 9.79547L13.1552 7.65631C14.2026 6.6153 15.6625 6.6153 16.7099 7.65631C17.7636 8.71002 17.7573 10.1763 16.7162 11.2173L15.7704 12.1631C15.9926 12.6773 16.0815 13.3248 15.9355 13.8897L17.7128 12.1124C19.3124 10.5318 19.3124 8.26569 17.7065 6.65973C16.1005 5.05377 13.8471 5.06647 12.2602 6.65338L10.0131 8.89411C8.42621 10.4874 8.41986 12.7471 10.0258 14.3467C10.3686 14.6959 10.8701 14.9752 11.5366 15.1021ZM12.4633 9.64313L11.3906 10.7222C12.0761 10.7921 12.6093 11.0269 12.9775 11.3951C14.0248 12.4424 14.0248 13.9151 12.9838 14.9498L10.8447 17.0889C9.7973 18.1299 8.33099 18.1363 7.28998 17.0889C6.23627 16.0352 6.24262 14.5753 7.28363 13.5342L8.22943 12.5884C8.00726 12.0743 7.9184 11.4205 8.06439 10.8555L6.2807 12.6329C4.68744 14.2198 4.68744 16.4796 6.28705 18.0855C7.89935 19.6915 10.1528 19.6851 11.7397 18.0919L13.9804 15.8511C15.5737 14.2579 15.58 12.0045 13.9741 10.3985C13.6313 10.0494 13.1298 9.77008 12.4633 9.64313Z"
      fill={color || colors.primaryText}
    />
  </svg>
);
