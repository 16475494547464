import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { colors } from '../../../constants/colors';
import { Button } from '../general/Button';
import { Logo } from '../../../icons/Logo';
import { HamburgerIcon } from '../../../icons/HamburgerIcon';
import { useToggle } from '../../../hooks/useToggle';
import { MarketingLandingMobileMenu } from './MarketingLandingMobileMenu';

type MarketingLandingHeaderProps = {
  onSignUp: () => void;
};

export const MarketingLandingHeader = (props: MarketingLandingHeaderProps) => {
  const { value: isMobileMenuVisible, toggle: toggleMobileMenu } = useToggle(
    false
  );

  return (
    <styles.container>
      <Link to={'/'}>
        <Logo color={colors.base} size={32} />
      </Link>

      <styles.linkContainer>
        <styles.link to={'/features'}>Features</styles.link>
        <styles.link to={'/teams'}>Teams</styles.link>
        <styles.link to={'/pricing'}>Pricing</styles.link>

        <Button onClick={props.onSignUp} primary>
          Sign up
        </Button>
      </styles.linkContainer>
      <styles.mobileMenuContainer onClick={toggleMobileMenu}>
        <HamburgerIcon color={colors.base} />
      </styles.mobileMenuContainer>

      {isMobileMenuVisible && (
        <MarketingLandingMobileMenu
          hideMenu={toggleMobileMenu}
          onSignUp={props.onSignUp}
        />
      )}
    </styles.container>
  );
};

const styles = {
  container: styled.div`
    position: sticky;
    top: 0px;
    height: 64px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: ${colors.white};
    z-index: 2;
    box-shadow: -8px 0px 0px ${colors.white}, 8px 0px 0px ${colors.white};
  `,
  linkContainer: styled.div`
    display: grid;
    grid-template-columns: auto auto auto auto;
    grid-gap: 32px;
    align-items: center;

    @media only screen and (max-width: 600px) {
      display: none;
    }
  `,
  link: styled(Link)`
    font-size: 14px;
    line-height: 24px;
    color: ${colors.base};
    text-decoration: none;

    :hover {
      color: ${colors.blueberry};
    }
  `,
  mobileMenuContainer: styled.div`
    display: none;
    cursor: pointer;

    @media only screen and (max-width: 600px) {
      display: block;
    }
  `,
  mobileMenu: styled.div`
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    background: ${colors.base};
  `
};
