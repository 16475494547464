import React from 'react';
import styled from 'styled-components';

import { colors } from '../../../constants/colors';
import { Text } from '../general/Text';
import { Button } from '../general/Button';
import { CheckIcon } from '../../../icons/CheckIcon';

type MarketingLandingPricingCardProps = {
  professional?: boolean;
  price: string;
  planName: string;
  features: string[];
  onSignUp?: () => void;
};

type StyledProps = {
  professional?: boolean;
};

export const MarketingLandingPricingCard = (
  props: MarketingLandingPricingCardProps
) => {
  return (
    <styles.container professional={props.professional}>
      <styles.mainContent>
        <Text
          size={'bodySmall'}
          fontWeight={600}
          color={props.professional ? 'secondaryText' : 'base'}
        >
          {props.price}
        </Text>
        <Text
          wrapText
          size={'header'}
          margin={'16px 0px 24px 0px'}
          color={props.professional ? 'white' : 'base'}
        >
          {props.planName}
        </Text>
        <styles.featuresContainer>
          {props.professional && <styles.gradient />}
          {props.features.map((item, index) => (
            <styles.featureListItem key={`${item}${index}`}>
              <styles.checkContainer>
                <CheckIcon
                  color={props.professional ? colors.white : colors.base}
                />
              </styles.checkContainer>
              <Text
                size={'bodySmall'}
                wrapText
                color={props.professional ? 'white' : 'base'}
              >
                {item}
              </Text>
            </styles.featureListItem>
          ))}
        </styles.featuresContainer>
      </styles.mainContent>

      {props.professional ? (
        <styles.linkButton
          href={
            "mailto:hello@variable.app?subject=I'm interested in Professional Teams"
          }
        >
          Contact us
        </styles.linkButton>
      ) : (
        <Button
          primary
          onClick={props.onSignUp}
          XL
          fullWidth
          margin={'8px 0px 0px 0px'}
          borderRadius={8}
        >
          Get started
        </Button>
      )}
    </styles.container>
  );
};

const styles = {
  container: styled.div<StyledProps>`
    padding: 24px;
    border: 3px solid ${colors.base};
    background: ${props => (props.professional ? colors.base : colors.white)};
    border-radius: 12px;
    flex: 1;
    margin: 16px;

    @media (max-width: 800px) {
      margin: 16px 0px;
    }
  `,
  featureListItem: styled.div`
    display: flex;
    margin-bottom: 12px;
  `,
  checkContainer: styled.div`
    flex-shrink: 0;
    padding-top: 2px;
    margin-right: 12px;
  `,
  mainContent: styled.div`
    padding: 8px 16px;
  `,
  linkButton: styled.a`
    color: ${colors.white};
    border-radius: 8px;
    background: ${colors.W8};
    border: 3px solid ${colors.clear};
    text-decoration: none;
    font-size: 16px;
    line-height: 24px;
    user-select: none;
    width: 100%;
    display: block;
    margin-top: 8px;
    padding: 9px 20px;
    box-sizing: border-box;
    text-align: center;
    font-weight: 700;
    transition: background 0.2s;

    :hover {
      background: ${colors.W12};
    }

    :active {
      background: ${colors.W8};
    }
  `,
  gradient: styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    user-select: none;
    pointer-events: none;
    background: linear-gradient(
      180deg,
      rgba(17, 17, 17, 0) 0%,
      rgba(17, 17, 17, 0.95) 100%
    );
  `,
  featuresContainer: styled.div`
    position: relative;
  `
};
