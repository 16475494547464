import React from 'react';
import {
  GeneralError,
  EmailInputError,
  PasswordInputError
} from '../../../constants/errors/authentication_errors';

import { Text } from '../general/Text';

type ErrorMessageProps = {
  text?: string | GeneralError | EmailInputError | PasswordInputError;
  margin?: string;
};

export const ErrorMessage = (props: ErrorMessageProps) => (
  <Text
    color={'strawberry'}
    size={'bodySmall'}
    fontWeight={500}
    wrapText
    margin={props.margin}
  >
    {props.text}
  </Text>
);
