import React, { useState, useCallback } from 'react';

import { Toast } from '../../general/Toast';
import { HomeSettingsEditNameModal } from './HomeSettingsEditNameModal';
import { HomeSettingsEditEmailModal } from './HomeSettingsEditEmailModal';
import { HomeSettingsEditPasswordModal } from './HomeSettingsEditPasswordModal';
import { HomeSettingsSection } from './HomeSettingsSection';
import { HomeSettingsEditAvatar } from './HomeSettingsEditAvatar';
import { useToggle } from '../../../hooks/useToggle';
import { useUpdateUser } from '../../../hooks/useUpdateUser';
import { useUserUtils } from '../../../hooks/useUserUtils';
import { AppUser } from '../../../declarations/AppUser';

type HomeSettingsProps = {
  user: firebase.User;
  currentUserData?: AppUser;
};

enum EditModal {
  Name = 'EditNameModal',
  Email = 'EditEmailModal',
  Password = 'EditPasswordModal'
}

export const HomeSettings = (props: HomeSettingsProps) => {
  const [visibleModal, setVisibleModal] = useState<EditModal | undefined>(
    undefined
  );
  const hideModal = useCallback(() => setVisibleModal(undefined), []);

  const {
    updateUserAvatar,
    updateUserEmail,
    updateUserName,
    updateUserPassword
  } = useUpdateUser(props.user);

  const { signOut } = useUserUtils();

  const {
    value: isToastVisible,
    setTrue: showToast,
    setFalse: hideToast
  } = useToggle(false);

  const handleUpdateEmail = (newEmail: string) => {
    updateUserEmail(newEmail);
    showToast();
  };

  return (
    <>
      {isToastVisible && (
        <Toast
          text={'Password updated'}
          action={{ text: 'Close', handler: hideToast }}
          hideSelf={hideToast}
        />
      )}
      {visibleModal === EditModal.Name && (
        <HomeSettingsEditNameModal
          onSubmit={updateUserName}
          onClose={hideModal}
          value={props.currentUserData?.name || props.user.displayName || ''}
        />
      )}
      {visibleModal === EditModal.Email && (
        <HomeSettingsEditEmailModal
          onSubmit={handleUpdateEmail}
          onClose={hideModal}
          value={props.currentUserData?.email || props.user.email || ''}
        />
      )}
      {visibleModal === EditModal.Password && (
        <HomeSettingsEditPasswordModal
          onSubmit={updateUserPassword}
          onClose={hideModal}
        />
      )}
      <HomeSettingsEditAvatar
        avatarUrl={props.currentUserData?.avatarUrl}
        onUpdateAvatar={updateUserAvatar}
        name={props.currentUserData?.name || props.user.displayName || ''}
      />
      <HomeSettingsSection
        label={'Name'}
        value={props.currentUserData?.name || props.user.displayName || ''}
        actions={[
          {
            text: 'Change name',
            handler: () => setVisibleModal(EditModal.Name)
          }
        ]}
      />
      <HomeSettingsSection
        label={'Email'}
        value={props.currentUserData?.email || props.user.email || ''}
        actions={[
          {
            text: 'Change email',
            handler: () => setVisibleModal(EditModal.Email)
          }
        ]}
      />
      <HomeSettingsSection
        label={'Password'}
        actions={[
          {
            text: 'Change password',
            handler: () => setVisibleModal(EditModal.Password)
          }
        ]}
      />
      <HomeSettingsSection
        label={'Account'}
        actions={[
          { text: 'Sign out', handler: signOut }
          // TODO: @Forest - After launch
          // {
          //   text: 'Delete account',
          //   handler: () => console.log('Account deleted')
          // }
        ]}
      />
    </>
  );
};
