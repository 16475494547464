import React from 'react';

import { Text } from '../../general/Text';

type HomeSettingsSectionProps = {
  label: string;
  value?: string;
  actions: { text: string; handler: () => void }[];
};

export const HomeSettingsSection = (props: HomeSettingsSectionProps) => (
  <>
    <Text margin={'48px 0px 0px 0px'} size={'header'}>
      {props.label}
    </Text>
    {props.value && (
      <Text userSelect={'text'} cursor={'text'} margin={'8px 0px 0px 0px'}>
        {props.value}
      </Text>
    )}

    {props.actions.map((action, index) => (
      <Text
        key={`${action.text}${index}`}
        margin={'8px 0px 0px 0px'}
        color={'blueberry'}
        cursor={'pointer'}
        onClick={action.handler}
        display={'inline-block'}
      >
        {action.text}
      </Text>
    ))}
  </>
);
