import { useState, useEffect } from 'react';

const useStripeInstance = () => {
  const [stripe, setStripe] = useState<stripe.Stripe | null>(null);

  useEffect(() => {
    let loadStripeHandler;
    if (typeof window.Stripe === 'function') {
      setStripe(window.Stripe('pk_test_IG9X3QDDiMZfCqMiBHJMdLuJ00RD3e0pmo'));
    } else {
      loadStripeHandler = () => {
        setStripe(window.Stripe('pk_test_IG9X3QDDiMZfCqMiBHJMdLuJ00RD3e0pmo'));
      };
      document
        .querySelector('#stripe-js')
        ?.addEventListener('load', loadStripeHandler);
    }

    return () => {
      if (loadStripeHandler) {
        document
          .querySelector('#stripe-js')
          ?.removeEventListener('load', loadStripeHandler);
      }
    };
  }, []);

  return stripe;
};

export default useStripeInstance;
