import React, { useRef } from 'react';
import styled from 'styled-components';

import { Avatar } from '../../general/Avatar';
import { EditIcon } from '../../../icons/EditIcon';

type HomeSettingsEditAvatarProps = {
  avatarUrl?: string;
  name: string;
  onUpdateAvatar: (file: File) => void;
};

export const HomeSettingsEditAvatar = (props: HomeSettingsEditAvatarProps) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const openFileChooser = () => fileInputRef?.current?.click();
  const handleUploadPhoto = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event?.target?.files?.[0];

    if (!file) {
      return;
    }

    props.onUpdateAvatar(file);
  };

  return (
    <>
      <styles.imageInput
        ref={fileInputRef}
        type={'file'}
        accept={'.png, .jpg'}
        onChange={handleUploadPhoto}
      />
      <styles.avatarContainer onClick={openFileChooser}>
        <styles.overlay>
          <EditIcon />
        </styles.overlay>
        <Avatar name={props.name} src={props.avatarUrl} size={96} />
      </styles.avatarContainer>
    </>
  );
};

const styles = {
  imageInput: styled.input`
    display: none;
  `,
  avatarContainer: styled.div`
    display: inline-block;
    position: relative;
    margin-top: 32px;
    height: 96px;
    width: 96px;
    border-radius: 50%;
    cursor: pointer;
  `,
  overlay: styled.div`
    position: absolute;
    width: 96px;
    height: 96px;
    opacity: 0;
    background: rgba(0, 0, 0, 0.5);
    transition: opacity 0.16s;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    :hover {
      opacity: 1;
    }
  `
};
