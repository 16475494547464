import React, { useState } from 'react';

import { Modal } from '../../general/Modal';
import { Input } from '../../general/Input';

type HomeSettingsEditPasswordModalProps = {
  onClose: () => void;
  onSubmit: (password: string) => void;
};

export const HomeSettingsEditPasswordModal = (
  props: HomeSettingsEditPasswordModalProps
) => {
  const [currentPasswordInputValue, setCurrentPasswordInputValue] = useState(
    ''
  );
  const [newPasswordInputValue, setNewPasswordInputValue] = useState('');
  const [
    confirmNewPasswordInputValue,
    setConfirmNewPasswordInputValue
  ] = useState('');

  const handleCurrentPasswordInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCurrentPasswordInputValue(event.target.value);
  };

  const handleNewPasswordInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setNewPasswordInputValue(event.target.value);
  };

  const handleConfirmNewPasswordInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setConfirmNewPasswordInputValue(event.target.value);
  };

  const handleSubmit = () => {
    if (
      currentPasswordInputValue &&
      newPasswordInputValue &&
      confirmNewPasswordInputValue
    ) {
      props.onSubmit(newPasswordInputValue);
      props.onClose();
    }
  };

  const handleForgotPassword = () => {
    console.log('Sent how to update password');
  };

  return (
    <Modal
      title={'Change password'}
      action={{
        title: 'Save',
        handler: handleSubmit,
        isDisabled:
          !currentPasswordInputValue ||
          !newPasswordInputValue ||
          !confirmNewPasswordInputValue
      }}
      // TODO: @Forest - After launch
      // secondaryAction={{
      //   title: 'Forgot password?',
      //   handler: handleForgotPassword
      // }}
      hideSelf={props.onClose}
    >
      <Input
        type={'password'}
        autoFocus
        autoCorrect={'off'}
        spellCheck={false}
        placeholder={'Current password'}
        margin={'24px 0px 0px 0px'}
        value={currentPasswordInputValue}
        onChange={handleCurrentPasswordInputChange}
      />
      <Input
        type={'password'}
        autoCorrect={'off'}
        spellCheck={false}
        placeholder={'New password'}
        margin={'16px 0px 0px 0px'}
        value={newPasswordInputValue}
        onChange={handleNewPasswordInputChange}
      />
      <Input
        type={'password'}
        autoCorrect={'off'}
        spellCheck={false}
        placeholder={'Confirm password'}
        margin={'16px 0px 0px 0px'}
        value={confirmNewPasswordInputValue}
        onChange={handleConfirmNewPasswordInputChange}
      />
    </Modal>
  );
};
