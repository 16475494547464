import { PlaygroundPermissions } from '../declarations/PlaygroundPermissions';

export const hasPermissionToEdit = (permissions?: PlaygroundPermissions) =>
  permissions === PlaygroundPermissions.Edit ||
  permissions === PlaygroundPermissions.Owner;

export const getEditablePermissions = (
  currentUserPermissions?: PlaygroundPermissions
) => {
  switch (currentUserPermissions) {
    case PlaygroundPermissions.Owner:
      return [
        PlaygroundPermissions.Owner,
        PlaygroundPermissions.Edit,
        PlaygroundPermissions.View
      ];
    case PlaygroundPermissions.Edit:
      return [PlaygroundPermissions.Edit, PlaygroundPermissions.View];
    case PlaygroundPermissions.View:
      return [];
    default:
      return [];
  }
};
