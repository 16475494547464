import { useContext, useState, useEffect } from 'react';
import { FirebaseContext } from '../Firebase';

import { Team } from '../declarations/Team';

import transformToTeam from '../transforms/TeamTransform';
import analytics from '../Analytics/Analytics';

const useTeamInfo = (teamId: string) => {
  const firebase = useContext(FirebaseContext);

  const [team, setTeam] = useState<Team | undefined>();

  useEffect(() => {
    if (!firebase || !teamId) {
      return;
    }

    const firestore = firebase.firestore();

    const unsubscribeFromTeamInfo = firestore.doc(`teams/${teamId}`).onSnapshot(
      async teamSnapshot => {
        try {
          const team = await transformToTeam(
            teamSnapshot,
            firestore.collection('users')
          );
          setTeam(team);
        } catch (e) {}
      },
      error => {
        analytics.track('useTeamInfo.observer.error', error);
      }
    );

    return () => {
      unsubscribeFromTeamInfo();
    };
  }, [firebase, teamId]);

  return team;
};

export default useTeamInfo;
