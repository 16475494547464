import React from 'react';
import styled from 'styled-components';

import { LoadingSpinner } from '../general/LoadingSpinner';
import { colors } from '../../constants/colors';

export const PlaygroundLoadingScreen = () => (
  <styles.loadingScreen>
    <LoadingSpinner size={32} />
  </styles.loadingScreen>
);

const styles = {
  loadingScreen: styled.div`
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${colors.base};
    z-index: 200;
  `
};
