import React from 'react';
import styled from 'styled-components';

import { PlaygroundFacepileAvatar } from './PlaygroundFacepileAvatar';
import { UserPresenceInfo } from '../../../hooks/usePresence';

type PlaygroundFacepileProps = {
  users: Array<UserPresenceInfo>;
  currentUser: firebase.User;
};

export const PlaygroundFacepile = (props: PlaygroundFacepileProps) => (
  <styles.facepileContainer>
    {props.users.map((presence, index) => (
      <PlaygroundFacepileAvatar
        image={presence.avatarUrl || ''}
        name={presence.name || presence.email || 'Anonymous'}
        index={index}
        color={presence.color}
        key={presence.userId}
        isCurrentUser={props.currentUser.uid === presence.userId}
      />
    ))}
  </styles.facepileContainer>
);

const styles = {
  facepileContainer: styled.div`
    display: flex;
    margin-right: 4px;
    margin-left: 8px;
  `
};
