import { FirebaseContext } from '../Firebase';
import { useContext, useCallback } from 'react';

const useTeamInviteUtility = (teamId: string, teamName: string) => {
  const firebase = useContext(FirebaseContext);

  const inviteToTeam = useCallback(
    (email: string) => {
      if (!firebase) {
        return;
      }

      const { onInviteToTeam } = firebase.functions();

      try {
        return onInviteToTeam({ teamId, teamName, email });
      } catch (error) {
        return { error: 'error sending invite to team' };
      }
    },
    [firebase, teamId, teamName]
  );

  return inviteToTeam;
};

export default useTeamInviteUtility;
