import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { RECENTS_FOLDER_ID } from '../../constants/keys';

import { Text } from '../general/Text';

import { AppUser } from '../../declarations/AppUser';
import { Team } from '../../declarations/Team';
import { Folder } from '../../declarations/Folder';
import { PlaygroundInfo } from '../../declarations/PlaygroundInfo';
import { PlaygroundPermissions } from '../../declarations/PlaygroundPermissions';

import { HomeSettings } from './home-settings/HomeSettings';
import { HomeTeamPage } from './home-team-page/HomeTeamPage';

import { HomePlaygroundRowItem } from './HomePlaygroundRowItem';
import { HomeSectionHeader } from './HomeSectionHeader';
import { HomeTemplateBar } from './HomeTemplateBar';
import { HomeTitleBar } from './HomeTitleBar';
import { SortingMethod } from '../../declarations/SortingMethod';
import { getHomePlaygroundRowDescription } from '../../utilities/HomeUtilities';

type HomeMainContentProps = {
  user: firebase.User;
  userData?: AppUser;
  activeTeam: Team;
  activeFolder?: Folder;
  currentUserHasEditPermissions: boolean;
  isViewingSettings: boolean;
  isViewingTeamPage: boolean;
  playgrounds: Array<PlaygroundInfo>;
  templates: Array<PlaygroundInfo>;
  handleCreateNewTemplate: (playgroundId: string) => Promise<void>;
  handleCreateNewPlayground: (template?: PlaygroundInfo) => Promise<void>;
  handleDeletePlayground: (
    playground: PlaygroundInfo
  ) => Promise<void> | undefined;
};

const HomeMainContent = ({
  user,
  userData,
  activeTeam,
  activeFolder,
  currentUserHasEditPermissions,
  isViewingSettings,
  isViewingTeamPage,
  playgrounds,
  templates,
  handleCreateNewTemplate,
  handleCreateNewPlayground,
  handleDeletePlayground
}: HomeMainContentProps) => {
  const [sortingBy, setSortingBy] = useState<SortingMethod>(
    activeFolder?.name === RECENTS_FOLDER_ID
      ? SortingMethod.RecentlyViewed
      : SortingMethod.RecentlyModified
  );

  useEffect(() => {
    setSortingBy(
      activeFolder?.name === RECENTS_FOLDER_ID
        ? SortingMethod.RecentlyViewed
        : SortingMethod.RecentlyModified
    );
  }, [activeFolder]);

  if (isViewingSettings) {
    return (
      <styles.mainContentContainer>
        <HomeTitleBar title={'Settings'} />
        <styles.scrollRegion>
          <HomeSettings user={user} currentUserData={userData} />
        </styles.scrollRegion>
      </styles.mainContentContainer>
    );
  }

  if (isViewingTeamPage) {
    return (
      <styles.mainContentContainer>
        <HomeTitleBar title={activeTeam.name} />
        <styles.scrollRegion>
          <HomeTeamPage activeTeam={activeTeam} currentUser={user} />
        </styles.scrollRegion>
      </styles.mainContentContainer>
    );
  }

  return (
    <styles.mainContentContainer>
      <HomeTitleBar
        title={activeFolder?.name || 'none'}
        onCreateNewPlayground={
          currentUserHasEditPermissions ? handleCreateNewPlayground : undefined
        }
      />
      <styles.scrollRegion>
        <HomeTemplateBar
          onDeleteTemplate={handleDeletePlayground}
          createNewPlaygroundFromTemplate={
            currentUserHasEditPermissions
              ? handleCreateNewPlayground
              : undefined
          }
          templates={templates}
        />
        <HomeSectionHeader
          sortingBy={sortingBy}
          setSortingBy={setSortingBy}
          isRecentsFolder={activeFolder?.name === RECENTS_FOLDER_ID}
        />
        {playgrounds
          .sort((left, right) =>
            activeFolder?.name === RECENTS_FOLDER_ID
              ? (right.lastViewed?.seconds ?? 0) -
                (left.lastViewed?.seconds ?? 0)
              : (right.lastModified?.seconds ?? 0) -
                (left.lastModified?.seconds ?? 0)
          ) // TODO: clean this shit up
          .map(playgroundInfo => {
            const member =
              userData?.id &&
              playgroundInfo?.members &&
              playgroundInfo?.members[userData.id];

            return (
              <HomePlaygroundRowItem
                key={playgroundInfo.id}
                onTurnIntoTemplate={
                  currentUserHasEditPermissions
                    ? handleCreateNewTemplate
                    : undefined
                }
                onDeletePlayground={handleDeletePlayground}
                canDeletePlayground={
                  member?.permissions === PlaygroundPermissions.Owner
                }
                playground={playgroundInfo}
                description={getHomePlaygroundRowDescription(
                  playgroundInfo,
                  activeFolder?.name === RECENTS_FOLDER_ID
                )}
              />
            );
          })}
        {playgrounds.length === 0 && (
          <Text
            margin={'48px 0px 0px 0px'}
            textAlign={'center'}
            color={'secondaryText'}
          >
            No playgrounds yet
          </Text>
        )}
      </styles.scrollRegion>
    </styles.mainContentContainer>
  );
};

const styles = {
  mainContentContainer: styled.div`
    overflow: auto;
    outline: none;
  `,
  scrollRegion: styled.div`
    padding-left: 80px;
    padding-right: 80px;
    padding-bottom: 56px;
    padding-top: 120px;

    @media (max-width: 840px) {
      padding-left: 13%;
      padding-right: 13%;
    }

    @media (max-width: 500px) {
      padding-left: 44x;
      padding-right: 44px;
    }
  `
};

export default HomeMainContent;
