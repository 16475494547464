import { useState, useEffect, useContext, useMemo } from 'react';

import { FirebaseContext } from '../Firebase';
import RealtimeManager, { RealtimeManagerEvent } from '../RealtimeManager';

export type UserPresenceInfo = {
  name?: string;
  userId: string;
  activeFile: string;
  color: string;
  avatarUrl?: string;
  hasFetchedProfile: boolean;
  email?: string;
};

const usePresence = (realtimeManager: RealtimeManager | null) => {
  const firebase = useContext(FirebaseContext);
  const [playgroundPresences, setPlaygroundPresences] = useState<
    Record<string, UserPresenceInfo>
  >({});

  const presenceArray = useMemo(() => {
    return Object.values(playgroundPresences);
  }, [playgroundPresences]);

  useEffect(() => {
    if (!realtimeManager) {
      return;
    }

    // TODO : Lets just util to calculate color where we need it
    const handlePresence = (
      userId: string,
      color?: string,
      activeFileRealtimeId?: string
    ) => {
      setPlaygroundPresences(prevPresences => {
        let updatedPresences = { ...prevPresences };
        const existingPresence: UserPresenceInfo | undefined =
          updatedPresences[userId];

        // User was deleted
        if (!color && !activeFileRealtimeId) {
          const { [userId]: deletedUser, ...restPresences } = updatedPresences;

          updatedPresences = restPresences;
        } else {
          updatedPresences[userId] = {
            ...existingPresence,
            userId,
            color: color || '',
            activeFile: activeFileRealtimeId || '',
            hasFetchedProfile: existingPresence?.hasFetchedProfile || false
          };
        }

        return updatedPresences;
      });
    };

    realtimeManager.on(RealtimeManagerEvent.presence, handlePresence);

    return () => {
      realtimeManager.off(RealtimeManagerEvent.presence, handlePresence);
    };
  }, [realtimeManager]);

  useEffect(() => {
    if (!firebase) {
      return;
    }
    const firestore = firebase.firestore();

    const presencesToFetch = Object.values(playgroundPresences).filter(
      presence => presence.hasFetchedProfile === false
    );

    if (presencesToFetch.length < 1) {
      return;
    }

    const updatePlaygroundPresences = async () => {
      const updatedPresences: Array<UserPresenceInfo> = await Promise.all(
        Object.values(presencesToFetch).map(async presence => {
          if (presence.hasFetchedProfile) {
            return presence;
          }

          const user = await firestore.doc(`users/${presence.userId}`).get();

          const userData = user.data();

          return {
            ...presence,
            name: userData?.name || '',
            email: userData?.email || '',
            avatarUrl: userData?.avatarUrl,
            hasFetchedProfile: true
          };
        })
      );

      setPlaygroundPresences(prevPresences => {
        const newPresences = { ...prevPresences };

        updatedPresences.forEach(updatedPresence => {
          if (updatedPresence) {
            newPresences[updatedPresence.userId] = {
              ...newPresences[updatedPresence.userId],
              ...updatedPresence
            };
          }
        });

        return newPresences;
      });
    };

    updatePlaygroundPresences();
  }, [playgroundPresences, firebase]);

  return presenceArray;
};

export { usePresence };
