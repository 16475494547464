import { useLayoutEffect } from 'react';
import { colors } from '../constants/colors';

export const useMarketingBodyStyle = () => {
  useLayoutEffect(() => {
    document.body.style.backgroundColor = colors.white;
    document.body.style.overflow = 'auto';
    return () => {
      document.body.style.backgroundColor = colors.base;
      document.body.style.overflow = 'hidden';
    };
  }, []);
};
