import { BackendPlaygroundInfo } from '../declarations/BackendPlaygroundInfo';
import { PlaygroundPermissions } from '../declarations/PlaygroundPermissions';

const MOTION_TEMPLATE = [
  {
    name: 'Drag.jsx',
    code: `import React, { useRef } from 'react';
import { motion, useMotionValue, useTransform } from 'framer-motion';

// Examples from Framer Motion
// https://www.framer.com/motion/

document.body.style.background = 'linear-gradient(180deg, #70f, #40f)';

export const Drag = () => {
  const constraintsRef = useRef(null);
  const x = useMotionValue(0);
  const rotateY = useTransform(x, [-200, 0, 200], [-45, 0, 45], {
    clamp: false
  });

  return (
    <motion.div
      ref={constraintsRef}
      style={{
        rotateY,
        width: 300,
        height: 150,
        display: 'flex',
        placeContent: 'center',
        overflow: 'hidden',
        background: 'rgba(255,255,255,0.2)',
        borderRadius: 30
      }}
    >
      <motion.div
        drag="x"
        dragConstraints={constraintsRef}
        style={{
          x,
          width: 150,
          height: 150,
          background: 'white',
          borderRadius: 'inherit'
        }}
      />
    </motion.div>
  );
};
    `
  },
  {
    name: 'Gestures.jsx',
    code: `import React from 'react';
import { motion } from 'framer-motion';

// Examples from Framer Motion
// https://www.framer.com/motion/

document.body.style.background = 'linear-gradient(180deg, #91f, #70f)';

export const Gestures = () => (
  <motion.div
    style={{
      height: 160,
      width: 160,
      background: 'white',
      borderRadius: 40
    }}
    whileHover={{ scale: 1.2, rotate: 90 }}
    whileTap={{ scale: 0.8, rotate: -90, borderRadius: '100%' }}
  />
);
    `
  },
  {
    name: 'Variants.jsx',
    code: `import React from 'react';
import { motion } from 'framer-motion';
import './styles.css';

// Examples from Framer Motion
// https://www.framer.com/motion/

document.body.style.background = 'linear-gradient(180deg, #d0e, #91f)';

const container = {
  hidden: { opacity: 1, scale: 0 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
        delay: 0.3,
        when: 'beforeChildren',
        staggerChildren: 0.1
      }
    }
};

const item = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1
  }
};

export const Variants = () => (
  <motion.ul
    className="container"
    variants={container}
    initial="hidden"
    animate="visible"
  >
    {[0, 1, 2, 3].map(index => (
      <motion.li key={index} className="item" variants={item} />
    ))}
  </motion.ul>
);
    `
  },
  {
    name: 'styles.css',
    code: `.container {
  width: 150px;
  height: 150px;
  display: grid;
  overflow: hidden;
  margin: 0;
  list-style: none;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 15px;
  padding: 15px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 50px;
}
    
.item {
  background: white;
  border-radius: 100%;
}
    
body {
  perspective: 500px;
}
    `
  }
];

export const getMotionTemplate = (timestamp: Object) => {
  const copiedTemplate = [...MOTION_TEMPLATE];

  return copiedTemplate.map(file => ({
    name: file.name,
    history: {
      A0: {
        a: '',
        o: {
          0: file.code
        },
        t: timestamp
      }
    }
  }));
};

interface CreateNewPlaygroundParams {
  playgroundCollectionRef: firebase.firestore.CollectionReference;
  playgroundRealtimeRef: firebase.database.Reference;
  timestamp: firebase.firestore.Timestamp;
  template: {
    info: BackendPlaygroundInfo;
    files: Array<{ name: string; data: { history?: any; checkpoint?: any } }>;
  };
}

export const createNewPlaygroundOrTemplate = async ({
  playgroundCollectionRef,
  playgroundRealtimeRef,
  template,
  timestamp
}: CreateNewPlaygroundParams) => {
  template.info.lastModified = timestamp;
  template.info.defaultPermissions = template.info.isInterview
    ? PlaygroundPermissions.Edit
    : PlaygroundPermissions.View;

  const newFirestorePlaygroundRef = await playgroundCollectionRef.add(
    template.info
  );

  const playgroundId = newFirestorePlaygroundRef.id;

  await Promise.all(
    template.files.map(async file => {
      const fileRef = await playgroundRealtimeRef
        .child(playgroundId)
        .child('files')
        .push({
          history: file.data.history || {},
          checkpoint: file.data.checkpoint || {}
        });

      await newFirestorePlaygroundRef
        .collection(`files`)
        .add({ name: file.name, realtimeDatabaseId: fileRef.key });
    })
  );

  return newFirestorePlaygroundRef.id;
};
