import React from 'react';
import styled from 'styled-components';

import { colors } from '../../../constants/colors';
import { Animations } from '../../../constants/animations';
import { Overlay } from '../../../components/general/Overlay';

import {
  useKeyboardShortcut,
  KeyboardShortcutKey
} from '../../../hooks/useKeyboardShortcut';

type ModalProps = {
  children: React.ReactNode;
  onClose: () => void;
};

export const Modal = (props: ModalProps) => {
  useKeyboardShortcut(KeyboardShortcutKey.Escape, props.onClose);

  return (
    <styles.container>
      <Overlay onMouseDown={props.onClose} />
      <styles.base>{props.children}</styles.base>
    </styles.container>
  );
};

const styles = {
  container: styled.div`
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 400;
  `,
  base: styled.div`
    width: 100%;
    z-index: 400;
    max-width: 420px;
    max-height: calc(100% - 48px);
    margin: 24px;
    box-sizing: border-box;
    padding: 40px;
    background: ${colors.white};
    overflow: auto;
    ${Animations.BounceIn};
  `
};
