import React, { useCallback, useRef, useEffect } from 'react';

import MonacoEditor, {
  EditorDidMount,
  ChangeHandler
} from 'react-monaco-editor';

import { VariableTheme, editorOptions } from './constants/editorStyling';

import { editor, languages, Uri } from 'monaco-editor';

import prettier from 'prettier/standalone';
import babylon from 'prettier/parser-babylon';
import typescript from 'prettier/parser-typescript';
import styled from 'styled-components';

import { debounce } from 'lodash';
import { FirestoreProjectFile } from './declarations/FirestoreProjectFile';
import { react_16_9_index_d_ts } from './react_16_9_index_d_ts';
import { createModel } from './utilities/EditorUtilities';
import analytics from './Analytics/Analytics';
import { liftOff } from './tokenizer/configureTokenizer';
import { device } from './constants/deviceBreakpoints';
import { framer_motion_d_ts } from './framer-motion_d_ts';

type EditorProps = {
  activeFile: FirestoreProjectFile;
  editingEnabled: boolean;
  isSideBarVisible: boolean;
  onChange: (activeFile: { name: string; text: string }) => void;
  onEditorMount: (editor: editor.IStandaloneCodeEditor) => void;
  onUpdateLastModified: () => void;
  showFileSwitcher: () => void;
};

const Editor = ({
  activeFile,
  onChange,
  onEditorMount,
  onUpdateLastModified,
  isSideBarVisible,
  editingEnabled,
  showFileSwitcher
}: EditorProps) => {
  const lastActiveFileId = useRef<string | null>(null);
  const monacoEditor = useRef<editor.IStandaloneCodeEditor | null>(null);

  useEffect(() => {
    window.onresize = event => {
      const windowWidth = event.target.innerWidth;
      monacoEditor.current &&
        monacoEditor.current.layout({
          width: windowWidth <= 768 ? windowWidth : windowWidth / 2,
          height: event.target.innerHeight
        });
    };
  }, []);

  useEffect(() => {
    monacoEditor.current?.updateOptions({ readOnly: !editingEnabled });
  }, [editingEnabled]);

  const onMonacoEditorMount: EditorDidMount = (vsCodeEditor, monaco) => {
    monaco.languages.typescript.javascriptDefaults.setEagerModelSync(true);
    monacoEditor.current = vsCodeEditor;

    monaco.editor.defineTheme('variableTheme', VariableTheme);
    monaco.editor.setTheme('variableTheme');

    // TODO: Clean up
    monaco.languages.typescript.typescriptDefaults.setDiagnosticsOptions({
      noSemanticValidation: true,
      noSyntaxValidation: true
    });

    const compilerOptions: languages.typescript.CompilerOptions = {
      target: monaco.languages.typescript.ScriptTarget.ES2016,
      allowNonTsExtensions: true,
      moduleResolution: monaco.languages.typescript.ModuleResolutionKind.NodeJs,
      module: monaco.languages.typescript.ModuleKind.CommonJS,
      noEmit: true,
      typeRoots: ['node_modules/@types'],
      jsx: monaco.languages.typescript.JsxEmit.React,
      jsxFactory: 'React.createElement',
      isolatedModules: true,
      allowSyntheticDefaultImports: true
    };

    monaco.languages.typescript.typescriptDefaults.setCompilerOptions(
      compilerOptions
    );
    monaco.languages.typescript.javascriptDefaults.setCompilerOptions(
      compilerOptions
    );

    // Add React model
    monaco.languages.typescript.typescriptDefaults.addExtraLib(
      react_16_9_index_d_ts,
      'file:///node_modules/react/index.d.ts'
    );
    monaco.languages.typescript.typescriptDefaults.addExtraLib(
      framer_motion_d_ts,
      'file:///node_modules/framer-motion/index.d.ts'
    );

    [
      'javascript',
      'javascriptreact',
      'typescript',
      'typescriptreact',
      'css',
      'html',
      'markdown',
      'json'
    ].forEach(language => {
      monaco.languages.registerDocumentFormattingEditProvider(language, {
        provideDocumentFormattingEdits: async () => {
          const model = vsCodeEditor.getModel();
          if (!model) {
            return;
          }

          const code = model.getValue();

          try {
            const text = prettier.format(code, {
              parser: 'typescript',
              plugins: [babylon, typescript],
              singleQuote: true
            });
            return [{ range: model.getFullModelRange(), text }];
          } catch (e) {
            return [{ range: model.getFullModelRange(), text: code }];
          }
        }
      });
    });

    vsCodeEditor.addCommand(
      monaco.KeyMod.CtrlCmd + monaco.KeyCode.KEY_S,
      () => {
        vsCodeEditor.getAction('editor.action.formatDocument').run();
      }
    );

    vsCodeEditor.addCommand(
      monaco.KeyMod.CtrlCmd + monaco.KeyCode.KEY_P,
      showFileSwitcher
    );

    const active = editor.getModel(Uri.parse(`file:///${activeFile.name}`));

    if (active) {
      active.dispose();
    }

    const newModel = createModel(activeFile.name);

    if (newModel) {
      vsCodeEditor.setModel(newModel);
    }

    // wasm
    liftOff(monaco);

    onEditorMount(vsCodeEditor);
  };

  const debounceChange = useCallback(
    debounce(onChange, 1000, { leading: false, trailing: true }),
    [onChange]
  );

  const onEditorChange: ChangeHandler = (value, event) => {
    if (!monacoEditor.current) {
      analytics.track('editor.noMonacoEditorOnChange');
      return;
    }

    const model = monacoEditor.current.getModel();

    if (!model) {
      analytics.track('editor.noModelOnChange');
      return;
    }

    // If we just changed the file, update instantly
    if (lastActiveFileId.current !== activeFile.id) {
      lastActiveFileId.current = activeFile.id;
      onChange({ name: activeFile.name, text: value });
      return;
    }

    debounceChange({ name: activeFile.name, text: value });

    if (editingEnabled) {
      onUpdateLastModified();
    }
  };

  return (
    <styles.container isSideBarVisible={isSideBarVisible}>
      <MonacoEditor
        width="100%"
        height="calc(100vh - 52px)"
        editorDidMount={onMonacoEditorMount}
        onChange={onEditorChange}
        options={editorOptions}
      />
    </styles.container>
  );
};

const styles = {
  container: styled.div<Partial<EditorProps>>`
    position: absolute;
    top: 52px;
    left: 0px;
    width: 50%;
    transform: ${props =>
      props.isSideBarVisible ? `translateX(${160}px)` : `translateX(${0}px)`};
    transition: all 0.2s;
    z-index: 18;

    ${device.mobile} {
      width: 100%;
    }
  `
};

export default Editor;
