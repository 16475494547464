import React, { useState, useContext } from 'react';
import { colors } from '../../constants/colors';
import { Animations } from '../../constants/animations';
import styled from 'styled-components';

import { useToggle } from '../../hooks/useToggle';

import { Button } from './Button';
import { Text } from './Text';
import { Toast } from './Toast';
import { ArrowUpIcon } from '../../icons/ArrowUpIcon';
import { FirebaseContext } from '../../Firebase';
import { AppUser } from '../../declarations/AppUser';
import { device } from '../../constants/deviceBreakpoints';

type BetaFeedbackProps = {
  currentUser?: AppUser;
};

export const BetaFeedback = (props: BetaFeedbackProps) => {
  const firebase = useContext(FirebaseContext);

  const {
    value: isDialogVisible,
    setFalse: hideFeedbackDialog,
    toggle: toggleFeedbackDialog
  } = useToggle(false);
  const {
    value: isToastVisible,
    setTrue: showToast,
    setFalse: hideToast
  } = useToggle(false);

  const [textAreaValue, setTextAreaValue] = useState('');

  const handleSubmit = async () => {
    if (firebase) {
      await firebase
        .firestore()
        .collection('feedback')
        .add({
          userId: props.currentUser?.id || '',
          email: props.currentUser?.email || '',
          name: props.currentUser?.name || '',
          message: textAreaValue,
          sentAt: firebase.app().firestore.Timestamp.now()
        });
    }
    hideFeedbackDialog();
    setTextAreaValue('');
    showToast();
  };

  return (
    <styles.container>
      <Button onClick={toggleFeedbackDialog}>
        {isDialogVisible ? 'Close' : 'Feedback'}
      </Button>
      {isToastVisible && (
        <Toast
          action={{ text: 'Close', handler: hideToast }}
          text={'Thanks for the feedback!'}
          hideSelf={hideToast}
        />
      )}

      {isDialogVisible && (
        <styles.dialog>
          <styles.textContainer>
            <Text size={'header'}>Feedback</Text>
            <styles.sendInviteButton
              onClick={handleSubmit}
              primary
              isDisabled={!textAreaValue}
            >
              <ArrowUpIcon color={colors.white} />
            </styles.sendInviteButton>
          </styles.textContainer>

          <styles.textarea
            placeholder={'Message...'}
            autoFocus
            autoCorrect={'off'}
            spellCheck={false}
            value={textAreaValue}
            onChange={e => setTextAreaValue(e.target.value)}
          />
        </styles.dialog>
      )}
    </styles.container>
  );
};

const styles = {
  container: styled.div`
    position: fixed;
    right: 16px;
    bottom: 16px;
    z-index: 40;

    ${device.mobile} {
      display: none;
    }
  `,
  dialog: styled.div`
    position: fixed;
    bottom: 60px;
    right: 16px;
    background: ${colors.baseDark};
    border-radius: 8px;
    box-shadow: inset 0px 0px 0px 1px ${colors.W12},
      0px 8px 24px ${colors.dropShadow};
    width: 320px;
    height: 360px;
    ${Animations.BounceUp}
  `,
  textarea: styled.textarea`
    resize: none;
    overflow: auto;
    width: 100%;
    height: calc(100% - 67px);
    font-size: 13px;
    line-height: 20px;
    font-family: Inter;
    background: none;
    border: none;
    outline: none;
    color: ${colors.primaryText};
    box-sizing: border-box;
    padding: 16px 24px;

    ::placeholder {
      color: ${colors.inactive};
    }
  `,
  textContainer: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 18px 0px;
    margin: 0px 24px;
    border-bottom: 1px solid ${colors.W12};
  `,
  sendInviteButton: styled(Button)`
    height: 30px;
    width: 30px;
    padding: 3px;
    flex-shrink: 0;
  `
};
