import React from 'react';
import styled from 'styled-components';

import { HomeTemplateCard } from './HomeTemplateCard';
import { PlaygroundInfo } from '../../declarations/PlaygroundInfo';
import { useHistory } from 'react-router-dom';

type HomeTemplateBarProps = {
  templates: Array<PlaygroundInfo>;
  onDeleteTemplate: (template: PlaygroundInfo) => void;
  createNewPlaygroundFromTemplate?: (template: PlaygroundInfo) => Promise<void>;
};

export const HomeTemplateBar = (props: HomeTemplateBarProps) => {
  const history = useHistory();

  const editTemplate = (template: PlaygroundInfo) => {
    history.push(`/p/${template.id}`);
  };

  return (
    <styles.container>
      {props.templates.map((template, index) => (
        <HomeTemplateCard
          index={index}
          key={template.id}
          template={template}
          onDeleteTemplate={props.onDeleteTemplate}
          onCreateNewFromTemplate={props.createNewPlaygroundFromTemplate}
          onEditTemplate={editTemplate}
        />
      ))}
    </styles.container>
  );
};

const styles = {
  container: styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-top: 4px;
  `
};
