import { useContext, useCallback } from 'react';
import { FirebaseContext } from '../Firebase';
import { PlaygroundPermissions } from '../declarations/PlaygroundPermissions';
import { PlaygroundInfo } from '../declarations/PlaygroundInfo';
import { Team } from '../declarations/Team';
import { hasPermissionToEdit } from '../utilities/PlaygroundPermissionsUtilities';
import { MAX_FREE_EDITORS } from '../constants/team';
import { RequestResult } from '../declarations/RequestResult';
import { UpgradeMemberError } from '../constants/errors/invite_errors';

export const usePlaygroundMembership = (
  currentUserId: string,
  playgroundInfo?: PlaygroundInfo,
  team?: Team
) => {
  const firebase = useContext(FirebaseContext);

  const updateMemberAccess = useCallback(
    async (
      userId: string,
      newPermissions: PlaygroundPermissions
    ): Promise<RequestResult | undefined> => {
      if (!team || !playgroundInfo) {
        return;
      }

      const canUpgradeAsEditor = (editors: Array<{ id: string }> = []) => {
        return !(
          editors.length >= MAX_FREE_EDITORS &&
          !editors.find(editor => editor.id === userId)
        );
      };

      const newPermissionsAreEdit = hasPermissionToEdit(newPermissions);
      if (newPermissionsAreEdit) {
        if (
          !playgroundInfo.isInterview &&
          !team.attributes?.isPersonal &&
          !team.attributes?.hasActiveSubscription &&
          !canUpgradeAsEditor(team.editors)
        ) {
          return { error: UpgradeMemberError.teamEditorLimitReached };
        }
      }
      const memberUpdate: Partial<PlaygroundInfo> = {
        [`members.${userId}`]: { permissions: newPermissions }
      };

      if (newPermissions === PlaygroundPermissions.Owner) {
        memberUpdate[`members.${currentUserId}`] = {
          permissions: PlaygroundPermissions.Edit
        };
      }

      await firebase
        ?.firestore()
        .doc(`playgrounds/${playgroundInfo.id}`)
        .update(memberUpdate);

      return { success: true };
    },
    [currentUserId, firebase, team, playgroundInfo]
  );

  return { updateMemberAccess };
};
