import React from 'react';
import MarketingRouter from './MarketingRouter';
import Home from './Home';

type RootRouterProps = {
  user: firebase.User | undefined | null;
  isUserAnonymous: boolean;
};
const RootRouter = ({ user, isUserAnonymous }: RootRouterProps) => {
  if (typeof user === 'undefined') {
    return <div />;
  }

  if (!user || isUserAnonymous) {
    return <MarketingRouter />;
  }

  return <Home user={user} />;
};

export default RootRouter;
