import React, { useState, useRef, useCallback } from 'react';
import { colors } from '../../../constants/colors';
import styled from 'styled-components';

import { useHistory } from 'react-router-dom';
import {
  useKeyboardShortcut,
  KeyboardShortcutKey
} from '../../../hooks/useKeyboardShortcut';
import { FirestoreProjectFile } from '../../../declarations/FirestoreProjectFile';
import { UserPresenceInfo } from '../../../hooks/usePresence';
import { useToggle } from '../../../hooks/useToggle';
import { DEPENDENCIES_FILE_ID } from '../../../constants/keys';
import { Button } from '../../general/Button';
import { Text, TextCursor } from '../../general/Text';
import { PlaygroundFacepile } from '../playground-facepile/PlaygroundFacepile';
import { Logo } from '../../../icons/Logo';
import { PlaygroundShareSheet } from '../playground-share-sheet/PlaygroundShareSheet';
import { PlaygroundPermissions } from '../../../declarations/PlaygroundPermissions';
import { debounce } from 'lodash';
import { PlaygroundInfo } from '../../../declarations/PlaygroundInfo';
import { TeamMember } from '../../../declarations/TeamMember';
import { device } from '../../../constants/deviceBreakpoints';
import { PlaygroundMobileFileDropdown } from './PlaygroundMobileFileDropdown';

type PlaygroundHeaderProps = {
  fileName: string;
  activeUsers: Array<UserPresenceInfo>;
  currentUser: firebase.User;
  viewOnly: boolean;
  playgroundInfo: PlaygroundInfo;
  teamMembers?: Array<TeamMember>;
  isCodeActiveMobileView: boolean;
  files: Array<FirestoreProjectFile>;
  activeEditingFile: FirestoreProjectFile;
  handleEndInterview: () => void;
  updateMemberAccess: (
    userId: string,
    newPermissions: PlaygroundPermissions
  ) => Promise<void>;
  updateActiveFileName: (name: string) => Promise<void>;
  updateActivePlaygroundName: (name: string) => Promise<void>;
  showSideBar: () => void;
  toggleActiveMobileView: () => void;
  onChangeFile: (id: string, isDependencies?: boolean) => void;
};

enum NameField {
  PlaygroundName = 'PlaygroundNameField',
  FileName = 'FileNameField'
}

export const PlaygroundHeader = (props: PlaygroundHeaderProps) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const history = useHistory();

  const [editingNameField, setEditingNameField] = useState<
    NameField | undefined
  >();
  const [inputVal, setInputVal] = useState('');
  const {
    value: isPlaygroundShareSheetVisible,
    setTrue: showPlaygroundShareSheet,
    setFalse: hidePlaygroundShareSheet
  } = useToggle(false);

  const onEditFileName = () => onEditNameField(NameField.FileName);
  const onEditPlaygroundName = () => onEditNameField(NameField.PlaygroundName);

  const nameTextCursor: TextCursor = !props.viewOnly ? 'text' : 'default';

  const handleFocus = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.includes('.')) {
      const extensionStarts = event.target.value.lastIndexOf('.');
      event.target.focus();
      event.target.setSelectionRange(0, extensionStarts);
    } else {
      event.target.select();
    }
  };

  const updateActiveFileNameDebounced = useCallback(
    debounce(props.updateActiveFileName),
    [props.updateActiveFileName]
  );

  const updateActivePlaygroundNameDebounced = useCallback(
    debounce(props.updateActivePlaygroundName),
    [props.updateActivePlaygroundName]
  );

  const updateName = async () => {
    switch (editingNameField) {
      case NameField.FileName:
        if (inputVal !== props.fileName) {
          await updateActiveFileNameDebounced(inputVal); // TODO: @Forest add logic if the name if the same as before don't update
        }
        break;
      case NameField.PlaygroundName:
        if (
          !props.currentUser.isAnonymous &&
          inputVal !== props.playgroundInfo.name
        ) {
          await updateActivePlaygroundNameDebounced(inputVal); // TODO: @Forest add logic if the name if the same as before don't update
        }
        break;
    }
    setEditingNameField(undefined);
  };

  const onEditNameField = (nameField: NameField) => {
    if (props.viewOnly) {
      return;
    }

    setEditingNameField(nameField);

    let inputVal: string;
    switch (nameField) {
      case NameField.FileName:
        inputVal = props.fileName;
        break;
      case NameField.PlaygroundName:
        inputVal = props.playgroundInfo.name;
        break;
    }
    setInputVal(inputVal);
  };

  const onClickLogo = () => {
    const isTeamMember = props.teamMembers?.find(
      member => member.id === props.currentUser.uid
    );

    history.push(
      isTeamMember
        ? `/${props.playgroundInfo.teamId}/${props.playgroundInfo.folderId}`
        : '/'
    );
  };

  useKeyboardShortcut(KeyboardShortcutKey.Enter, updateName);
  useKeyboardShortcut(KeyboardShortcutKey.Escape, () =>
    setEditingNameField(undefined)
  );

  const handleShowSideBar = () => {
    window.innerWidth > 768 && props.showSideBar();
  };

  return (
    <styles.container>
      {isPlaygroundShareSheetVisible && (
        <PlaygroundShareSheet
          updateMemberAccess={props.updateMemberAccess}
          currentUser={props.currentUser}
          playgroundInfo={props.playgroundInfo}
          hideSelf={hidePlaygroundShareSheet}
        />
      )}
      <styles.logoFlexContainer>
        <styles.logoContainer
          onMouseEnter={handleShowSideBar}
          onClick={onClickLogo}
        >
          <Logo />
        </styles.logoContainer>
      </styles.logoFlexContainer>

      <styles.titleContainer>
        {editingNameField ? (
          <styles.titleInput
            ref={inputRef}
            onChange={e => setInputVal(e.target.value)}
            autoFocus
            onFocus={handleFocus}
            onBlur={updateName}
            value={inputVal}
            spellCheck={false}
          />
        ) : (
          <>
            <Text
              ellipsis
              cursor={nameTextCursor}
              flexShrink={1}
              color={'secondaryText'}
              onClick={onEditPlaygroundName}
            >
              {props.playgroundInfo.name}
            </Text>
            <Text color={'inactive'} margin={'0px 8px 0px 8px'}>
              /
            </Text>
            <Text
              cursor={
                props.fileName === DEPENDENCIES_FILE_ID
                  ? 'default'
                  : nameTextCursor // TODO: clean up
              }
              onClick={
                props.fileName === DEPENDENCIES_FILE_ID
                  ? undefined
                  : onEditFileName // TODO: clean up
              }
            >
              {props.fileName === DEPENDENCIES_FILE_ID
                ? 'Dependencies'
                : props.fileName // TODO: clean up
              }
            </Text>
            {props.viewOnly && (
              <Text margin={'0px 0px 0px 8px'} color={'inactive'}>
                View only
              </Text>
            )}
          </>
        )}
      </styles.titleContainer>

      <styles.actionsContainer>
        <PlaygroundFacepile
          users={props.activeUsers}
          currentUser={props.currentUser}
        />
        {props.playgroundInfo.isInterview &&
          !props.playgroundInfo.interviewCompletedAt &&
          props.teamMembers?.find(
            user => user.id === props.currentUser.uid
          ) && (
            <Button
              margin={'0px 0px 0px 11px'}
              primary
              isDestructive
              onClick={props.handleEndInterview}
            >
              End interview
            </Button>
          )}
        {(!props.playgroundInfo.isInterview ||
          (props.playgroundInfo.isInterview &&
            !props.currentUser.isAnonymous)) && (
          <Button
            margin={'0px 0px 0px 11px'}
            isDisabled={isPlaygroundShareSheetVisible}
            primary
            onClick={showPlaygroundShareSheet}
          >
            Share
          </Button>
        )}
      </styles.actionsContainer>

      <PlaygroundMobileFileDropdown
        files={props.files}
        activeEditingFile={props.activeEditingFile}
        onChangeFile={props.onChangeFile}
      />

      <styles.showHideContainer onMouseDown={props.toggleActiveMobileView}>
        <Text color={'secondaryText'} textAlign={'right'} cursor={'pointer'}>
          {props.isCodeActiveMobileView ? 'Hide code' : 'View code'}
        </Text>
      </styles.showHideContainer>
    </styles.container>
  );
};

const styles = {
  container: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: ${colors.base};
    height: 52px;
    user-select: none;
  `,
  titleContainer: styled.div`
    display: flex;
    justify-content: center;
    flex: 3;
    align-items: center;
    height: 52px;
    min-width: 0;

    ${device.mobile} {
      display: none;
    }
  `,
  titleInput: styled.input`
    background: ${colors.base};
    height: 52px;
    padding: 0px;
    outline: none;
    border: none;
    font-size: 13px;
    color: ${colors.primaryText};
    text-align: center;
    width: 100%;
  `,
  logoContainer: styled.div`
    cursor: pointer;
    z-index: 90;
    height: 52px;
    box-sizing: border-box;
    padding: 14px 30px;
    width: 104px;
  `,
  actionsContainer: styled.div`
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: flex-end;
    margin-right: 11px;

    ${device.mobile} {
      display: none;
    }
  `,
  logoFlexContainer: styled.div`
    display: flex;
    flex: 1;

    ${device.mobile} {
      flex: 0;
    }
  `,
  showHideContainer: styled.div`
    display: none;
    height: 100%;
    width: 104px;
    align-items: center;
    justify-content: flex-end;
    padding-right: 20px;
    box-sizing: border-box;
    flex-shrink: 0;
    cursor: pointer;

    ${device.mobile} {
      display: flex;
    }
  `
};
