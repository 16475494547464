import {
  GeneralError,
  EmailInputError,
  PasswordInputError
} from '../constants/errors/authentication_errors';

export const isValidFileName = (name: string) => {
  return !!name.match(/^.*\.(jsx|tsx|js|css)$/i);
};

export const isValidEmail = (email: string) =>
  email.includes('@') && email.includes('.');

export const getEmailFromId = (id: string) => {
  const transformedId = id.split(',').join('.');
  return isValidEmail(transformedId) ? transformedId : undefined;
};

export const isValidEmailAndPassword = (email: string, password: string) => {
  if (!email || !password) {
    return {
      isValid: false,
      errorMessage: GeneralError.empty
    };
  }

  if (!isValidEmail(email)) {
    return {
      isValid: false,
      errorMessage: EmailInputError.invalid
    };
  }

  if (password.length < 6) {
    return {
      isValid: false,
      errorMessage: PasswordInputError.tooShort
    };
  }

  return { isValid: true, errorMessage: '' };
};
