import React from 'react';
import { colors } from '../../../constants/colors';
import styled from 'styled-components';

import { Avatar } from '../../general/Avatar';
import { ContextMenuProps } from '../../general/ContextMenu';
import { UserPresenceInfo } from '../../../hooks/usePresence';

type PlaygroundLeftSideBarItemProps = {
  fileId: string;
  isActiveFile: boolean;
  name: string;
  hasEditPermissions: boolean;
  users: UserPresenceInfo[];
  numberOfFiles: number;
  onDeleteFile: (id: string) => void;
  onChangeFile: (id: string, isDependencies?: boolean) => void;
} & ContextMenuProps;

export const PlaygroundLeftSideBarItem = (
  props: PlaygroundLeftSideBarItemProps
) => {
  const handleRightClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    event.preventDefault();
    event.stopPropagation();
    if (props.hasEditPermissions && props.numberOfFiles >= 2) {
      props.showContextMenu({
        coords: { x: event.pageX, y: event.pageY },
        actions: [
          { title: 'Delete', onClick: handleDeleteFile, isDestructive: true }
        ],
        id: props.fileId
      });
    } else {
      props.onChangeFile(props.fileId);
    }
  };

  const handleChangeFolder = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    if (event.button === 0) {
      props.onChangeFile(props.fileId);
    }
  };

  const handleDeleteFile = async () => {
    if (props.hasEditPermissions) {
      await props.onDeleteFile(props.fileId);
    }
  };

  return (
    <styles.container
      isActiveFile={props.isActiveFile}
      isContextMenuVisible={props.contextMenuData?.id === props.fileId}
      onContextMenu={handleRightClick}
      onMouseDown={handleChangeFolder}
    >
      <styles.fileName>{props.name}</styles.fileName>
      <styles.facePile>
        {props.users
          .slice(0, 3) // display up to 3
          .map((presence, index) => (
            <Avatar
              name={presence.name || presence.email || 'Anonymous'}
              key={presence.userId}
              size={16}
              zIndex={-index + 1}
              margin={'0px -3px 0px 0px'}
              src={presence.avatarUrl}
              cursor={'pointer'}
            />
          ))}
      </styles.facePile>
    </styles.container>
  );
};

type StyledContainerProps = {
  isActiveFile: boolean;
  isContextMenuVisible?: boolean;
};

const styles = {
  container: styled.div<StyledContainerProps>`
    display: flex;
    align-items: center;
    padding: 7px 7px 7px 19px;
    justify-content: space-between;
    color: ${props =>
      props.isActiveFile || props.isContextMenuVisible
        ? colors.primaryText
        : colors.secondaryText};
    border: ${props =>
      props.isContextMenuVisible
        ? `1px solid ${colors.blueberry}`
        : `1px solid ${colors.clear}`};
    border-radius: 6px;
    font-weight: ${props => (props.isActiveFile ? 600 : 400)};
    user-select: none;
    cursor: pointer;

    :hover {
      color: ${colors.primaryText};
    }
  `,
  facePile: styled.div`
    display: flex;
    flex-shrink: 0;
    margin-right: 3px;
  `,
  fileName: styled.div`
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    min-width: 0;
    font-size: 13px;
    line-height: 18px;
    margin-right: 2px;
  `
};
