import { TEMPLATES } from './constants';
import PreviewManager from '../PreviewManager';
import { IDependencies, IFiles } from '../declarations/Preview';

class Filesystem {
  private _previewManager?: PreviewManager;
  private _filesystem: IFiles = {};
  private _dependencies: IDependencies;
  private _activePlaygroundId?: string;

  constructor() {
    this._dependencies = TEMPLATES.CREATE_REACT_APP.dependencies;
  }

  private _attemptPreviewUpdate = () => {
    if (!this._previewManager) {
      return;
    }
    this._previewManager.updatePreview({
      files: { ...this._filesystem },
      dependencies: { ...this._dependencies }
    });
  };

  setFile = (path: string, code: string) => {
    this._filesystem[path] = { code };

    this._attemptPreviewUpdate();
  };

  setDependencies = (dependencies: IDependencies) => {
    this._dependencies = dependencies;

    this._attemptPreviewUpdate();
  };

  setManager = (instance: HTMLIFrameElement) => {
    this._previewManager = new PreviewManager(instance);
  };

  setActivePlaygroundId = (id: string) => {
    if (id !== this._activePlaygroundId) {
      this._activePlaygroundId = id;
      this._filesystem = {};
      this._dependencies = {};
    }
  };

  setActiveFilePath = (activeFilePath: string) => {
    this._previewManager?.setActiveFile(activeFilePath);
    // Try to update the preview with the new active file
    this._attemptPreviewUpdate();
  };

  refreshPreview = () => {
    this._previewManager?.refreshPreview();
  };

  getFiles = () => this._filesystem;
}

export default Filesystem;
