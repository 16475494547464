import React from 'react';

import { useToggle } from './hooks/useToggle';
import { SignUpModal } from './marketing/components/sign-up/SignUpModal';
import { MarketingLandingHeader } from './marketing/components/landing/MarketingLandingHeader';
import { MarketingLandingHero } from './marketing/components/landing/MarketingLandingHero';
import { MarketingLandingPlaygroundImage } from './marketing/components/landing/MarketingLandingPlaygroundImage';
import { MarketingLandingFooter } from './marketing/components/landing/MarketingLandingFooter';
import { MarketingLandingBasicCard } from './marketing/components/landing/MarketingLandingBasicCard';
import { PageContainer } from './marketing/components/general/PageContainer';
import { useMarketingBodyStyle } from './hooks/useMarketingBodyStyle';

const MarketingLanding = () => {
  useMarketingBodyStyle();

  const {
    value: isSignUpModalVisible,
    setTrue: showSignUpModal,
    setFalse: hideSignUpModal
  } = useToggle(false);

  return (
    <PageContainer>
      {isSignUpModalVisible && <SignUpModal onClose={hideSignUpModal} />}
      <MarketingLandingHeader onSignUp={showSignUpModal} />
      <MarketingLandingHero
        showWordMark
        showSignUp
        onSignUp={showSignUpModal}
        description={'Real-time code collaboration.'}
      />
      <MarketingLandingPlaygroundImage />
      <MarketingLandingBasicCard
        subheading={'Code'}
        title={'Share code like never before'}
        description={
          'Work in the same files at the same time with code always up to date. Start a new React project in just one click, and then share with a link.'
        }
        action={{
          title: 'Explore features',
          linkUrl: 'features'
        }}
        video={
          'https://www.dropbox.com/s/rbgpr8uywet6shl/Real%20Time%20Editing.mp4?raw=1'
        }
      />
      <MarketingLandingBasicCard
        flip
        subheading={'Teams'}
        title={'Fast and powerful, just like your team'}
        description={
          'Share playgrounds, files, and templates across your team. The fastest you have ever worked together.'
        }
        action={{
          title: 'More about teams',
          linkUrl: 'teams'
        }}
        video={'https://www.dropbox.com/s/ew9ny3kcgg0q2qj/Sharing.mp4?raw=1'}
      />
      <MarketingLandingBasicCard
        subheading={'Interviews'}
        title={'Give your candidates the best experience'}
        description={
          'All the tools needed to set up your candidates for success. The best way to interview React developers.'
        }
        action={{
          title: 'More about interviews',
          linkUrl: 'teams'
        }}
        video={'https://www.dropbox.com/s/ypxwwm6iwkqcde1/Interview.mp4?raw=1'}
      />
      <MarketingLandingFooter showSignUpForm />
    </PageContainer>
  );
};

export default MarketingLanding;
