import React from 'react';
import styled from 'styled-components';

import { Text } from '../general/Text';
import { colors } from '../../../constants/colors';

export const MarketingLandingPlaygroundImage = () => {
  return (
    <styles.linkContainer
      target={'blank'}
      href={'https://variable.app/p/0088wbsgZacahMVEORaY'}
    >
      <styles.imageContainer>
        <styles.overlay>
          <Text
            userSelect={'none'}
            cursor={'pointer'}
            fontWeight={500}
            color={'primaryText'}
          >
            Open playground
          </Text>
        </styles.overlay>
        <styles.lazyImage>
          <styles.video playsInline webkit-playsInline autoPlay loop muted>
            <source
              src={
                'https://www.dropbox.com/s/gfj00cm78ec5zag/Landing.mp4?raw=1'
              }
              type="video/mp4"
            />
          </styles.video>
        </styles.lazyImage>
      </styles.imageContainer>
    </styles.linkContainer>
  );
};

const styles = {
  linkContainer: styled.a`
    margin-top: 160px;
    display: block;
  `,
  video: styled.video`
    width: 100%;
    user-select: none;
    position: block;
    position: absolute;
  `,
  imageContainer: styled.div`
    position: relative;
    cursor: pointer;
    z-index: 0;
  `,
  overlay: styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    background: rgba(0, 0, 0, 0.6);
    transition: opacity 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;

    :hover {
      opacity: 1;
    }
  `,
  lazyImage: styled.div`
    padding-bottom: calc(1732 / 2600 * 100%);
    background: ${colors.base};
  `
};
