import { colors } from '../../constants/colors';
import styled from 'styled-components';

import { ColorTypes } from '../../constants/colors';

enum TypeScale {
  label = 'label',
  bodySmall = 'bodySmall',
  body = 'body',
  header = 'header',
  title = 'title',
  titleXL = 'titleXL'
}

type TextSizeProps = {
  fontSize: string;
  lineHeight: string;
  fontWeight: number;
};

const textSizes: {
  [key in TypeScale]: TextSizeProps;
} = {
  label: { fontSize: '11px', lineHeight: '18px', fontWeight: 600 },
  bodySmall: { fontSize: '12px', lineHeight: '18px', fontWeight: 400 },
  body: { fontSize: '13px', lineHeight: '18px', fontWeight: 400 },
  header: { fontSize: '16px', lineHeight: '24px', fontWeight: 700 },
  title: { fontSize: '22px', lineHeight: '30px', fontWeight: 700 },
  titleXL: { fontSize: '32px', lineHeight: '40px', fontWeight: 700 }
};

export type TextCursor = 'auto' | 'default' | 'pointer' | 'text';

type TextProps = {
  size?: 'label' | 'bodySmall' | 'body' | 'header' | 'title' | 'titleXL';
  fontWeight?: 400 | 500 | 600 | 700;
  textAlign?: 'left' | 'center' | 'right';
  color?: ColorTypes;
  hoverColor?: ColorTypes;
  pressedColor?: ColorTypes;
  margin?: string;
  padding?: string;
  capitalize?: boolean;
  userSelect?: 'auto' | 'text' | 'none' | 'all';
  cursor?: TextCursor;
  wrapText?: boolean;
  ellipsis?: boolean;
  flexShrink?: number;
  display?: 'inline-block';
};

export const Text = styled.p<TextProps>`
  font-size: ${props =>
    props.size ? textSizes[props.size].fontSize : textSizes.body.fontSize};
  line-height: ${props =>
    props.size ? textSizes[props.size].lineHeight : textSizes.body.lineHeight};
  font-weight: ${props =>
    props.fontWeight
      ? props.fontWeight
      : textSizes[props.size || 'body'].fontWeight};
  text-align: ${props => props.textAlign || 'left'};
  color: ${props => (props.color ? colors[props.color] : colors.primaryText)};
  margin: ${props => props.margin || 0};
  padding: ${props => props.padding || 0};
  user-select: ${props => props.userSelect || 'none'};
  white-space: ${props => (props.wrapText ? 'normal' : 'nowrap')};
  overflow: ${props => (props.wrapText ? 'visible' : 'hidden')};
  text-overflow: ${props => (props.ellipsis ? 'ellipsis' : 'default')};
  flex-shrink: ${props => props.flexShrink || 0};
  text-decoration: none;
  display: ${props => props.display && props.display};

  cursor: ${props => props.cursor || 'default'};

  ::first-letter {
    text-transform: ${props => (props.capitalize ? 'uppercase' : 'default')};
  }

  :hover {
    color: ${props => props.hoverColor && colors[props.hoverColor]};
  }

  :active {
    color: ${props => props.pressedColor && colors[props.pressedColor]};
  }
`;
