import styled from 'styled-components';

export const PageContainer = styled.div`
  margin: auto;
  max-width: 1200px;
  padding: 0px 40px;

  @media only screen and (max-width: 800px) {
    padding: 0px 24px;
  }
`;
