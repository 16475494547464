import React, { useState } from 'react';

import { Modal } from '../../general/Modal';
import { Input } from '../../general/Input';

type HomeSettingsEditEmailModalProps = {
  value: string;
  onClose: () => void;
  onSubmit: (email: string) => void;
};

export const HomeSettingsEditEmailModal = (
  props: HomeSettingsEditEmailModalProps
) => {
  const [inputValue, setInputValue] = useState(props.value);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleSubmit = () => {
    if (inputValue) {
      props.onSubmit(inputValue);
      props.onClose();
    }
  };

  return (
    <Modal
      title={'Change email'}
      action={{ title: 'Save', handler: handleSubmit }}
      hideSelf={props.onClose}
    >
      <Input
        autoFocus
        spellCheck={false}
        autoCorrect={'off'}
        autoComplete={'off'}
        placeholder={'Name'}
        margin={'24px 0px 0px 0px'}
        value={inputValue}
        onChange={handleInputChange}
      />
    </Modal>
  );
};
