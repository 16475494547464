import React from 'react';
import styled from 'styled-components';

import { colors } from '../../../constants/colors';
import { Text } from '../../general/Text';

type HomeTeamPageTabsProps = {
  tabs: Array<{ title: string }>;
  activeTab: number;
  setActiveTab: (index: number) => void;
};

export const HomeTeamPageTabs = (props: HomeTeamPageTabsProps) => {
  const handleChangeTab = (index: number) => props.setActiveTab(index);

  return (
    <styles.container>
      {props.tabs.map((tab, index) => (
        <Text
          key={`teampagetabs-${index}`}
          onMouseDown={() => handleChangeTab(index)}
          cursor={'pointer'}
          color={props.activeTab === index ? 'primaryText' : 'secondaryText'}
          fontWeight={props.activeTab === index ? 500 : 400}
          margin={index === 0 ? '0px' : '0px 0px 0px 20px'}
        >
          {tab.title}
        </Text>
      ))}
    </styles.container>
  );
};

const styles = {
  container: styled.div`
    display: flex;
    padding: 24px 0px 16px 0px;
    background: ${colors.base};
    border-bottom: 1px solid ${colors.W8};
    position: sticky;
    top: 120px;
    margin-bottom: 8px;
    z-index: 1;
    user-select: none;
  `
};
