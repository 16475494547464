import { useState, useEffect } from 'react';

import { PlaygroundInfo } from '../declarations/PlaygroundInfo';
import { Team } from '../declarations/Team';
import { Folder } from '../declarations/Folder';

import useHomeFolders from './useHomeFolders';
import { useHomeTeams } from './useHomeTeams';
import usePlaygroundInfo from './usePlaygroundInfo';
import analytics from '../Analytics/Analytics';

const useHomeManager = (
  user: firebase.User,
  teamId?: string,
  folderId?: string
): {
  teams: Team[];
  activeTeam: any;
  folders: Folder[];
  playgrounds: PlaygroundInfo[];
  templates: PlaygroundInfo[];
  activeFolder: Folder | undefined;
} => {
  const teams = useHomeTeams(user.uid);
  const fetchPlaygroundInfo = usePlaygroundInfo();

  const activeTeam = teams.find(team =>
    teamId && teamId !== 'settings'
      ? team.id === teamId
      : team.attributes?.isPersonal
  );

  const folders = useHomeFolders(
    user,
    activeTeam?.folderCollectionRef,
    activeTeam?.attributes?.isPersonal
  );

  const [playgrounds, setPlaygrounds] = useState<Array<PlaygroundInfo>>([]);
  const [templates, setTemplates] = useState<Array<PlaygroundInfo>>([]);

  const activeFolder = folders.find(folder =>
    teamId && (teamId === 'settings' || folderId)
      ? folder.id === folderId
      : folder.attributes?.isRecents
  );

  useEffect(() => {
    if (!activeFolder || !user.uid) {
      return;
    }

    const unsubscribePlaygroundObserver = activeFolder.playgroundCollectionRef.onSnapshot(
      async snapshot => {
        if (activeFolder.attributes?.isRecents) {
          try {
            const playgrounds = await Promise.all(
              snapshot.docs.map(async doc => ({
                ...(await fetchPlaygroundInfo(doc.id)),
                id: doc.id,
                lastViewed: doc.data().lastViewed
              }))
            );
            setPlaygrounds(
              playgrounds.filter(
                playground => typeof playground !== 'undefined'
              ) as Array<PlaygroundInfo>
            );
          } catch (e) {
            // do nothing
          }
        } else {
          setPlaygrounds(
            snapshot.docs.map(doc => {
              const data = doc.data() as PlaygroundInfo;
              return { ...data, id: doc.id };
            })
          );
        }
      },
      error => {
        analytics.track(
          'useHomeManager.folderPlaygroundsObserver.error',
          error
        );
      }
    );

    const unsubscribeTemplateObserver = activeFolder.templateCollectionRef.onSnapshot(
      snapshot => {
        setTemplates(
          snapshot.docs.map(doc => {
            const data = doc.data() as PlaygroundInfo;
            return { ...data, id: doc.id };
          })
        );
      },
      error => {
        analytics.track('useHomeManager.folderTemplatesObserver.error', error);
      }
    );

    return () => {
      unsubscribePlaygroundObserver();
      unsubscribeTemplateObserver();
    };
  }, [activeFolder, fetchPlaygroundInfo, user]);

  return { teams, activeTeam, folders, playgrounds, templates, activeFolder };
};

export { useHomeManager };
