import React, { useMemo } from 'react';
import styled from 'styled-components';

import { FirestoreProjectFile } from '../../../declarations/FirestoreProjectFile';
import { Text } from '../../general/Text';
import { device } from '../../../constants/deviceBreakpoints';
import { DropdownIcon } from '../../../icons/DropdownIcon';
import { useToggle } from '../../../hooks/useToggle';
import { Menu } from '../../general/Menu';
import { DEPENDENCIES_FILE_ID } from '../../../constants/keys';

type PlaygroundMobileFileDropdownProps = {
  files: Array<FirestoreProjectFile>;
  activeEditingFile: FirestoreProjectFile;
  onChangeFile: (id: string, isDependencies?: boolean) => void;
};

export const PlaygroundMobileFileDropdown = ({
  files,
  activeEditingFile,
  onChangeFile
}: PlaygroundMobileFileDropdownProps) => {
  const {
    value: isMenuVisible,
    setTrue: showMenu,
    setFalse: hideMenu
  } = useToggle(false);

  const fileName =
    activeEditingFile.name === DEPENDENCIES_FILE_ID
      ? 'Dependencies'
      : activeEditingFile.name;

  const fileList = useMemo(() => {
    return [
      ...files.map(file => ({
        title: file.name,
        onClick: () => onChangeFile(file.id)
      })),
      {
        title: 'divider'
      },
      {
        title: 'Dependencies',
        onClick: () => onChangeFile('', true)
      }
    ];
  }, [files, onChangeFile]);

  return (
    <styles.container>
      <StyledDropdownContainer
        onMouseDown={isMenuVisible ? hideMenu : showMenu}
      >
        <Text ellipsis flexShrink={1} cursor={'pointer'}>
          {fileName}
        </Text>
        <StyledIconContainer isMenuVisible={isMenuVisible}>
          <DropdownIcon />
        </StyledIconContainer>
      </StyledDropdownContainer>

      {isMenuVisible && (
        <styles.menuContainer>
          <Menu showCheckFor={fileName} onHide={hideMenu} actions={fileList} />
        </styles.menuContainer>
      )}
    </styles.container>
  );
};

const styles = {
  container: styled.div`
    display: none;
    max-width: calc(100% - 208px);

    ${device.mobile} {
      display: block;
    }
  `,
  menuContainer: styled.div`
    display: flex;
    justify-content: center;
    position: fixed;
    top: 48px;
    left: 0px;
    width: 100%;
    height: calc(100vh - 48px);
    z-index: 200;
  `
};

const StyledDropdownContainer = styled.div`
  display: flex;
  height: 52px;
  align-items: center;
  cursor: pointer;
`;

const StyledIconContainer = styled.div<{ isMenuVisible: boolean }>`
  display: inline-block;
  transition: transform 0.12s;
  transform: ${props =>
    props.isMenuVisible ? 'translateY(3px)' : 'translateY(1px)'};

  ${StyledDropdownContainer}:hover & {
    transform: translateY(3px);
  }
`;
