import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { colors } from '../../../constants/colors';
import { Text } from '../general/Text';
import { SignUpForm } from '../sign-up/SignUpForm';

type MarketingLandingFooterProps = {
  showSignUpForm?: boolean;
};

export const MarketingLandingFooter = (props: MarketingLandingFooterProps) => {
  return (
    <>
      {props.showSignUpForm && (
        <styles.signUpContainer>
          <SignUpForm />
        </styles.signUpContainer>
      )}
      <styles.container>
        <styles.column>
          <Text fontWeight={600}>Variable</Text>
        </styles.column>
        <styles.column>
          <Text fontWeight={600}>Product</Text>
          <styles.link to={'/features'}>Overview</styles.link>
          <styles.link to={'/teams'}>Teams</styles.link>
          <styles.link to={'/teams'}>Interviews</styles.link>
          <styles.link to={'/pricing'}>Pricing</styles.link>
        </styles.column>
        <styles.column>
          <Text fontWeight={600}>Company</Text>
          <styles.externalLink
            target="blank"
            href={
              'https://www.notion.so/maxsteitle/Media-Kit-5c5e79cea25e4c258572fbb883c755ea'
            }
          >
            Media kit
          </styles.externalLink>
          <styles.externalLink
            target="blank"
            href={'https://twitter.com/Variable_HQ'}
          >
            Twitter
          </styles.externalLink>
        </styles.column>
        <styles.column>
          <Text fontWeight={600}>Contact</Text>
          <styles.externalLink href={'mailto: hello@variable.app?subject=👋'}>
            Email us
          </styles.externalLink>
        </styles.column>
      </styles.container>
    </>
  );
};

const styles = {
  container: styled.div`
    display: grid;
    grid-template-columns: auto auto auto auto;
    margin: 120px auto;

    @media only screen and (max-width: 800px) {
      grid-template-columns: auto auto;
    }
  `,
  column: styled.div`
    margin: 80px 0px 0px 0px;
  `,
  link: styled(Link)`
    display: block;
    margin-top: 8px;
    font-size: 14px;
    line-height: 24px;
    cursor: pointer;
    text-decoration: none;
    color: ${colors.base};

    :hover {
      text-decoration: underline;
    }
  `,
  externalLink: styled.a`
    display: block;
    margin-top: 8px;
    font-size: 14px;
    line-height: 24px;
    cursor: pointer;
    text-decoration: none;
    color: ${colors.base};

    :hover {
      text-decoration: underline;
    }
  `,
  signUpContainer: styled.div`
    max-width: 340px;
    margin: auto;
    margin-top: 240px;
  `
};
