enum UndoManagerState {
  normal = 'normal',
  undoing = 'undoing',
  redoing = 'redoing'
}

export default class UndoManager {
  maxItems: number;
  state: UndoManagerState;

  dontCompose = false;

  undoStack;
  redoStack;

  constructor(maxItems?: number) {
    this.maxItems = maxItems || 50;
    this.state = UndoManagerState.normal;
    this.dontCompose = false;
    this.undoStack = [];
    this.redoStack = [];
  }

  // Add an operation to the undo or redo stack, depending on the current state
  // of the UndoManager. The operation added must be the inverse of the last
  // edit. When `compose` is true, compose the operation with the last operation
  // unless the last operation was alread pushed on the redo stack or was hidden
  // by a newer operation on the undo stack.
  add = (operation, compose) => {
    if (this.state === UndoManagerState.undoing) {
      this.redoStack.push(operation);
      this.dontCompose = true;
    } else if (this.state === UndoManagerState.redoing) {
      this.undoStack.push(operation);
      this.dontCompose = true;
    } else {
      var undoStack = this.undoStack;
      if (!this.dontCompose && compose && undoStack.length > 0) {
        undoStack.push(operation.compose(undoStack.pop()));
      } else {
        undoStack.push(operation);
        if (undoStack.length > this.maxItems) {
          undoStack.shift();
        }
      }
      this.dontCompose = false;
      this.redoStack = [];
    }
  };

  static transformStack = (stack, operation) => {
    var newStack = [];
    var Operation = operation.constructor;
    for (var i = stack.length - 1; i >= 0; i--) {
      var pair = Operation.transform(stack[i], operation);
      if (typeof pair[0].isNoop !== 'function' || !pair[0].isNoop()) {
        // TODO:
        // @ts-ignore
        newStack.push(pair[0]);
      }
      operation = pair[1];
    }
    return newStack.reverse();
  };

  // Transform the undo and redo stacks against a operation by another client.
  transform = (operation) => {
    this.undoStack = UndoManager.transformStack(this.undoStack, operation);
    this.redoStack = UndoManager.transformStack(this.redoStack, operation);
  };

  // Perform an undo by calling a function with the latest operation on the undo
  // stack. The function is expected to call the `add` method with the inverse
  // of the operation, which pushes the inverse on the redo stack.
  performUndo = (fn) => {
    this.state = UndoManagerState.undoing;
    if (this.undoStack.length === 0) {
      throw new Error('undo not possible');
    }
    fn(this.undoStack.pop());
    this.state = UndoManagerState.normal;
  };

  // The inverse of `performUndo`.
  performRedo = (fn) => {
    this.state = UndoManagerState.redoing;
    if (this.redoStack.length === 0) {
      throw new Error('redo not possible');
    }
    fn(this.redoStack.pop());
    this.state = UndoManagerState.normal;
  };

  // Is the undo stack not empty?
  canUndo = () => {
    return this.undoStack.length !== 0;
  };

  // Is the redo stack not empty?
  canRedo = () => {
    return this.redoStack.length !== 0;
  };

  // Whether the UndoManager is currently performing an undo.
  isUndoing = () => {
    return this.state === UndoManagerState.undoing;
  };

  // Whether the UndoManager is currently performing a redo.
  isRedoing = () => {
    return this.state === UndoManagerState.redoing;
  };
}
