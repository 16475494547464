import React, { useState, useRef } from 'react';
import styled from 'styled-components';

import { colors } from '../../../constants/colors';
import { Text } from '../general/Text';
import { Input } from '../general/Input';
import { Button } from '../general/Button';
import { LoadingSpinner } from '../../../components/general/LoadingSpinner';
import { GitHubIcon } from '../../../icons/GitHubIcon';
import { ErrorMessage } from './ErrorMessage';
import { getAuthenticationErrorMessage } from '../../../utilities/ErrorMessageUtility';
import { isValidEmailAndPassword } from '../../../utilities/ValidationUtilities';
import {
  GeneralError,
  EmailInputError,
  PasswordInputError,
  GeneralErrorMessages,
  EmailInputErrorMessages,
  PasswordInputErrorMessages
} from '../../../constants/errors/authentication_errors';
import { useHistory } from 'react-router';

import {
  useKeyboardShortcut,
  KeyboardShortcutKey
} from '../../../hooks/useKeyboardShortcut';
import { useAuthentication } from '../../../hooks/useAuthentication';

type SignUpFormProps = {
  autoFocus?: boolean;
  stayOnPage?: boolean;
  onSuccess?: () => void;
};

export const SignUpForm = (props: SignUpFormProps) => {
  const [isSigningUp, setIsSigningUp] = useState(true);
  const [emailInputValue, setEmailInputValue] = useState('');
  const [passwordInputValue, setPasswordInputValue] = useState('');
  const [error, setError] = useState<
    string | GeneralError | EmailInputError | PasswordInputError
  >('');
  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();

  const emailInputRef = useRef<HTMLInputElement | null>(null);

  const {
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    signInWithGithub
  } = useAuthentication();

  useKeyboardShortcut(KeyboardShortcutKey.Enter, () => {
    if (emailInputValue !== '' && passwordInputValue !== '') {
      handleSubmit();
    }
  });

  const getEmailAndPass = () => {
    const email = emailInputValue;
    const password = passwordInputValue;

    return { email, password };
  };

  const handleSubmit = async () => {
    const { email, password } = getEmailAndPass();

    const validatedEmailAndPassword = isValidEmailAndPassword(email, password);

    if (!validatedEmailAndPassword.isValid) {
      setError(validatedEmailAndPassword.errorMessage);
      return;
    }

    setIsLoading(true);

    let result;
    if (isSigningUp) {
      result = await createUserWithEmailAndPassword(email, password);
    } else {
      result = await signInWithEmailAndPassword(email, password);
    }
    if (!result?.success || result.error) {
      setError(getAuthenticationErrorMessage(result?.error) || '');
    }

    setIsLoading(false);

    if (result.success && props.onSuccess) {
      props.onSuccess();
    }

    if (!props.stayOnPage) {
      history.push('/');
    }
  };

  const handleGithubSubmit = async () => {
    const result = await signInWithGithub();
    if (!result?.success || result.error) {
      setError(getAuthenticationErrorMessage(result?.error) || '');
    }
    if (!props.stayOnPage) {
      history.push('/');
    }
  };

  const toggleIsSigningIn = () => {
    setIsSigningUp(prev => !prev);
    emailInputRef?.current?.focus();
  };

  const [generalError, emailInputError, passwordInputError]: Array<
    string | undefined
  > = [
    GeneralErrorMessages[error],
    EmailInputErrorMessages[error],
    PasswordInputErrorMessages[error]
  ];

  return (
    <>
      {generalError && (
        <ErrorMessage text={generalError} margin={'0px 0px 16px 0px'} />
      )}
      <Text
        cursor={'default'}
        userSelect={'none'}
        color={'base'}
        size={'header'}
      >
        {isSigningUp ? 'Sign up' : 'Sign in'}
      </Text>

      <Button
        fullWidth
        margin={'32px 0px 0px 0px'}
        XL
        onClick={handleGithubSubmit}
        borderRadius={8}
      >
        <styles.buttonContainer>
          <styles.iconContainer>
            <GitHubIcon color={colors.base} />
          </styles.iconContainer>
          {`Sign ${isSigningUp ? 'up' : 'in'} with GitHub`}
        </styles.buttonContainer>
      </Button>
      <Text
        userSelect={'none'}
        textAlign={'center'}
        margin={'16px 0px 0px 0px'}
        color={'editorDarkGrey'}
        fontWeight={500}
        size={'bodySmall'}
        cursor={'default'}
      >
        or
      </Text>
      <Input
        ref={emailInputRef}
        autoFocus={props.autoFocus}
        autoCorrect={'off'}
        spellCheck={false}
        placeholder={'Email'}
        type={'email'}
        margin={'16px 0px 0px 0px'}
        value={emailInputValue}
        onChange={e => setEmailInputValue(e.target.value)}
        error={!!emailInputError}
      />
      {emailInputError && (
        <ErrorMessage text={emailInputError} margin={'8px 0px 0px 0px'} />
      )}
      <Input
        autoCorrect={'off'}
        spellCheck={false}
        placeholder={'Password'}
        margin={'20px 0px 0px 0px'}
        type={'password'}
        value={passwordInputValue}
        onChange={e => setPasswordInputValue(e.target.value)}
        error={!!passwordInputError}
      />
      {passwordInputError && (
        <ErrorMessage text={passwordInputError} margin={'8px 0px 0px 0px'} />
      )}
      <Button
        fullWidth
        margin={'24px 0px 0px 0px'}
        borderRadius={8}
        XL
        primary
        onClick={handleSubmit}
      >
        {isLoading ? (
          <LoadingSpinner size={24} />
        ) : isSigningUp ? (
          'Create account'
        ) : (
          'Sign in'
        )}
      </Button>

      <Text
        userSelect={'none'}
        margin={'32px 0px 0px 0px'}
        textAlign={'center'}
        style={{ color: '#666' }}
        size={'bodySmall'}
        cursor={'default'}
      >
        {isSigningUp ? 'Already have an account? ' : 'No account? '}
        <styles.textAction onClick={toggleIsSigningIn}>
          {isSigningUp ? 'Sign in' : 'Sign up'}
        </styles.textAction>
      </Text>
      {/* TODO: @Forest - After launch */}
      {/* {!isSigningUp && (
        <Text
          userSelect={'none'}
          margin={'12px 0px 0px 0px'}
          fontWeight={500}
          cursor={'pointer'}
          textAlign={'center'}
          color={'blueberry'}
          size={'bodySmall'}
        >
          Forgot password?
        </Text>
      )} */}
    </>
  );
};

const styles = {
  container: styled.div`
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 200;
  `,
  buttonContainer: styled.div`
    display: flex;
    justify-content: center;
    height: 24px;
    align-items: center;
  `,
  iconContainer: styled.span`
    margin-right: 10px;
    height: 24px;
    padding: 0px;
  `,
  textAction: styled.span`
    color: ${colors.blueberry};
    font-weight: 500;
    cursor: pointer;
  `
};
