import React, { useRef } from 'react';
import { Modal } from '../general/Modal';

type UpgradeProTeamModalProps = {
  visible: boolean;
  onHide: () => void;
};

const UpgradeProTeamModal = ({ visible, onHide }: UpgradeProTeamModalProps) => {
  const linkRef = useRef<HTMLAnchorElement | null>(null);

  const onContactUsPressed = () => {
    linkRef.current?.click();
  };

  if (!visible) {
    return <></>;
  }

  return (
    <>
      <Modal
        title={'Upgrade to professional team'}
        description={
          'Professional teams are coming soon. With a professional team, you can add as many editors as you like.'
        }
        action={{
          title: 'Contact us',
          handler: onContactUsPressed
        }}
        hideSelf={onHide}
      />
      {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
      <a
        ref={linkRef}
        href="mailto:hello@variable.app?subject=I'm%20interested%20in%20upgrading%20to%20a%20professional%20team"
      />
    </>
  );
};

export default UpgradeProTeamModal;
