import {
  Client,
  Cursor,
  FileAdapterEvent,
  FileEditorClientEvent,
  TextOperation,
  FirebaseFileAdapter,
  MonacoFileAdapter
} from '.';

export default class FileEditorClient extends Client.default {
  fileAdapter: FirebaseFileAdapter;
  monacoFileAdapter: MonacoFileAdapter;

  cursor: Cursor | null = null;

  focused = false;

  allowedEvents_: Array<string>;
  eventListeners_ = {};

  constructor(
    fileAdapter: FirebaseFileAdapter,
    monacoFileAdapter: MonacoFileAdapter
  ) {
    super();

    this.fileAdapter = fileAdapter;
    // this.editorAdapter = editorAdapter;
    this.monacoFileAdapter = monacoFileAdapter;

    // TODO: type all events
    this.allowedEvents_ = ['synced', FileEditorClientEvent.change];

    this.monacoFileAdapter.registerCallbacks({
      change: (operation: TextOperation, inverse: TextOperation) => {
        this.onChange(operation, inverse);
      }
    });

    this.fileAdapter.registerCallbacks({
      ack: fileId => {
        this.serverAck();

        this.emitStatus();
      },
      retry: () => {
        this.serverRetry();
      },
      operation: (fileId, fileName, operation, document) => {
        this.applyServer(operation);
        // this.triggerEvent(FileEditorClientEvent.change)
        this.fileAdapter.trigger(FileAdapterEvent.change, document);
      }
    });
  }

  // Change from monaco to be applied here
  onChange = (textOperation: TextOperation, inverse: TextOperation) => {
    // var cursorBefore = this.cursor;
    // this.updateCursor();

    // var compose =
    //   this.undoManager.undoStack.length > 0 &&
    //   inverse.shouldBeComposedWithInverted(
    //     FileEditorClient.last(this.undoManager.undoStack).wrapped
    //   );
    // var inverseMeta = new SelfMeta(this.cursor, cursorBefore);
    // this.undoManager.add(new WrappedOperation(inverse, inverseMeta), compose);
    this.applyClient(textOperation);
  };

  sendOperation = operation => {
    this.fileAdapter?.sendOperation(operation);
    this.emitStatus();
  };

  applyOperation = operation => {
    this.fileAdapter.monacoFileAdapter?.applyOperation(operation);
    // TODO: Do we need this
    // this.updateCursor();
  };

  emitStatus = () => {
    setTimeout(() => {
      this.trigger('synced', this.state instanceof Client.Synchronized);
    }, 0);
  };

  // EVENT EMIITER STUFF

  on = (
    eventType: string,
    callback: (...args: Array<any>) => void,
    context?
  ) => {
    this.validateEventType_(eventType);
    this.eventListeners_ = this.eventListeners_ || {};
    this.eventListeners_[eventType] = this.eventListeners_[eventType] || [];
    this.eventListeners_[eventType].push({
      callback: callback,
      context: context
    });
  };

  off = (eventType: string, callback: VoidFunction) => {
    this.validateEventType_(eventType);
    this.eventListeners_ = this.eventListeners_ || {};
    var listeners = this.eventListeners_[eventType] || [];
    for (var i = 0; i < listeners.length; i++) {
      if (listeners[i].callback === callback) {
        listeners.splice(i, 1);
        return;
      }
    }
  };

  trigger = (eventType: string, ...args) => {
    this.eventListeners_ = this.eventListeners_ || {};
    var listeners = this.eventListeners_[eventType] || [];
    for (var i = 0; i < listeners.length; i++) {
      listeners[i].callback.apply(listeners[i].context, args);
    }
  };

  validateEventType_ = (eventType: string) => {
    if (this.allowedEvents_) {
      var allowed = false;
      for (var i = 0; i < this.allowedEvents_.length; i++) {
        if (this.allowedEvents_[i] === eventType) {
          allowed = true;
          break;
        }
      }
      if (!allowed) {
        throw new Error('Unknown event "' + eventType + '"');
      }
    }
  };

  static last = arr => {
    return arr[arr.length - 1];
  };
}
