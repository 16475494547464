import React from 'react';
import styled from 'styled-components';

import { Text } from '../../general/Text';
import { FirestoreProjectFile } from '../../../declarations/FirestoreProjectFile';
import { PlaygroundLeftSideBarItem } from './PlaygroundLeftSideBarItem';
import { DEPENDENCIES_FILE_ID } from '../../../constants/keys';
import { UserPresenceInfo } from '../../../hooks/usePresence';
import { withContextMenu, ContextMenuProps } from '../../general/ContextMenu';

type PlaygroundLeftSideBarProps = {
  files: Array<FirestoreProjectFile>;
  activeEditingFile: FirestoreProjectFile;
  users: Array<UserPresenceInfo>;
  visible: boolean;
  viewOnly: boolean;
  hideSideBar: () => void;
  onChangeFile: (id: string, isDependencies?: boolean) => void;
  onAddFile: () => void;
  onDeleteFile: (id: string) => void;
} & ContextMenuProps;

const PlaygroundLeftSideBar = (props: PlaygroundLeftSideBarProps) => {
  const onDependenciesSelected = () => {
    props.onChangeFile('', true);
  };

  const handleAddFile = () => {
    props.onAddFile();
  };

  return (
    <>
      {props.visible && (
        <styles.overlay
          onMouseDown={props.hideSideBar}
          onMouseMove={props.contextMenuData ? undefined : props.hideSideBar}
        />
      )}
      <styles.container visible={props.visible}>
        <div>
          {props.files
            .sort((left, right) => (left.name > right.name ? 1 : -1))
            .map(file => (
              <PlaygroundLeftSideBarItem
                name={file.name}
                fileId={file.id}
                key={file.id}
                isActiveFile={file.name === props.activeEditingFile.name}
                hasEditPermissions={!props.viewOnly}
                onDeleteFile={props.onDeleteFile}
                onChangeFile={props.onChangeFile}
                showContextMenu={props.showContextMenu}
                contextMenuData={props.contextMenuData}
                users={props.users.filter(
                  presence =>
                    presence.activeFile === file.realtimeDatabaseRef?.key
                )}
                numberOfFiles={props.files.length}
              />
            ))}
          {!props.viewOnly && (
            <Text
              onClick={handleAddFile}
              color={'blueberry'}
              hoverColor={'blueberryLight'}
              padding={'8px 20px'}
              cursor={'pointer'}
            >
              New file
            </Text>
          )}
        </div>

        <Text
          onMouseDown={onDependenciesSelected}
          color={
            props.activeEditingFile.id === DEPENDENCIES_FILE_ID
              ? 'primaryText'
              : 'secondaryText'
          }
          fontWeight={
            props.activeEditingFile.id === DEPENDENCIES_FILE_ID ? 500 : 400
          }
          hoverColor={'primaryText'}
          padding={'8px 20px'}
          margin={'0px 0px 12px 0px'}
          cursor={'pointer'}
        >
          Dependencies
        </Text>
      </styles.container>
    </>
  );
};

type StyledContainerProps = {
  visible: boolean;
};

const styles = {
  container: styled.div<StyledContainerProps>`
    position: absolute;
    top: 52px;
    left: 0px;
    box-sizing: border-box;
    height: calc(100vh - 52px);
    width: 180px;
    transform: ${props =>
      props.visible ? `translateX(${0}px)` : `translateX(${-180}px)`};
    transition: transform 0.2s;
    z-index: 40;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: auto;
  `,
  overlay: styled.div`
    position: absolute;
    top: 0px;
    left: 180px;
    width: 100vw;
    height: 100vh;
    z-index: 199;
  `
};

export default withContextMenu(PlaygroundLeftSideBar);
