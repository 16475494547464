import { useCallback, useContext } from 'react';
import { FirebaseContext } from '../Firebase';

import { throttle } from 'lodash';
import analytics from '../Analytics/Analytics';

const useModifiedManager = (playgroundId: string) => {
  const firebase = useContext(FirebaseContext);

  const updatePlaygroundLastModified = useCallback(
    throttle(
      () => {
        if (!firebase || !playgroundId) {
          return;
        }

        firebase
          .firestore()
          .doc(`playgrounds/${playgroundId}`)
          .update({ lastModified: firebase.app().firestore.Timestamp.now() })
          .catch(error => {
            analytics.track(
              'useModifiedManager.updateLastModified.error',
              error
            );
          });
      },
      2 * 60 * 1000,
      { leading: true, trailing: false }
    ),
    [firebase, playgroundId]
  );

  return updatePlaygroundLastModified;
};

export { useModifiedManager };
