import React from 'react';
import { colors } from '../constants/colors';
import { IconProps } from './IconProps';

export const SpinnerIcon = ({ size, color }: IconProps) => (
  <svg
    width={size || 24}
    height={size || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19 12C19 10.6155 18.5895 9.26215 17.8203 8.11101C17.0511 6.95986 15.9579 6.06266 14.6788 5.53284C13.3997 5.00303 11.9922 4.86441 10.6344 5.1345C9.2765 5.4046 8.02922 6.07129 7.05025 7.05025C6.07128 8.02922 5.4046 9.2765 5.1345 10.6344C4.86441 11.9922 5.00303 13.3997 5.53284 14.6788C6.06266 15.9579 6.95986 17.0511 8.11101 17.8203C9.26215 18.5895 10.6155 19 12 19"
      stroke="url(#paint0_linear)"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="12"
        y1="12"
        x2="12"
        y2="19"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={color || colors.primaryText} />
        <stop
          offset="1"
          stopColor={color || colors.primaryText}
          stopOpacity="0"
        />
      </linearGradient>
    </defs>
  </svg>
);
