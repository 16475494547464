import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import MarketingFeatures from './MarketingFeatures';
import MarketingTeams from './MarketingTeams';
import MarketingPricing from './MarketingPricing';
import MarketingLanding from './MarketingLanding';

const MarketingRouter = () => {
  const { teamId } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [teamId]);

  switch (teamId) {
    case 'features':
      return <MarketingFeatures />;
    case 'teams':
      return <MarketingTeams />;
    case 'pricing':
      return <MarketingPricing />;
    default:
      return <MarketingLanding />;
  }
};

export default MarketingRouter;
