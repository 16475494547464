import { Client, Cursor, UndoManager, MonacoAdapter, FirebaseAdapter } from '.';

export default class EditorClient extends Client.default {
  firebaseAdapter: FirebaseAdapter;
  editorAdapter: MonacoAdapter;
  undoManager;

  clients: Record<string, OtherClient>;
  cursor: Cursor | null = null;

  focused = false;

  allowedEvents_: Array<string>;
  eventListeners_ = {};

  constructor(serverAdapter: FirebaseAdapter, editorAdapter: MonacoAdapter) {
    super();

    this.firebaseAdapter = serverAdapter;
    this.editorAdapter = editorAdapter;
    this.undoManager = new UndoManager();

    this.clients = {};

    // TODO: type all events
    this.allowedEvents_ = ['synced'];

    this.editorAdapter.registerCallbacks({
      cursorActivity: () => {
        this.onCursorActivity();
      },
      blur: () => {
        this.onBlur();
      },
      focus: () => {
        this.onFocus();
      }
    });

    this.firebaseAdapter.registerCallbacks({
      ack: () => {
        this.serverAck();
        if (this.focused && this.state instanceof Client.Synchronized) {
          this.updateCursor();
          this.sendCursor(this.cursor);
        }
        this.emitStatus();
      },
      cursor: (
        clientId,
        cursor?: { position: number; selectionEnd: number },
        color?: string,
        activeFileId?: string
      ) => {
        if (
          this.firebaseAdapter.userId_ === clientId ||
          !(this.state instanceof Client.Synchronized)
        ) {
          return;
        }
        var client = this.getClientObject(clientId);
        // We only want to set cursors in the active file
        if (cursor && activeFileId === this.firebaseAdapter.activeFileId) {
          if (color) client.setColor(color);
          client.updateCursor(Cursor.fromJSON(cursor));
        } else {
          client.removeCursor();
        }
      }
    });
  }

  getClientObject = clientId => {
    var client = this.clients[clientId];
    if (client) {
      return client;
    }
    return (this.clients[clientId] = new OtherClient(
      clientId,
      this.editorAdapter
    ));
  };

  updateCursor = () => {
    this.cursor = this.editorAdapter.getCursor();
  };

  onCursorActivity = () => {
    var oldCursor = this.cursor;
    this.updateCursor();
    if (!this.focused || (oldCursor && this.cursor?.equals(oldCursor))) {
      return;
    }
    this.sendCursor(this.cursor);
  };

  onBlur = () => {
    this.cursor = null;
    this.sendCursor(null);
    this.focused = false;
  };

  onFocus = () => {
    this.focused = true;
    this.onCursorActivity();
  };

  sendCursor = cursor => {
    if (this.state instanceof Client.AwaitingWithBuffer) {
      return;
    }
    this.firebaseAdapter.sendCursor(cursor);
  };

  emitStatus = () => {
    setTimeout(() => {
      this.trigger('synced', this.state instanceof Client.Synchronized);
    }, 0);
  };

  // EVENT EMIITER STUFF

  on = (eventType: string, callback: (e: any) => void, context?) => {
    this.validateEventType_(eventType);
    this.eventListeners_ = this.eventListeners_ || {};
    this.eventListeners_[eventType] = this.eventListeners_[eventType] || [];
    this.eventListeners_[eventType].push({
      callback: callback,
      context: context
    });
  };

  off = (eventType: string, callback: VoidFunction) => {
    this.validateEventType_(eventType);
    this.eventListeners_ = this.eventListeners_ || {};
    var listeners = this.eventListeners_[eventType] || [];
    for (var i = 0; i < listeners.length; i++) {
      if (listeners[i].callback === callback) {
        listeners.splice(i, 1);
        return;
      }
    }
  };

  trigger = (eventType: string, ...args) => {
    this.eventListeners_ = this.eventListeners_ || {};
    var listeners = this.eventListeners_[eventType] || [];
    for (var i = 0; i < listeners.length; i++) {
      listeners[i].callback.apply(listeners[i].context, args);
    }
  };

  validateEventType_ = (eventType: string) => {
    if (this.allowedEvents_) {
      var allowed = false;
      for (var i = 0; i < this.allowedEvents_.length; i++) {
        if (this.allowedEvents_[i] === eventType) {
          allowed = true;
          break;
        }
      }
      if (!allowed) {
        throw new Error('Unknown event "' + eventType + '"');
      }
    }
  };

  static last = arr => {
    return arr[arr.length - 1];
  };
}

class OtherClient {
  id: string;
  editorAdapter: MonacoAdapter;

  color?: string;
  mark;
  cursor?: Cursor;
  constructor(id: string, editorAdapter) {
    this.id = id;
    this.editorAdapter = editorAdapter;
  }

  setColor = color => {
    this.color = color;
  };

  updateCursor = cursor => {
    this.removeCursor();
    this.cursor = cursor;

    if (!this.color) {
      return;
    }

    this.mark = this.editorAdapter.setOtherCursor(cursor, this.color, this.id);
  };

  removeCursor = () => {
    if (this.mark) {
      this.mark.clear();
    }
  };
}
