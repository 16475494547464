import React from 'react';

import { useToggle } from './hooks/useToggle';

import { SignUpModal } from './marketing/components/sign-up/SignUpModal';
import { MarketingLandingHeader } from './marketing/components/landing/MarketingLandingHeader';
import { MarketingLandingHero } from './marketing/components/landing/MarketingLandingHero';
import { MarketingLandingFooter } from './marketing/components/landing/MarketingLandingFooter';
import { MarketingLandingBasicCard } from './marketing/components/landing/MarketingLandingBasicCard';
import { PageContainer } from './marketing/components/general/PageContainer';
import { useMarketingBodyStyle } from './hooks/useMarketingBodyStyle';

const MarketingTeams = () => {
  useMarketingBodyStyle();

  const {
    value: isSignUpModalVisible,
    setTrue: showSignUpModal,
    setFalse: hideSignUpModal
  } = useToggle(false);

  return (
    <PageContainer>
      {isSignUpModalVisible && <SignUpModal onClose={hideSignUpModal} />}
      <MarketingLandingHeader onSignUp={showSignUpModal} />
      <MarketingLandingHero
        showSignUp
        onSignUp={showSignUpModal}
        title={'Where teams code together'}
        description={
          'Share playgrounds, templates, interviews, and folders across your team.'
        }
      />
      <MarketingLandingBasicCard
        title={'Collaborate'}
        description={
          'Invite Designers, Product Managers, and the rest of your team. Then, make changes on the spot.'
        }
        marginTop={240}
        image={'https://www.dropbox.com/s/xok3hz0b5e7wvf3/Teams.png?raw=1'}
      />
      <MarketingLandingBasicCard
        title={'Interviews'}
        description={
          'Real-time editing with a built-in preview, the best way to interview React developers. Interviews are automatically updated after the interviewee joins.'
        }
        image={
          'https://www.dropbox.com/s/4l9jmyfhfgc777a/Teams%20Interviews.png?raw=1'
        }
      />
      <MarketingLandingBasicCard
        title={'A shared space'}
        description={
          'Everyone on the team has access to shared folders, playgrounds, and templates.'
        }
        image={
          'https://www.dropbox.com/s/slzvrqw8eodg55x/Teams%20Your%20Team.png?raw=1'
        }
      />
      <MarketingLandingBasicCard
        title={'Remote development on another level'}
        description={
          "Sharing code across your team, presenting to stakeholders, or collaborating with the Designer you're in crunch time with has never been easier."
        }
        image={
          'https://www.dropbox.com/s/oyxw78j9tl0tfxj/Teams%20Remote.png?raw=1'
        }
      />

      <MarketingLandingFooter showSignUpForm />
    </PageContainer>
  );
};

export default MarketingTeams;
