import React from 'react';

import { colors } from '../constants/colors';
import { IconProps } from './IconProps';

export const Logo = ({ margin, color, size }: IconProps) => (
  <svg
    style={{ margin, flexShrink: 0 }}
    width={size || 24}
    height={size || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.02586 16.86C6.71919 16.5533 6.56586 16.1867 6.56586 15.76C6.56586 15.3333 6.71253 14.9733 7.00586 14.68C7.31253 14.3733 7.65919 14.22 8.04586 14.22C8.44586 14.22 8.77919 14.3333 9.04586 14.56C9.32586 14.7733 9.51253 15.0467 9.60586 15.38C10.1792 14.82 10.6259 14.04 10.9459 13.04L9.00586 8.96C8.83253 8.61333 8.67919 8.38 8.54586 8.26C8.42586 8.12667 8.25253 8.06 8.02586 8.06C7.57253 8.06 7.12586 8.25333 6.68586 8.64L6.30586 8.16C7.33253 7.18667 8.40586 6.7 9.52586 6.7C10.6459 6.7 11.4325 7.18 11.8859 8.14L12.7459 9.96C13.1059 9 13.5725 8.22 14.1459 7.62C14.7192 7.00667 15.3725 6.7 16.1059 6.7C16.5325 6.7 16.8925 6.85333 17.1859 7.16C17.4925 7.45333 17.6459 7.81333 17.6459 8.24C17.6459 8.66667 17.4925 9.03333 17.1859 9.34C16.8925 9.63333 16.5459 9.78 16.1459 9.78C15.7459 9.78 15.4059 9.67333 15.1259 9.46C14.8592 9.24667 14.6859 8.96667 14.6059 8.62C14.0192 9.19333 13.5659 9.98667 13.2459 11L15.1859 15.04C15.3592 15.3867 15.5059 15.6267 15.6259 15.76C15.7592 15.88 15.9392 15.94 16.1659 15.94C16.6192 15.94 17.0659 15.7467 17.5059 15.36L17.8859 15.84C16.8592 16.8133 15.7859 17.3 14.6659 17.3C13.5459 17.3 12.7592 16.82 12.3059 15.86L11.4459 14.08C11.0859 15.04 10.6259 15.82 10.0659 16.42C9.50586 17.0067 8.87253 17.3 8.16586 17.3C7.71253 17.3 7.33253 17.1533 7.02586 16.86Z"
      fill={color || colors.primaryText}
    />
  </svg>
);
