import React, { useRef } from 'react';
import { colors } from '../../../constants/colors';
import { Animations } from '../../../constants/animations';
import styled from 'styled-components';

import { Team } from '../../../declarations/Team';

import { CheckIcon } from '../../../icons/CheckIcon';
import { Logo } from '../../../icons/Logo';
import { Avatar } from '../../general/Avatar';

import { useOnClickOutside } from '../../../hooks/useOnClickOutside';
import {
  useKeyboardShortcut,
  KeyboardShortcutKey
} from '../../../hooks/useKeyboardShortcut';
import { useHistory } from 'react-router-dom';

type HomeLeftSideBarTeamSwitcherProps = {
  teams: Array<Team>;
  activeTeamId: string;
  hideSelf: () => void;
  navigateToSettings: () => void;
  showHomeNewTeamModal: () => void;
};

export const HomeLeftSideBarTeamSwitcher = (
  props: HomeLeftSideBarTeamSwitcherProps
) => {
  const switcherRef = useRef<HTMLDivElement | null>(null);
  const history = useHistory();

  useOnClickOutside(switcherRef, props.hideSelf);
  useKeyboardShortcut(KeyboardShortcutKey.Escape, props.hideSelf);

  const onChangeTeam = (teamId: string) => {
    history.push(`/${teamId}`);
    props.hideSelf();
  };

  const navigateToSettings = () => {
    props.navigateToSettings();
    props.hideSelf();
  };

  const showHomeNewTeamModal = () => {
    props.showHomeNewTeamModal();
    props.hideSelf();
  };

  return (
    <styles.container ref={switcherRef}>
      <styles.listItem
        secondary
        onMouseUp={showHomeNewTeamModal}
        onClick={showHomeNewTeamModal}
      >
        <styles.text>New team</styles.text>
      </styles.listItem>
      {props.teams
        .sort((left, right) => {
          if (right.attributes?.isPersonal) {
            return -1;
          } else {
            return 0;
          }
        })
        .map(team => (
          <styles.listItem
            key={team.id}
            onMouseUp={() => onChangeTeam(team.id)}
            onClick={() => onChangeTeam(team.id)}
          >
            <CheckIcon
              color={
                team.id === props.activeTeamId
                  ? colors.primaryText
                  : colors.clear
              }
            />
            <styles.text>{team.name}</styles.text>
            {team.name === 'Personal' ? (
              <Logo size={24} />
            ) : (
              <Avatar
                src={team.avatarUrl}
                name={team.name}
                boxShadow={colors.clear}
                size={24}
                cursor={'pointer'}
              />
            )}
          </styles.listItem>
        ))}
      <styles.divider />
      <styles.listItem
        onMouseUp={navigateToSettings}
        onClick={navigateToSettings}
      >
        <styles.text>Settings</styles.text>
      </styles.listItem>
    </styles.container>
  );
};

type StyledListItemProps = {
  secondary?: boolean;
};

const styles = {
  container: styled.div`
    position: fixed;
    bottom: 78px;
    left: 12px;
    padding: 8px 0px;
    background: ${colors.base};
    box-shadow: inset 0px 0px 0px 1px ${colors.W12},
      0px 8px 24px ${colors.dropShadow};
    border-radius: 8px;
    display: inline-block;
    z-index: 20;
    width: 216px;
    ${Animations.BounceUp}
  `,
  listItem: styled.div<StyledListItemProps>`
    display: grid;
    align-items: center;
    height: 40px;
    grid-template-columns: [check] 16px [text] auto [logo] 24px;
    grid-gap: 10px;
    padding: 0px 20px 0px 12px;
    user-select: none;
    font-size: 13px;
    line-height: 18px;
    color: ${props =>
      props.secondary ? colors.secondaryText : colors.primaryText};
    cursor: pointer;

    :hover {
      background: ${colors.blueberry};
      color: ${colors.white};
    }

    :hover:active {
      background: ${colors.blueberryHover};
    }
  `,
  divider: styled.div`
    margin: 8px 1px;
    height: 1px;
    background: ${colors.W12};
  `,
  text: styled.p`
    grid-column-start: text;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  `
};
