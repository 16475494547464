const TEMPLATES = {
  CREATE_REACT_APP: {
    dependencies: {
      react: '16.8.3',
      'react-dom': '16.8.3'
    },
    files: {
      '/src/index.jsx': {
        code: `\
                import React from "react";
                import { render } from "react-dom";
                import ReactPreview from "./ReactPreview";
                
                import "./styles.css";
                
                function App() {
                  return (
                    <div><ReactPreview /></div>
                  );
                }
                
                render(<App />, document.getElementById("app"));`
      },
      '/src/ReactPreview.jsx': {
        code: `\
                    import React from 'react';
                
                    import * as Previews from './Sandbox';
                
                    const ReactPreview = () => {
                      const getElement = Object.values(Previews)[0];
          
                      if (typeof getElement !== 'function') {
                          return <div> no element to render</div>;
                      }
                
                      return <div style={{ display: 'flex', height: '100vh', flex: 1, justifyContent: 'center', alignItems: 'center' }}><div>{getElement()}</div></div>;
                    };
                
                    export default ReactPreview;
                    `
      },
      '/src/Sandbox.jsx': {
        code: ''
      },
      '/src/styles.css': {
        code: `\
                .App {
                  font-family: sans-serif;
                  text-align: center;
                  background-color: #eee;
                }`
      },
      '/public/index.html': {
        code: `\
                <!DOCTYPE html>
                <html lang="en">
                <head>
                  <meta charset="utf-8">
                  <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
                  <title>Hello React</title>
                </head>
                <body>
                  <noscript>You need to enable JavaScript to run this app.</noscript>
                  <div id="app"></div>
                </body>
                </html>`
      }
    }
  }
};

export { TEMPLATES };
