import React from 'react';
import styled from 'styled-components';

import { TeamMember } from '../../../declarations/TeamMember';
import {
  TEAM_PERMISSIONS_TITLES,
  TeamPermissions
} from '../../../declarations/TeamPermissions';

import { Text } from '../../general/Text';
import { Avatar } from '../../general/Avatar';

import { colors } from '../../../constants/colors';
import { PermissionsDropdown } from '../../general/PermissionsDropdown';
import { getEmailFromId } from '../../../utilities/ValidationUtilities';

type HomeTeamPageMemberRowProps = {
  currentUser: firebase.User;
  editablePermissions: TeamPermissions[];
  member: TeamMember;
  updateMemberPermissions: (
    member: TeamMember,
    newPermissions: TeamPermissions
  ) => Promise<void>;
};

export const HomeTeamPageMemberRow = ({
  currentUser,
  editablePermissions,
  member,
  updateMemberPermissions
}: HomeTeamPageMemberRowProps) => {
  return (
    <styles.rowItem key={member.id}>
      <Avatar
        src={undefined}
        name={member.name || member.email}
        boxShadow={colors.clear}
      />
      <Text ellipsis>
        {member.name || member.email || getEmailFromId(member.id)}
        {member.id === currentUser.uid && ' (You)'}
      </Text>
      <Text
        color={'secondaryText'}
        cursor={'text'}
        userSelect={'text'}
        ellipsis
      >
        {member.email}
      </Text>
      <PermissionsDropdown
        isCurrentUser={member.id === currentUser.uid}
        permissionsTitles={TEAM_PERMISSIONS_TITLES}
        actions={editablePermissions.map(permission => {
          return {
            title: TEAM_PERMISSIONS_TITLES[permission],
            onClick: () => updateMemberPermissions(member, permission)
          };
        })}
        role={member.permissions}
        type={'user'}
        menuPosition={{
          y: 8,
          x: -36
        }}
      />
    </styles.rowItem>
  );
};

const styles = {
  rowItem: styled.div`
    position: relative;
    display: grid;
    grid-template-columns: [avatar] 30px [name] 1fr [email] 1fr [permission] 72px;
    align-items: center;
    grid-gap: 16px;
    height: 50px;
  `
};
