import { useState, useEffect } from 'react';
import { NEW_TEAM_FTE } from '../constants/keys';

type FirstTimeExperienceKeys = typeof NEW_TEAM_FTE;

export const useFirstTimeExperience = (key: FirstTimeExperienceKeys) => {
  const [isVisible, setIsVisible] = useState();

  useEffect(() => {
    const viewedTip = window.localStorage.getItem(key) === '' ? false : true;
    setIsVisible(viewedTip);
  }, [key]);

  const hideTip = () => {
    setIsVisible(false);
    window.localStorage.setItem(key, '');
  };

  return [isVisible, hideTip];
};
