import { FirebaseContext } from '../Firebase';
import { useContext, useCallback, useRef, useState, useEffect } from 'react';
import { editor } from 'monaco-editor';
import RealtimeManager from '../RealtimeManager';

/*
 * ***** IMPORTANT *****
 *
 * we should only ever create 1
 * realtime manager per playground
 *
 */
const useRealtimeManager = (
  activePlaygroundId: string,
  currentUserId: string,
  activeFileRealtimeId: string
) => {
  const firebase = useContext(FirebaseContext);

  const realtimeManager = useRef<RealtimeManager | null>(null);
  const monacoEditor = useRef<editor.IStandaloneCodeEditor | null>(null);

  const [isEditorMounted, setIsEditorMounted] = useState(false);

  const onEditorMount = useCallback(
    (codeEditor: editor.IStandaloneCodeEditor) => {
      if (!firebase) {
        return;
      }

      const firestore = firebase.firestore();
      const database = firebase.database();

      const realtimePlaygroundRef = database
        .ref()
        .child('playgrounds')
        .child(activePlaygroundId);

      const firestoreFilesRef = firestore
        .collection('playgrounds')
        .doc(activePlaygroundId)
        .collection('files');

      monacoEditor.current = codeEditor;
      realtimeManager.current = new RealtimeManager(
        // realtimeFileRefs,
        realtimePlaygroundRef,
        firestoreFilesRef,
        activeFileRealtimeId,
        codeEditor,
        { userId: currentUserId }
      );

      setIsEditorMounted(true);
    },
    [activeFileRealtimeId, activePlaygroundId, currentUserId, firebase]
  );

  useEffect(() => {
    return () => {
      realtimeManager.current?.dispose();
      editor.getModels().forEach(model => model.dispose());
    };
  }, []);

  useEffect(() => {
    if (!isEditorMounted) {
      return;
    }
    // setTimeout so that our custom font has time to load
    // before we tell the editor to remeasure
    setTimeout(() => editor.remeasureFonts(), 200);
  }, [isEditorMounted]);

  return {
    onEditorMount,
    realtimeManager: realtimeManager.current,
    monacoEditor
  };
};

export { useRealtimeManager };
