import React from 'react';
import styled from 'styled-components';

import { colors } from '../../../constants/colors';
import { Avatar } from '../../general/Avatar';

type HomeLeftSideBarTeamItemProps = {
  name: string;
  id: string | number;
  activeFolderId: string;
  avatarUrl?: string;
  navigateToTeamPage: () => void;
};

export const HomeLeftSideBarTeamItem = (
  props: HomeLeftSideBarTeamItemProps
) => {
  return (
    <styles.listItem onMouseDown={props.navigateToTeamPage}>
      <Avatar
        size={28}
        src={props.avatarUrl}
        name={props.name}
        boxShadow={colors.clear}
        cursor={'pointer'}
      />
    </styles.listItem>
  );
};

const styles = {
  listItem: styled.div`
    display: flex;
    padding: 14px 16px;
    border-radius: 6px;
    align-items: center;
    transition: opacity 0.16s;
    cursor: pointer;

    :hover {
      opacity: 0.7;
    }
  `
};
