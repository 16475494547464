import { useContext } from 'react';
import { FirebaseContext } from '../Firebase';
import analytics from '../Analytics/Analytics';
import {
  getMotionTemplate,
  createNewPlaygroundOrTemplate
} from '../utilities/PlaygroundCreationUtilities';
import { FileType } from '../declarations/FileType';
import { PlaygroundPermissions } from '../declarations/PlaygroundPermissions';
import { createTeam } from '../utilities/TeamCreationUtilites';
import { DRAFTS_FOLDER_ID } from '../constants/keys';

const useAuthentication = () => {
  const firebase = useContext(FirebaseContext);

  const signInWithEmailAndPassword = async (
    email: string,
    password: string
  ): Promise<{ success?: Boolean; error?: string } | undefined> => {
    if (!firebase) {
      return;
    }

    const auth = firebase.auth();

    try {
      const token = await auth.signInWithEmailAndPassword(email, password);

      const user = token.user;

      if (!user) {
        return;
      }

      if (token.additionalUserInfo?.isNewUser) {
        analytics.track('sign_up', {
          method: 'email'
        });
        setInitialUserData(user.uid, email);
      } else {
        analytics.track('login', { method: 'email' });
      }

      await auth.updateCurrentUser(token.user);

      return !!token.user
        ? { success: true }
        : { error: 'Something went wrong' };
    } catch (error) {
      analytics.track(
        'useAuthentication.signInWithEmailAndPassword.error',
        error
      );
      return { error: error.code };
    }
  };

  const createUserWithEmailAndPassword = async (
    email: string,
    password: string
  ): Promise<{ success?: Boolean; error?: string } | undefined> => {
    if (!firebase) {
      return;
    }

    const auth = firebase.auth();

    try {
      let token: firebase.auth.UserCredential;
      if (auth.currentUser) {
        var credential = firebase
          .app()
          .auth.EmailAuthProvider.credential(email, password);

        token = await auth.currentUser.linkWithCredential(credential);
      } else {
        token = await auth.createUserWithEmailAndPassword(email, password);
      }

      if (!token.user) {
        return { error: 'no user' };
      }

      if (!token.user.email) {
        token.user.updateEmail(email);
      }

      await setInitialUserData(token.user.uid, email);

      analytics.track('sign_up', {
        method: 'email'
      });

      await auth.updateCurrentUser(token.user);

      return !!token.user
        ? { success: true }
        : { error: 'Something went wrong' };
    } catch (error) {
      analytics.track(
        'useAuthentication.createUserWithEmailAndPassword.error',
        error
      );

      return { error: error.code };
    }
  };

  const signInWithGithub = async (): Promise<
    { success?: Boolean; error?: string } | undefined
  > => {
    if (!firebase) {
      return;
    }

    const auth = firebase.auth();
    const app = firebase.app();

    try {
      const githubProvider = new app.auth.GithubAuthProvider();

      const result = await auth.signInWithPopup(githubProvider);
      const credential = result.credential as firebase.auth.OAuthCredential | null;

      if (!credential) {
        return;
      }

      var user = result.user;
      if (!user) {
        return;
      }

      if (result.additionalUserInfo?.isNewUser) {
        analytics.track('sign_up', {
          method: 'github'
        });
        await setInitialUserData(user.uid, user.email || '');
      } else {
        analytics.track('login', { method: 'github' });
      }

      return !!user ? { success: true } : { error: 'Something went wrong' };
    } catch (error) {
      analytics.track('useAuthentication.signInWithGithub.error', error);
      return { error: error.code };

      // TODO: Handle this error
      // if (error.code === 'auth/account-exists-with-different-credential') {
      // User's email already exists.
      // var pendingCred = error.credential;
      // The provider account's email address.
      // var email = error.email;
      // Get sign-in methods for this email.
      // const methods = await auth.fetchSignInMethodsForEmail(email);
      // }
    }
  };

  const setInitialUserData = async (userId: string, email: string) => {
    if (!firebase) {
      return;
    }

    const firestore = firebase.firestore();
    const database = firebase.database();
    const functions = firebase.functions();

    const userFirestoreRef = firestore.doc(`users/${userId}`);
    await userFirestoreRef.set({
      name: '',
      email
    });

    const newTeamRef = firestore.collection('teams').doc();
    const personalTeamId = await createTeam({
      newTeamRef,
      userId,
      isPersonal: true,
      timestamp: firebase.app().firestore.Timestamp.now()
    });

    if (!personalTeamId) {
      return;
    }
    const playgroundsRef = firestore.collection('playgrounds');
    const playgroundRealtimeRef = database.ref().child('playgrounds');

    const motionTemplate = getMotionTemplate(
      firebase.app().database.ServerValue.TIMESTAMP
    );
    const motionTemplateFiles = await Promise.all(
      motionTemplate.map(async file => {
        const { history, name } = file;

        return {
          name,
          data: { history }
        };
      })
    );

    const firestoreTimestamp = firebase.app().firestore.Timestamp.now();

    await Promise.all([
      functions.onCreateUser({ email }),
      createNewPlaygroundOrTemplate({
        playgroundCollectionRef: playgroundsRef,
        playgroundRealtimeRef,
        template: {
          info: {
            folderId: DRAFTS_FOLDER_ID,
            teamId: personalTeamId,
            name: 'Motion',
            type: FileType.Template,
            defaultPermissions: PlaygroundPermissions.View,
            dependencies: { 'framer-motion': 'latest' },
            isPersonal: true,
            members: { [userId]: { permissions: PlaygroundPermissions.Owner } }
          },
          files: motionTemplateFiles
        },
        timestamp: firestoreTimestamp
      })
    ]);
  };

  return {
    signInWithEmailAndPassword,
    signInWithGithub,
    createUserWithEmailAndPassword
  };
};

export { useAuthentication };
