import React from 'react';
import styled from 'styled-components';
import { colors } from '../../constants/colors';

import { useToggle } from '../../hooks/useToggle';

import { Text } from './Text';
import { DropdownIcon } from '../../icons/DropdownIcon';
import { Menu } from './Menu';
import {
  PlaygroundPermissions,
  FrontEndPlaygroundPermissions
} from '../../declarations/PlaygroundPermissions';
import {
  TeamPermissions,
  FrontEndTeamPermissions
} from '../../declarations/TeamPermissions';

type PermissionsDropdownProps = {
  permissionsTitles:
    | Record<PlaygroundPermissions, FrontEndPlaygroundPermissions>
    | Record<TeamPermissions, FrontEndTeamPermissions>;
  actions: Array<{
    title: FrontEndPlaygroundPermissions | FrontEndTeamPermissions;
    onClick: () => void;
  }>;
  role: PlaygroundPermissions | TeamPermissions;
  isCurrentUser?: boolean;
  type: 'user' | 'link' | 'folder' | 'team';
  menuPosition?: {
    top?: number;
    left?: number;
    x?: number;
    y?: number;
  };
};

export const PermissionsDropdown = (props: PermissionsDropdownProps) => {
  const {
    value: isMenuVisible,
    setFalse: hideMenu,
    toggle: toggleIsMenuVisible
  } = useToggle(false);

  if (
    props.type === 'link' ||
    props.isCurrentUser ||
    props.actions.length === 0 ||
    props.role === PlaygroundPermissions.Owner
  ) {
    return (
      <Text color={'secondaryText'}>{props.permissionsTitles[props.role]}</Text>
    );
  }

  const hasActions = props.actions.length > 0;

  return (
    <StyledContainer>
      <Text
        cursor={'pointer'}
        color={'secondaryText'}
        onMouseDown={toggleIsMenuVisible}
      >
        {props.permissionsTitles[props.role]}
        {hasActions && (
          <StyledIconContainer isMenuVisible={isMenuVisible}>
            <DropdownIcon color={colors.secondaryText} />
          </StyledIconContainer>
        )}
      </Text>
      {hasActions && isMenuVisible && (
        <Menu
          showCheckFor={props.permissionsTitles[props.role]}
          top={props.menuPosition?.top}
          left={props.menuPosition?.left}
          x={props.menuPosition?.x}
          y={props.menuPosition?.y}
          onHide={hideMenu}
          actions={props.actions}
        />
      )}
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  box-sizing: border-box;
  height: 30px;
  padding-top: 4px;
`;

type StyledIconContainerProps = {
  isMenuVisible: boolean;
};

const StyledIconContainer = styled.span<StyledIconContainerProps>`
  display: inline-block;
  transition: transform 0.12s;
  transform: ${props =>
    props.isMenuVisible ? 'translateY(4px)' : 'translateY(2px)'};

  ${StyledContainer}:hover & {
    transform: translateY(4px);
  }
`;
