import React from 'react';
import { colors } from '../constants/colors';
import { IconProps } from './IconProps';

export const TemplateIcon = ({ margin, color, size }: IconProps) => (
  <svg
    style={{ margin }}
    width={size || 32}
    height={size || 32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.79297 19.6895V13.0488C8.79297 10.1582 10.5117 8.44922 13.4316 8.44922H19.9453C18.5879 5.70508 15.7461 3.82031 12.4746 3.82031C7.85547 3.82031 4.10547 7.58008 4.10547 12.1895C4.10547 15.4805 6.01953 18.332 8.79297 19.6895ZM13.4316 26.6523H23.9199C26.0391 26.6523 27.1426 25.5684 27.1426 23.4785V13.0586C27.1426 10.9688 26.0391 9.875 23.9199 9.875H13.4316C11.3223 9.875 10.2188 10.9688 10.2188 13.0586V23.4688C10.2188 25.5684 11.3223 26.6523 13.4316 26.6523Z"
      fill={color || colors.primaryText}
    />
  </svg>
);
