import { editor } from 'monaco-editor';
import { UserPresenceInfo } from '../hooks/usePresence';

const WIDGET_ACTIVITY_TIMEOUT = 2000;

export const handleCursorWidgets = ({
  activeCursors,
  playgroundPresences,
  widgets,
  widgetTimeouts,
  editor: monacoEditor,
  currentUserId
}: {
  activeCursors: Array<{ userId: string; position?: number }>;
  playgroundPresences: Array<UserPresenceInfo>;
  widgets: Record<string, editor.IContentWidget | undefined>;
  widgetTimeouts: Record<string, number | undefined>;
  currentUserId: string;
  editor: Pick<
    editor.IStandaloneCodeEditor,
    | 'getModel'
    | 'addContentWidget'
    | 'layoutContentWidget'
    | 'removeContentWidget'
  >;
}) => {
  const activeModel = monacoEditor.getModel();

  if (!activeModel) {
    return;
  }

  activeCursors.forEach(cursor => {
    if (cursor.userId === currentUserId) {
      return;
    }

    const currentTimeout = widgetTimeouts[cursor.userId];
    if (currentTimeout) {
      clearTimeout(currentTimeout);
    }

    let currentWidget = widgets[cursor.userId];
    if (!currentWidget) {
      const member = playgroundPresences.find(
        member => member.userId === cursor.userId
      );

      const name = member?.name || member?.email;

      if (!name) {
        return;
      }

      currentWidget = {
        getId: function() {
          return cursor.userId;
        },
        getDomNode: function() {
          const node = document.createElement('div');
          node.innerHTML = name;
          node.style.background = member?.color || '';
          node.style.padding = '2px 8px';
          node.style.borderRadius = '20px';
          node.style.color = 'white';
          node.style.fontWeight = '600';
          node.style.fontSize = '13px';
          node.style.lineHeight = '18px';
          node.style.whiteSpace = 'nowrap';
          node.style.boxShadow = '0px 4px 12px rgba(0,0,0,0.4)';

          return node;
        },
        getPosition: function() {
          return {
            position: cursor.position
              ? activeModel.getPositionAt(cursor.position)
              : null,
            preference: [editor.ContentWidgetPositionPreference.ABOVE]
          };
        }
      };
      monacoEditor.addContentWidget(currentWidget);
      widgets[cursor.userId] = currentWidget;
    } else {
      currentWidget.getPosition = function() {
        return {
          position: cursor.position
            ? activeModel.getPositionAt(cursor.position)
            : null,
          preference: [editor.ContentWidgetPositionPreference.ABOVE]
        };
      };
    }

    monacoEditor.layoutContentWidget(currentWidget);

    widgetTimeouts[cursor.userId] = setTimeout(() => {
      if (widgets[cursor.userId]) {
        if (currentWidget) {
          monacoEditor.removeContentWidget(currentWidget);
        }
        const { [cursor.userId]: old, ...remaining } = widgetTimeouts;
        widgetTimeouts = remaining;
        widgets[cursor.userId] = undefined;
      }
    }, WIDGET_ACTIVITY_TIMEOUT);
  });
};

export const cursorDidChangePosition = (
  prevPosition?: { position?: number; selectionEnd?: number },
  newPosition?: { position?: number; selectionEnd?: number }
) => {
  return (
    prevPosition?.position !== newPosition?.position ||
    prevPosition?.selectionEnd !== newPosition?.selectionEnd
  );
};
