import React, { useRef } from 'react';
import { colors } from '../../constants/colors';
import styled from 'styled-components';

import { CheckIcon } from '../../icons/CheckIcon';
import {
  useKeyboardShortcut,
  KeyboardShortcutKey
} from '../../hooks/useKeyboardShortcut';
import { useOnClickOutside } from '../../hooks/useOnClickOutside';

type MenuProps<T> = {
  id?: T;
  actions: {
    title: string;
    onClick?: (id?: T) => void;
    isDestructive?: boolean;
  }[];
  top?: number;
  left?: number;
  x?: number;
  y?: number;
  showCheckFor?: string;
  onHide: () => void;
};

type StyledContainerProps = {
  top?: number;
  right?: number;
  bottom?: number;
  left?: number;
  x?: number;
  y?: number;
};

type MenuItemProps = {
  isDestructive?: boolean;
};

export const Menu: <T>(props: MenuProps<T>) => JSX.Element = props => {
  const menuRef = useRef<HTMLDivElement | null>(null);

  const isValid = (sideVal: number | undefined, windowVal: number) =>
    sideVal && windowVal - sideVal > windowVal / 2;

  const handleAction = (handler?: (id?: typeof props.id) => void) => {
    handler && handler(props.id);
    props.onHide();
  };

  useKeyboardShortcut(KeyboardShortcutKey.Escape, props.onHide);
  useOnClickOutside(menuRef, props.onHide);

  return (
    <styles.container
      ref={menuRef}
      top={isValid(props.top, window.innerHeight) ? props.top : undefined}
      bottom={
        !isValid(props.top, window.innerHeight)
          ? props.top && window.innerHeight - props.top
          : undefined
      }
      left={isValid(props.left, window.innerWidth) ? props.left : undefined}
      right={
        !isValid(props.left, window.innerWidth)
          ? props.left && window.innerWidth - props.left
          : undefined
      }
      x={props.x}
      y={props.y}
    >
      {props.actions.map((action, index) =>
        action.title === 'divider' ? (
          <styles.divider key={`${action}${index}`} />
        ) : (
          <styles.menuItem
            key={`${action}${index}`}
            isDestructive={action.isDestructive}
            onClick={() => handleAction(action.onClick)}
            onMouseUp={() => handleAction(action.onClick)}
          >
            {props.showCheckFor && (
              <styles.iconContainer>
                <CheckIcon
                  color={
                    props.showCheckFor === action.title
                      ? colors.primaryText
                      : colors.clear
                  }
                />
              </styles.iconContainer>
            )}
            {action.title}
          </styles.menuItem>
        )
      )}
    </styles.container>
  );
};

const styles = {
  container: styled.div<StyledContainerProps>`
    padding: 8px 0px;
    background: ${colors.base};
    box-shadow: inset 0px 0px 0px 1px ${colors.W12},
      0px 8px 24px ${colors.dropShadow};
    border-radius: 8px;
    display: inline-block;
    position: absolute;
    z-index: 200;
    min-width: 80px;
    top: ${props => (props.top ? `${props.top}px` : undefined)};
    left: ${props => (props.left ? `${props.left}px` : undefined)};
    bottom: ${props => (props.bottom ? `${props.bottom}px` : undefined)};
    right: ${props => (props.right ? `${props.right}px` : undefined)};
    transform: ${props =>
      props.x || props.y
        ? `translate(${props.x || 0}px, ${props.y || 0}px)`
        : undefined};
  `,
  menuItem: styled.div<MenuItemProps>`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 6px 20px;
    color: ${props =>
      props.isDestructive ? colors.strawberry : colors.primaryText};
    font-size: 13px;
    line-height: 18px;
    font-weight: 500;
    white-space: nowrap;
    user-select: none;
    cursor: pointer;

    :hover {
      background: ${props =>
        props.isDestructive ? colors.strawberry : colors.blueberry};
      color: ${colors.white};
    }
    :hover:active {
      background: ${props =>
        props.isDestructive ? colors.strawberryHover : colors.blueberryHover};
    }
  `,
  divider: styled.div`
    margin: 8px 1px;
    height: 1px;
    background: ${colors.W12};
  `,
  iconContainer: styled.div`
    margin-left: -8px;
    margin-right: 8px;
    display: flex;
    align-items: center;
  `
};
