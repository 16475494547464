import React, {
  useContext,
  useEffect,
  useState,
  useCallback,
  useMemo
} from 'react';
import { FilesystemContext } from './Filesystem';
import { editor, Uri } from 'monaco-editor';

import styled from 'styled-components';

import Editor from './Editor';
import {
  useKeyboardShortcut,
  KeyboardShortcutKey
} from './hooks/useKeyboardShortcut';

import analytics from './Analytics/Analytics';

import { usePlaygroundManager } from './hooks/usePlaygroundManager';
import { useRealtimeChanges } from './hooks/useRealtimeChanges';
import { useToggle } from './hooks/useToggle';

import { PlaygroundInterviewModal } from './components/playground/PlaygroundInterviewModal';
import { BetaFeedback } from './components/general/BetaFeedback';
import { PlaygroundHeader } from './components/playground/playground-header/PlaygroundHeader';
import PlaygroundLeftSideBar from './components/playground/playground-left-sidebar/PlaygroundLeftSideBar';
import { PlaygroundFileSwitcher } from './components/playground/PlaygroundFileSwitcher';
import { PlaygroundSignUpBar } from './components/playground/PlaygroundSignUpBar';
import { PlaygroundLoadingScreen } from './components/playground/PlaygroundLoadingScreen';
import { SignUpModal } from './marketing/components/sign-up/SignUpModal';
import { colors } from './constants/colors';
import { hasPermissionToEdit } from './utilities/PlaygroundPermissionsUtilities';
import { createModel } from './utilities/EditorUtilities';
import { FirestoreProjectFile } from './declarations/FirestoreProjectFile';
import { debounce } from 'lodash';
import useInterviewUtilities from './hooks/useInterviewUtilites';
import { PlaygroundInfo } from './declarations/PlaygroundInfo';
import { useUpdateUser } from './hooks/useUpdateUser';
import useAutoJoinPlayground from './hooks/useAutoJoinPlayground';
import { useModifiedManager } from './hooks/useModifiedManager';
import useDeleteFile from './hooks/useDeleteFile';
import { device } from './constants/deviceBreakpoints';
import { PlaygroundRefreshButton } from './components/playground/PlaygroundRefreshButton';

import UpgradeProTeamModal from './components/upgrade-modals/UpgradeProTeamModal';
import { PlaygroundPermissions } from './declarations/PlaygroundPermissions';
import { PREVIEW_URL } from './constants/urls';
import { IDependencies } from './declarations/Preview';
import { usePresence } from './hooks/usePresence';
import { useCursorWidgets } from './hooks/useCursorWidgets';
import { useRealtimeManager } from './hooks/useRealtimeManager';

type PlaygroundProps = {
  playgroundId: string;
  user: firebase.User;
};

const shouldShowInterviewSignUpModal = (
  isInterview: boolean,
  members: PlaygroundInfo['members'],
  currentUser: firebase.User
) => {
  if (!isInterview) {
    return false;
  }

  return (
    currentUser.isAnonymous &&
    !members.find(member => member.id === currentUser.uid)
  );
};

const Playground: React.FC<PlaygroundProps> = ({ playgroundId, user }) => {
  const filesystem = useContext(FilesystemContext);

  useKeyboardShortcut(
    [KeyboardShortcutKey.Meta, KeyboardShortcutKey.p],
    event => {
      event?.preventDefault();
      showFileSwitcher();
    }
  );

  const {
    activeEditingFile,
    createFile,
    dependencies,
    files,
    team,
    playgroundInfo,
    setActiveEditingFile,
    updateDependencies,
    updateFileName,
    updateMemberAccess,
    updatePlaygroundName
  } = usePlaygroundManager(playgroundId, user.uid);

  const deleteFile = useDeleteFile(playgroundId, files);

  const updatePlaygroundLastModified = useModifiedManager(playgroundId);

  useAutoJoinPlayground({
    currentUser: user,
    playgroundMembers: playgroundInfo?.members || [],
    teamMembers: team?.members || [],
    defaultPermissions: playgroundInfo?.defaultPermissions,
    updateMemberAccess: updateMemberAccess
  });

  const { endInterview } = useInterviewUtilities(playgroundId);
  const { updateUserName } = useUpdateUser(user);

  const { onEditorMount, realtimeManager, monacoEditor } = useRealtimeManager(
    playgroundId,
    user.uid,
    activeEditingFile?.realtimeDatabaseRef?.key || ''
  );

  const isInterviewModalVisible = useMemo(
    () =>
      shouldShowInterviewSignUpModal(
        !!playgroundInfo?.isInterview,
        playgroundInfo?.members || [],
        user
      ),
    [playgroundInfo, user]
  );

  const {
    value: isSideBarVisible,
    setTrue: showSideBar,
    setFalse: hideSideBar
  } = useToggle(false);
  const {
    value: isFileSwitcherVisible,
    setTrue: showFileSwitcher,
    setFalse: hideFileSwitcher
  } = useToggle(false);
  const {
    value: isSignUpModalVisible,
    setTrue: showSignUpModal,
    setFalse: hideSignUpModal
  } = useToggle(false);
  const {
    value: isUpgradeModalVisible,
    setTrue: showUpgradeModal,
    setFalse: hideUpgradeModal
  } = useToggle(false);

  const {
    value: isCodeActiveMobileView,
    toggle: toggleActiveMobileView
  } = useToggle(false);

  const [isEditingDependencies, setIsEditingDependencies] = useState(false);

  useEffect(() => {
    if (!filesystem) {
      return;
    }

    filesystem.setActivePlaygroundId(playgroundId);
  }, [filesystem, playgroundId]);

  const playgroundPresences = usePresence(realtimeManager);
  useRealtimeChanges(realtimeManager);
  useCursorWidgets(
    playgroundPresences,
    realtimeManager,
    monacoEditor.current,
    user.uid
  );

  const setPreviewManager = useCallback(
    (instance: HTMLIFrameElement | null) => {
      if (!instance || !filesystem) {
        return;
      }

      filesystem.setManager(instance);
    },
    [filesystem]
  );

  const setFile = filesystem?.setFile;

  const updateDependenciesDebounced = useCallback(
    debounce(updateDependencies, 2000, { leading: false, trailing: true }),
    [updateDependencies]
  );

  const onActiveFileDidChange = useCallback(
    (activeFile: { name: string; text: string }) => {
      if (!setFile) {
        return;
      }

      if (isEditingDependencies) {
        const editorText = monacoEditor.current?.getValue();

        if (!editorText) {
          return;
        }

        try {
          const newDeps = JSON.parse(editorText) as IDependencies;

          updateDependenciesDebounced(newDeps);
        } catch (e) {
          // TODO: Show error message
        }
        return;
      }

      setFile(`/src/${activeFile.name}`, activeFile.text);
    },
    [setFile, isEditingDependencies, monacoEditor, updateDependenciesDebounced]
  );

  if (!activeEditingFile || !filesystem || !playgroundInfo) {
    return <PlaygroundLoadingScreen />;
  }

  const onChangeFile = (
    fileId: string,
    isDependencies: boolean = false,
    newActive?: FirestoreProjectFile,
    newModel?: editor.ITextModel
  ) => {
    if (!monacoEditor.current) {
      return;
    }

    if (fileId === activeEditingFile.id) {
      return;
    }

    setIsEditingDependencies(isDependencies);

    if (isDependencies) {
      setActiveEditingFile({
        name: 'dependencies',
        id: 'dependencies',
        createdBy: user.uid
      });

      let dependenciesModel = editor.getModel(
        Uri.parse('file:///dependencies.json')
      );

      if (!dependenciesModel) {
        dependenciesModel = editor.createModel(
          JSON.stringify(dependencies, null, '\t'),
          'json',
          Uri.parse('file:///dependencies.json')
        );
      }

      monacoEditor.current.setModel(dependenciesModel);
      realtimeManager?.setActiveFile('dependencies');

      return;
    }

    const active = newActive || files.find(file => file.id === fileId);
    const model =
      newModel || editor.getModel(Uri.parse(`file:///${active?.name}`));

    if (!active || !model) {
      return;
    }

    if (active.realtimeDatabaseRef?.key) {
      monacoEditor.current.setModel(model);
      realtimeManager?.setActiveFile(active.realtimeDatabaseRef.key);
      filesystem.setFile(`/src/${active.name}`, model.getValue());
    }

    setActiveEditingFile(active);

    if (active.name.match(/^.*\.(tsx|jsx)$/i)) {
      filesystem.setActiveFilePath(active.name);
    }
  };

  const onAddFile = async (name?: string) => {
    analytics.track('playground.onAddFile');

    const fileName = name || `untitled-${files.length + 1}.jsx`;
    const newFile = await createFile(fileName);

    if (newFile && newFile.realtimeDatabaseRef) {
      onChangeFile(newFile.id, false, newFile);
    }
  };

  const onDeleteFile = async (fileId: string) => {
    // get first file that isnt one we're deleting
    const newActive = files.find(file => file.id !== fileId);
    if (!newActive) {
      return;
    }
    await deleteFile(fileId);

    onChangeFile(newActive.id);
  };

  const updateActiveFileName = async (name: string) => {
    const previousName = activeEditingFile.name;

    // make sure theres not already a model with this name
    const existing = editor.getModel(Uri.parse(`file:///${name}`));

    if (existing) {
      return;
    }

    const currentModel = monacoEditor.current?.getModel();
    const newModel = createModel(name, currentModel?.getValue());

    const fileNameUpdatedSuccessfully = await updateFileName(
      activeEditingFile.id,
      name
    );

    if (!fileNameUpdatedSuccessfully) {
      return;
    }

    filesystem.setFile(`/src/${name}`, newModel?.getValue() || '');

    const active = files.find(file => file.name === previousName);

    if (!active || !newModel) {
      return;
    }

    active.name = name;
    onChangeFile(name, false, active, newModel);

    currentModel?.dispose();
  };

  const handleUpdateMemberAccess = async (
    id: string,
    newPermissions: PlaygroundPermissions
  ) => {
    const result = await updateMemberAccess(id, newPermissions);

    if (result?.error) {
      showUpgradeModal();
    }
  };

  const onJoinInterview = async (name: string) => {
    await updateUserName(name);
    return Promise.all([
      updatePlaygroundName(`${name} (${playgroundInfo.name})`),
      updateMemberAccess(user.uid, playgroundInfo.defaultPermissions)
    ]);
  };

  const onEndInterview = () => {
    endInterview(playgroundInfo.members);
  };

  const currentUser = playgroundInfo.members.find(
    member => member.id === user.uid
  );
  const currentUserPermissions = currentUser?.permissions;

  const playgroundIsEditable =
    (!user.isAnonymous || !!playgroundInfo.isInterview) &&
    hasPermissionToEdit(currentUserPermissions);

  return (
    <>
      <PlaygroundHeader
        currentUser={user}
        fileName={activeEditingFile.name}
        activeUsers={playgroundPresences}
        viewOnly={!playgroundIsEditable}
        updateActiveFileName={updateActiveFileName}
        updateActivePlaygroundName={updatePlaygroundName}
        updateMemberAccess={handleUpdateMemberAccess}
        showSideBar={showSideBar}
        handleEndInterview={onEndInterview}
        playgroundInfo={playgroundInfo}
        teamMembers={team?.members}
        isCodeActiveMobileView={isCodeActiveMobileView}
        toggleActiveMobileView={toggleActiveMobileView}
        files={files}
        onChangeFile={onChangeFile}
        activeEditingFile={activeEditingFile}
      />
      <Editor
        isSideBarVisible={isSideBarVisible}
        activeFile={activeEditingFile}
        editingEnabled={playgroundIsEditable}
        onChange={onActiveFileDidChange}
        onEditorMount={onEditorMount}
        onUpdateLastModified={updatePlaygroundLastModified}
        showFileSwitcher={showFileSwitcher}
      />
      <styles.previewIframe
        title={playgroundId}
        ref={setPreviewManager}
        src={`${PREVIEW_URL}${playgroundId}`}
        sandbox="allow-forms allow-scripts allow-same-origin allow-modals allow-popups allow-presentation"
        frameBorder={0}
        marginHeight={0}
        id="preview"
        isCodeActiveMobileView={isCodeActiveMobileView}
      />
      <PlaygroundRefreshButton
        onRefreshPlayground={filesystem.refreshPreview}
      />
      <PlaygroundLeftSideBar
        files={files}
        onChangeFile={onChangeFile}
        activeEditingFile={activeEditingFile}
        users={playgroundPresences}
        onAddFile={onAddFile}
        visible={isSideBarVisible}
        viewOnly={!playgroundIsEditable}
        hideSideBar={hideSideBar}
        onDeleteFile={onDeleteFile}
      />

      <styles.sideBarTrigger onMouseEnter={showSideBar} />
      {user.isAnonymous && !playgroundInfo.isInterview && (
        <PlaygroundSignUpBar
          isCodeActiveMobileView={isCodeActiveMobileView}
          onSignUp={showSignUpModal}
        />
      )}
      {isSignUpModalVisible && (
        <SignUpModal onClose={hideSignUpModal} stayOnPage />
      )}
      {isFileSwitcherVisible && (
        <PlaygroundFileSwitcher
          files={files}
          onChangeFile={onChangeFile}
          activeEditingFile={activeEditingFile}
          hideSelf={hideFileSwitcher}
          viewOnly={!playgroundIsEditable}
          onAddFile={onAddFile}
        />
      )}
      {isInterviewModalVisible && (
        <PlaygroundInterviewModal handleJoinInterview={onJoinInterview} />
      )}
      <UpgradeProTeamModal
        onHide={hideUpgradeModal}
        visible={isUpgradeModalVisible}
      />
      <BetaFeedback currentUser={currentUser} />
    </>
  );
};

const styles = {
  sideBarTrigger: styled.div`
    position: absolute;
    top: 52px;
    left: 0px;
    height: calc(100vh - 52px);
    width: 56px;
    z-index: 20;

    ${device.mobile} {
      display: none;
    }
  `,
  previewIframe: styled.iframe<{ isCodeActiveMobileView: boolean }>`
    padding: 0px;
    position: absolute;
    top: 52px;
    left: 50%;
    width: 50%;
    height: calc(100% - 52px);
    overflow: hidden;
    background: ${colors.baseLight};
    z-index: 20;
    border-radius: 6px 0px 0px 0px;

    ${device.mobile} {
      width: 100%;
      left: 0px;
      border-radius: 0px;
      background: ${colors.base};
      display: ${props => (props.isCodeActiveMobileView ? 'none' : 'block')};
    }
  `
};

export default Playground;
