import React from 'react';
import styled, { keyframes } from 'styled-components';

import { colors } from '../../constants/colors';
import { Button } from '../general/Button';
import { Text } from '../general/Text';

type ToastProps = {
  text: string;
  action?: { text: string; handler: () => void };
  isDestructive?: boolean;
  hideSelf: () => void;
};

const TOAST_DURATION = 3000; // 3 sec

export const Toast = (props: ToastProps) => {
  setTimeout(() => {
    props.hideSelf();
  }, TOAST_DURATION);

  return (
    <styles.container>
      <styles.bar isDestructive={props.isDestructive}>
        <Text color={'white'} fontWeight={500} margin={'0px 16px 0px 0px'}>
          {props.text}
        </Text>
        {props.action && (
          <styles.SignUpButton primary onClick={props.action.handler}>
            {props.action.text}
          </styles.SignUpButton>
        )}
      </styles.bar>
    </styles.container>
  );
};

const toastAnimation = keyframes`
  0% {
    transform: translateY(72px);
  }

  10% {
    transform: translateY(0px);
  }

  90% {
    opacity: 1;
    transform: scale(1);
  }

  100% {
    opacity: 0;
    transform: scale(0.9);
  }
`;

const styles = {
  bar: styled.div<Partial<ToastProps>>`
    display: flex;
    background: ${props =>
      props.isDestructive ? colors.strawberry : colors.blueberry};
    padding: 8px 8px 8px 20px;
    border-radius: 100px;
    align-items: center;
    justify-self: center;
    flex-shrink: 0;
    animation: ${toastAnimation} 3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  `,
  SignUpButton: styled(Button)`
    background: rgba(255, 255, 255, 0.2);
    pointer-events: auto;

    :hover {
      background: rgba(255, 255, 255, 0.28);
    }

    :hover:active {
      background: rgba(255, 255, 255, 0.2);
    }
  `,
  container: styled.div`
    position: fixed;
    left: 0px;
    bottom: 24px;
    display: flex;
    justify-content: center;
    z-index: 40;
    width: 100vw;
    user-select: none;
    pointer-events: none;
  `
};
