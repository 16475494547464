import React from 'react';
import styled from 'styled-components';

import { colors } from '../../../constants/colors';
import { Avatar } from '../../general/Avatar';
import { Text } from '../../general/Text';
import { LinkIcon } from '../../../icons/LinkIcon';
import { PermissionsDropdown } from '../../general/PermissionsDropdown';
import {
  PlaygroundPermissions,
  PLAYGROUND_PERMISSIONS_TITLES
} from '../../../declarations/PlaygroundPermissions';

type PlaygroundShareSheetItemProps = {
  text: string;
  memberId?: string;
  dropdownPermissions: Array<PlaygroundPermissions>;
  role: PlaygroundPermissions;
  isCurrentUser?: boolean;
  type: 'user' | 'link' | 'folder' | 'team';
  avatar?: string;
  onUpdateMemberAccess?: (
    memberId: string,
    newPermissions: PlaygroundPermissions
  ) => Promise<void>;
};

export const PlaygroundShareSheetItem = (
  props: PlaygroundShareSheetItemProps
) => {
  const handleUpdateMemberAccess = (newPermissions: PlaygroundPermissions) => {
    if (!props.memberId || !props.onUpdateMemberAccess) {
      return;
    }

    props.onUpdateMemberAccess(props.memberId, newPermissions);
  };

  return (
    <styles.sheetItem>
      <styles.sheetItemLeading>
        {props.type === 'link' && (
          <styles.avatarContainer>
            <LinkIcon />
          </styles.avatarContainer>
        )}
        {props.type === 'user' && (
          <Avatar name={props.text} src={props.avatar} />
        )}
        <Text
          margin={'0px 0px 0px 12px'}
          ellipsis
          userSelect={'text'}
          cursor={'text'}
        >
          {props.text}
        </Text>
        {props.isCurrentUser && (
          <Text margin={'0px 0px 0px 6px'} color={'inactive'}>
            (You)
          </Text>
        )}
      </styles.sheetItemLeading>
      <PermissionsDropdown
        isCurrentUser={props.isCurrentUser}
        permissionsTitles={PLAYGROUND_PERMISSIONS_TITLES}
        actions={props.dropdownPermissions.map(permission => ({
          title: PLAYGROUND_PERMISSIONS_TITLES[permission],
          onClick: () => handleUpdateMemberAccess(permission)
        }))}
        role={props.role}
        type={props.type}
        menuPosition={{
          left: 228,
          y: 8
        }}
      />
    </styles.sheetItem>
  );
};

const styles = {
  sheetItem: styled.div`
    display: flex;
    padding: 8px 24px;
    align-items: center;
    justify-content: space-between;
  `,
  sheetItemLeading: styled.div`
    display: flex;
    align-items: center;
  `,
  avatarContainer: styled.div`
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background: ${colors.W8};
    display: flex;
    justify-content: center;
    align-items: center;
  `
};
