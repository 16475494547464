import { useContext, useCallback } from 'react';

import { FirebaseContext } from '../Firebase';

import { createTeam } from '../utilities/TeamCreationUtilites';

const useTeamCreationUtilities = (currentUserId: string) => {
  const firebase = useContext(FirebaseContext);

  const createNewTeam = useCallback(
    async ({
      isPersonal,
      teamName
    }: {
      isPersonal?: boolean;
      teamName?: string;
    }) => {
      if (!firebase || (isPersonal && !teamName)) {
        return;
      }

      const firestore = firebase.firestore();

      const newTeamRef = firestore.collection('teams').doc();

      await createTeam({
        newTeamRef,
        isPersonal,
        teamName,
        userId: currentUserId,
        timestamp: firebase.app().firestore.Timestamp.now()
      });

      return newTeamRef.id;
    },
    [firebase, currentUserId]
  );

  return { createNewTeam };
};

export default useTeamCreationUtilities;
