import React, { useEffect } from 'react';
import { colors } from '../../../constants/colors';
import styled from 'styled-components';

import { Folder } from '../../../declarations/Folder';
import { useHistory } from 'react-router-dom';
import { Text } from '../../general/Text';
import { Avatar } from '../../general/Avatar';
import { RECENTS_FOLDER_ID } from '../../../constants/keys';
import { DRAFTS_FOLDER_ID } from '../../../constants/keys';
import { INTERVIEWS_FOLDER_ID } from '../../../constants/keys';
import { AppUser } from '../../../declarations/AppUser';
import { HomeLeftSideBarItem } from './HomeLeftSideBarItem';
import { HomeLeftSideBarTeamItem } from './HomeLeftSideBarTeamItem';
import { HomeLeftSideBarTeamSwitcher } from './HomeLeftSideBarTeamSwitcher';
import { FirstTimeTooltip } from '../../general/FirstTimeTooltip';
import { useFirstTimeExperience } from '../../../hooks/useFirstTimeExperience';

import { useToggle } from '../../../hooks/useToggle';
import { Team } from '../../../declarations/Team';
import useDeleteFolder from '../../../hooks/useDeleteFolder';
import { NEW_TEAM_FTE } from '../../../constants/keys';

type HomeLeftSidebarProps = {
  teams: Array<Team>;
  activeTeam: Team;
  currentUserData?: AppUser;
  currentUserHasEditPermissions: boolean;
  folders: Array<Folder>;
  activeFolderId: string;
  isViewingSettings: boolean;
  showHomeNewFolderModal: () => void;
  showHomeNewTeamModal: () => void;
};

export const HomeLeftSideBar = (props: HomeLeftSidebarProps) => {
  const history = useHistory();

  const deleteFolder = useDeleteFolder(props.activeTeam.id);

  const {
    value: isTeamSwitcherVisible,
    setTrue: showTeamSwitcher,
    setFalse: hideTeamSwitcher
  } = useToggle(false);

  const navigateToFolder = (folderId: string) =>
    history.push(`/${props.activeTeam.id}/${folderId}`);

  const navigateToRecents = () =>
    history.push(`/${props.activeTeam.id}/${RECENTS_FOLDER_ID}`);

  const navigateToSettings = () => {
    history.push('/settings');
  };

  const navigateToTeamPage = () => {
    history.push(`/${props.activeTeam.id}/settings`);
  };

  const [isTeamsTooltipVisible, hideTeamsTooltip] = useFirstTimeExperience(
    NEW_TEAM_FTE
  );

  useEffect(() => {
    if (isTeamSwitcherVisible) {
      hideTeamsTooltip();
    }
  }, [isTeamSwitcherVisible, hideTeamsTooltip]);

  return (
    <styles.container>
      <styles.folderContainer>
        {!props.activeTeam.attributes?.isPersonal && (
          <HomeLeftSideBarTeamItem
            name={props.activeTeam.name}
            avatarUrl={props.activeTeam?.avatarUrl}
            id={props.activeTeam.id}
            activeFolderId={props.activeFolderId}
            navigateToTeamPage={navigateToTeamPage}
          />
        )}
        {props.folders
          .sort((left, right) => {
            if (left.id === RECENTS_FOLDER_ID) {
              return -1;
            } else if (right.id === RECENTS_FOLDER_ID) {
              return 1;
            } else if (left.attributes?.isDrafts) {
              return -1;
            } else if (right.attributes?.isDrafts) {
              return 1;
            } else {
              return 0;
            }
          })
          .map((folder, index) => (
            <HomeLeftSideBarItem
              isDrafts={folder.id === DRAFTS_FOLDER_ID}
              isRecents={folder.id === RECENTS_FOLDER_ID}
              isInterviews={folder.id === INTERVIEWS_FOLDER_ID}
              navigateToRecents={navigateToRecents}
              onChangeFolder={navigateToFolder}
              onDeleteFolder={deleteFolder}
              hasEditPermissions={props.currentUserHasEditPermissions}
              key={`${folder}${index}`}
              id={folder.id}
              activeFolderId={props.activeFolderId}
              name={folder.name}
            />
          ))}
        {props.currentUserHasEditPermissions && (
          <styles.newFolder
            color="secondaryText"
            onClick={props.showHomeNewFolderModal}
          >
            New folder
          </styles.newFolder>
        )}
      </styles.folderContainer>
      <styles.profile
        onMouseDown={showTeamSwitcher}
        isTeamSwitcherVisible={isTeamSwitcherVisible}
      >
        <Avatar
          name={
            props.currentUserData?.name || props.currentUserData?.email || ''
          }
          src={props.currentUserData?.avatarUrl}
          margin={'0px 16px 0px 0px'}
          boxShadow={colors.clear}
          cursor={'pointer'}
        />

        <styles.nameWrapper>
          <Text ellipsis cursor={'pointer'}>
            {props.currentUserData?.name}
          </Text>
          <Text
            ellipsis
            size={'bodySmall'}
            color={'secondaryText'}
            cursor={'pointer'}
          >
            {props.activeTeam.name}
          </Text>
        </styles.nameWrapper>
      </styles.profile>
      {isTeamSwitcherVisible && (
        <HomeLeftSideBarTeamSwitcher
          teams={props.teams}
          hideSelf={hideTeamSwitcher}
          activeTeamId={props.activeTeam.id}
          navigateToSettings={navigateToSettings}
          showHomeNewTeamModal={props.showHomeNewTeamModal}
        />
      )}
      {isTeamsTooltipVisible && (
        <FirstTimeTooltip
          title={'Create a team'}
          description={'Share playgrounds, templates, and folders'}
          hideSelf={hideTeamsTooltip}
          bottom={78}
          left={16}
          width={174}
        />
      )}
    </styles.container>
  );
};

type StyledProfileProps = {
  isTeamSwitcherVisible: boolean;
};

const styles = {
  container: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: ${colors.baseDark};
  `,
  folderContainer: styled.div`
    padding: 48px 24px 32px 24px;
    overflow: auto;
  `,
  profile: styled.div<StyledProfileProps>`
    display: flex;
    padding: 11px 14px;
    margin: 12px;
    align-items: center;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.16s;
    width: 216px;
    box-sizing: border-box;
    flex-shrink: 0;
    background: ${props =>
      props.isTeamSwitcherVisible ? colors.W6 : colors.clear};

    :hover {
      background: ${colors.W6};
    }
  `,
  newFolder: styled(Text)`
    cursor: pointer;
    padding: 8px 16px;
    border: 1px solid ${colors.clear};
    :hover {
      color: ${colors.primaryText};
    }
  `,
  nameWrapper: styled.div`
    min-width: 0;
  `
};
