import {
  TeamPermissions,
  RANKED_TEAM_PERMISSIONS
} from '../declarations/TeamPermissions';
import { PlaygroundPermissions } from '../declarations/PlaygroundPermissions';

export const hasPermissionToEdit = (permissions?: TeamPermissions) =>
  !!(
    permissions &&
    RANKED_TEAM_PERMISSIONS[permissions] >=
      RANKED_TEAM_PERMISSIONS[TeamPermissions.Edit]
  );

export const hasAdminPermissions = (permissions?: TeamPermissions) =>
  permissions === TeamPermissions.Owner;

export const getEditablePermissions = (
  currentUserPermissions?: TeamPermissions
) => {
  switch (currentUserPermissions) {
    case TeamPermissions.Owner:
      return [
        TeamPermissions.Owner,
        TeamPermissions.Edit,
        TeamPermissions.View
      ];
    case TeamPermissions.Edit:
      return [TeamPermissions.Edit, TeamPermissions.View];
    case TeamPermissions.View:
      return [];
    default:
      return [];
  }
};

export const shouldUpgradePlaygroundPermissions = (
  newPermissions: TeamPermissions,
  prevPermissions: TeamPermissions
): boolean => {
  const viewPermissionsLevel = RANKED_TEAM_PERMISSIONS[TeamPermissions.View];
  const newPermissionsLevel = RANKED_TEAM_PERMISSIONS[newPermissions];

  return (
    prevPermissions === TeamPermissions.View &&
    newPermissionsLevel > viewPermissionsLevel
  );
};

export const getPlaygroundPermissions = (teamPermissions: TeamPermissions) => {
  const viewPermissionsLevel = RANKED_TEAM_PERMISSIONS[TeamPermissions.View];
  const currentPermissionLevel = RANKED_TEAM_PERMISSIONS[teamPermissions];

  return currentPermissionLevel > viewPermissionsLevel
    ? PlaygroundPermissions.Edit
    : PlaygroundPermissions.View;
};
