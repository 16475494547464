import React from 'react';
import { colors } from '../constants/colors';
import { IconProps } from './IconProps';

export const PlusIcon = ({ margin, color, size }: IconProps) => (
  <svg
    style={{ margin }}
    width={size || 24}
    height={size || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 7V17"
      stroke={color || colors.primaryText}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M7 12H17"
      stroke={color || colors.primaryText}
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);
