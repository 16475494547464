export default class WrappedOperation {
  wrapped;
  meta;

  // A WrappedOperation contains an operation and corresponing metadata.
  constructor(operation, meta) {
    this.wrapped = operation;
    this.meta = meta;
  }

  apply = (...args) => {
    return this.wrapped.apply.apply(this.wrapped, args);
  };

  invert = (...args) => {
    var meta = this.meta;
    return new WrappedOperation(
      this.wrapped.invert.apply(this.wrapped, args),
      meta && typeof meta === 'object' && typeof meta.invert === 'function'
        ? meta.invert.apply(meta, args)
        : meta
    );
  };

  // Copy all properties from source to target.
  static copy = (source, target) => {
    for (var key in source) {
      if (source.hasOwnProperty(key)) {
        target[key] = source[key];
      }
    }
  };

  static composeMeta = (a, b) => {
    if (a && typeof a === 'object') {
      if (typeof a.compose === 'function') {
        return a.compose(b);
      }
      var meta = {};
      WrappedOperation.copy(a, meta);
      WrappedOperation.copy(b, meta);
      return meta;
    }
    return b;
  };

  compose = (other) => {
    return new WrappedOperation(
      this.wrapped.compose(other.wrapped),
      WrappedOperation.composeMeta(this.meta, other.meta)
    );
  };

  static transformMeta = (meta, operation) => {
    if (meta && typeof meta === 'object') {
      if (typeof meta.transform === 'function') {
        return meta.transform(operation);
      }
    }
    return meta;
  };

  static transform = (a, b) => {
    var pair = a.wrapped.transform(b.wrapped);
    return [
      new WrappedOperation(
        pair[0],
        WrappedOperation.transformMeta(a.meta, b.wrapped)
      ),
      new WrappedOperation(
        pair[1],
        WrappedOperation.transformMeta(b.meta, a.wrapped)
      )
    ];
  };

  // convenience method to write transform(a, b) as a.transform(b)
  transform = (other) => {
    return WrappedOperation.transform(this, other);
  };
}
