import React, { useMemo } from 'react';

import { getEditablePermissions } from '../../../utilities/TeamPermissionsUtilities';

import { Team } from '../../../declarations/Team';
import { TeamPermissions } from '../../../declarations/TeamPermissions';
import { TeamMember } from '../../../declarations/TeamMember';

import { useTeamMembership } from '../../../hooks/useTeamMembership';

import { HomeTeamPageInviteInput } from './HomeTeamPageInviteInput';
import { HomeTeamPageMemberRow } from './HomeTeamPageMemberRow';

type HomeTeamPageMemberListProps = {
  currentUser: firebase.User;
  activeTeam: Team;
  showUpgradeModal: () => void;
};

export const HomeTeamPageMemberList = ({
  activeTeam,
  currentUser,
  showUpgradeModal
}: HomeTeamPageMemberListProps) => {
  const { updateMemberAccess } = useTeamMembership(currentUser.uid, activeTeam);

  const currentUserPermissions = useMemo(
    () =>
      activeTeam.members.find(member => member.id === currentUser.uid)
        ?.permissions,
    [activeTeam, currentUser]
  );

  const memberListItemEditablePermissions = useMemo(
    () => getEditablePermissions(currentUserPermissions),
    [currentUserPermissions]
  );

  const onUpdateMemberPermissions = async (
    member: TeamMember,
    newPermissions: TeamPermissions
  ) => {
    const result = await updateMemberAccess(
      member.id,
      newPermissions,
      member.permissions
    );

    if (result?.error) {
      showUpgradeModal();
    }
  };

  return (
    <>
      <HomeTeamPageInviteInput activeTeam={activeTeam} />
      {activeTeam.members.map((member, index) => (
        <HomeTeamPageMemberRow
          key={member.id}
          currentUser={currentUser}
          editablePermissions={memberListItemEditablePermissions}
          member={member}
          updateMemberPermissions={onUpdateMemberPermissions}
        />
      ))}
    </>
  );
};
