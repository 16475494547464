import { FirebaseContext } from '../Firebase';
import { useContext, useCallback } from 'react';

const usePlaygroundInviteUtility = (
  playgroundId: string,
  playgroundName: string
) => {
  const firebase = useContext(FirebaseContext);

  const inviteToPlayground = useCallback(
    (email: string) => {
      if (!firebase) {
        return;
      }

      const { onInviteToPlayground } = firebase.functions();

      try {
        return onInviteToPlayground({ playgroundId, playgroundName, email });
      } catch (error) {
        return { error: 'error sending invite to playground' };
      }
    },
    [firebase, playgroundId, playgroundName]
  );

  return inviteToPlayground;
};

export default usePlaygroundInviteUtility;
