import React, { useState } from 'react';
import styled from 'styled-components';

import { colors } from '../../../constants/colors';
import { Text } from '../../general/Text';
import { Button } from '../../general/Button';
import { ArrowUpIcon } from '../../../icons/ArrowUpIcon';
import usePlaygroundInviteUtility from '../../../hooks/usePlaygroundInviteUtitlity';
import { PlaygroundInfo } from '../../../declarations/PlaygroundInfo';
import { LoadingSpinner } from '../../general/LoadingSpinner';

import {
  useKeyboardShortcut,
  KeyboardShortcutKey
} from '../../../hooks/useKeyboardShortcut';
import analytics from '../../../Analytics/Analytics';

type PlaygroundShareSheetEmailInputProps = {
  playgroundInfo: PlaygroundInfo;
};

export const PlaygroundShareSheetEmailInput = (
  props: PlaygroundShareSheetEmailInputProps
) => {
  const [emailInputValue, setEmailInputValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const inviteToPlayground = usePlaygroundInviteUtility(
    props.playgroundInfo.id,
    props.playgroundInfo.name
  );

  const onUpdateEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmailInputValue(event.target.value);
  };

  const onInviteToPlayground = async () => {
    if (!emailInputValue) {
      // TODO: validate?
      return;
    }

    const email = emailInputValue.toLowerCase();
    setEmailInputValue('');
    setIsLoading(true);

    await inviteToPlayground(email);
    analytics.track('share', {
      method: 'email',
      location: 'shareSheet',
      playgroundId: props.playgroundInfo.id
    });
    setIsLoading(false);
  };

  useKeyboardShortcut(KeyboardShortcutKey.Enter, onInviteToPlayground);

  return (
    <styles.inputContainer>
      <Text margin={'0px 0px 0px 6px'}>To:</Text>
      <styles.emailInput
        value={emailInputValue}
        onChange={onUpdateEmail}
        type={'email'}
        spellCheck={false}
        autoFocus
        placeholder={'name@example.com'}
      />
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <styles.sendInviteButton
          isDisabled={
            !emailInputValue.includes('@') || !emailInputValue.includes('.')
          }
          primary
          onClick={onInviteToPlayground}
        >
          <ArrowUpIcon color={colors.white} />
        </styles.sendInviteButton>
      )}
    </styles.inputContainer>
  );
};

const styles = {
  inputContainer: styled.div`
    display: flex;
    margin: 16px 24px 0px 24px;
    align-items: center;
    padding-bottom: 22px;
    border-bottom: 1px solid ${colors.W12};
  `,
  emailInput: styled.input`
    background: ${colors.baseDark};
    height: 30px;
    font-size: 13px;
    color: ${colors.primaryText};
    outline: none;
    border: none;
    margin: 0px 8px 0px 16px;
    box-sizing: border-box;
    width: 100%;

    ::placeholder {
      color: ${colors.inactive};
    }
  `,
  sendInviteButton: styled(Button)`
    height: 30px;
    width: 30px;
    padding: 3px;
    flex-shrink: 0;
  `
};
