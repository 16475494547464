import { useContext, useState, useEffect } from 'react';
import { FirebaseContext } from '../Firebase';
import { AppUser } from '../declarations/AppUser';
import analytics from '../Analytics/Analytics';

const useUserInfo = (userId: string) => {
  const firebase = useContext(FirebaseContext);

  const [userData, setUserData] = useState<AppUser | undefined>();

  useEffect(() => {
    if (!firebase) {
      return;
    }

    const firestore = firebase.firestore();

    const unsubscribeUserListener = firestore.doc(`users/${userId}`).onSnapshot(
      snapshot => {
        const data = snapshot.data() as Partial<Omit<AppUser, 'id'>>;

        setUserData({
          id: userId,
          name: data?.name || data?.email || '',
          email: data?.email || '',
          avatarUrl: data?.avatarUrl || ''
        });
      },
      error => {
        analytics.track('useUserInfo.userInfoObserver.error', error);
      }
    );

    return () => {
      unsubscribeUserListener();
    };
  }, [firebase, userId]);

  return userData;
};

export { useUserInfo };
