export enum EmailInputError {
  invalid = 'emailInvalid'
}

export enum PasswordInputError {
  tooShort = 'passwordTooShort'
}

export enum GeneralError {
  empty = 'empty',
  tooManyAttempts = 'tooManyAttempts',
  incorrect = 'incorrect',
  alreadyTaken = 'alreadyTaken'
}

export const EmailInputErrorMessages: Record<EmailInputError, string> = {
  [EmailInputError.invalid]: "Hmm, that email address doesn't look right."
};
export const PasswordInputErrorMessages: Record<PasswordInputError, string> = {
  [PasswordInputError.tooShort]:
    'Please set a password longer than 6 characters.'
};

export const GeneralErrorMessages: Record<GeneralError, string> = {
  [GeneralError.empty]: 'Enter your email and password.',
  [GeneralError.tooManyAttempts]:
    'Too many sign in attempts, try again in 5 minutes.',
  [GeneralError.incorrect]: 'That email and password combination is incorrect.',
  [GeneralError.alreadyTaken]: 'This email is already taken. Please sign in.'
};
