import React, { useState, useMemo, useRef, useEffect } from 'react';

import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import analytics from '../../../Analytics/Analytics';

import { useOnClickOutside } from '../../../hooks/useOnClickOutside';
import {
  useKeyboardShortcut,
  KeyboardShortcutKey
} from '../../../hooks/useKeyboardShortcut';

import { colors } from '../../../constants/colors';
import { Animations } from '../../../constants/animations';
import { Button } from '../../general/Button';
import { PlaygroundShareSheetItem } from './PlaygroundShareSheetItem';
import { PlaygroundShareSheetEmailInput } from './PlaygroundShareSheetEmailInput';

import { PlaygroundPermissions } from '../../../declarations/PlaygroundPermissions';
import { PlaygroundInfo } from '../../../declarations/PlaygroundInfo';

import {
  getEditablePermissions,
  hasPermissionToEdit
} from '../../../utilities/PlaygroundPermissionsUtilities';
import { getEmailFromId } from '../../../utilities/ValidationUtilities';

type PlaygroundShareSheetProps = {
  playgroundInfo: PlaygroundInfo;
  currentUser: firebase.User;
  updateMemberAccess: (
    userId: string,
    newPermissions: PlaygroundPermissions
  ) => Promise<void>;
  hideSelf: () => void;
};

enum LinkButtonText {
  CopyLink = 'Copy link',
  Copied = 'Copied!'
}

export const PlaygroundShareSheet = (props: PlaygroundShareSheetProps) => {
  const { playgroundId } = useParams();

  const sheetRef = useRef<HTMLDivElement | null>(null);
  const [copyLinkButtonText, setCopyLinkButtonText] = useState<LinkButtonText>(
    LinkButtonText.CopyLink
  );

  useOnClickOutside(sheetRef, props.hideSelf);
  useKeyboardShortcut(KeyboardShortcutKey.Escape, props.hideSelf);

  useEffect(() => {
    let timeout;
    let isUnmounted = false;
    if (copyLinkButtonText === LinkButtonText.Copied) {
      timeout = setTimeout(() => {
        !isUnmounted && setCopyLinkButtonText(LinkButtonText.CopyLink);
      }, 3000);
    }

    return () => {
      isUnmounted = true;
      clearTimeout(timeout);
    };
  }, [copyLinkButtonText]);

  const copyToClipboard = () => {
    setCopyLinkButtonText(LinkButtonText.Copied);
    var textField = document.createElement('textarea');
    textField.innerText = window.location.href;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
    analytics.track('share', {
      method: 'copy_link',
      location: 'shareSheet',
      playgroundId: playgroundId
    });
  };

  const currentUserPermissions = props.playgroundInfo.members.find(
    member => member.id === props.currentUser.uid
  )?.permissions;

  const shareSheetItemEditablePermissions = useMemo(
    () => getEditablePermissions(currentUserPermissions),
    [currentUserPermissions]
  );

  const membersToDisplay = useMemo(() => {
    if (props.playgroundInfo.isPrivate) {
      return props.playgroundInfo.members.filter(member =>
        hasPermissionToEdit(member.permissions)
      );
    }

    return props.playgroundInfo.members;
  }, [props.playgroundInfo.isPrivate, props.playgroundInfo.members]);

  return (
    <styles.touchGuardOverlay>
      <styles.container ref={sheetRef}>
        <PlaygroundShareSheetEmailInput playgroundInfo={props.playgroundInfo} />
        <styles.sheetItemsContainer>
          <PlaygroundShareSheetItem
            type={'link'}
            role={props.playgroundInfo.defaultPermissions}
            text={'Anyone with the link'}
            dropdownPermissions={[]}
          />
          {membersToDisplay.map(member => (
            <PlaygroundShareSheetItem
              key={member.id}
              memberId={member.id}
              type={'user'}
              role={member.permissions}
              isCurrentUser={member.id === props.currentUser.uid}
              text={
                member.name || member.email || getEmailFromId(member.id) || ''
              }
              avatar={member.avatarUrl}
              dropdownPermissions={shareSheetItemEditablePermissions}
              onUpdateMemberAccess={props.updateMemberAccess}
            />
          ))}
        </styles.sheetItemsContainer>
        <styles.buttonContainer>
          <styles.copyLinkButton onClick={copyToClipboard}>
            {copyLinkButtonText}
          </styles.copyLinkButton>
        </styles.buttonContainer>
      </styles.container>
    </styles.touchGuardOverlay>
  );
};

const styles = {
  container: styled.div`
    width: 360px;
    position: absolute;
    top: 0px;
    right: 11px;
    padding: 8px 0px 0px 0px;
    background: ${colors.baseDark};
    box-shadow: inset 0px 0px 0px 1px ${colors.W12},
      0px 8px 24px ${colors.dropShadow};
    border-radius: 8px;
    ${Animations.BounceDown};
  `,
  sheetItemsContainer: styled.div`
    overflow: auto;
    max-height: 400px;
    padding-top: 12px;
  `,
  copyLinkButton: styled(Button)`
    width: 100%;
    border-radius: 8px;
    padding: 8px 13px;
    box-sizing: border-box;
  `,
  buttonContainer: styled.div`
    padding: 20px 24px 24px 24px;
  `,
  touchGuardOverlay: styled.div`
    position: absolute;
    top: 50px;
    left: 50vw;
    width: 50vw;
    height: calc(100vh - 50px);
    z-index: 21;
  `
};
