import * as Client from './Client';
import Cursor from './Cursor';
import EditorClient from './EditorClient';
import FileEditorClient from './FileEditorClient';
import FirebaseAdapter from './FirebaseAdapter';
import FirebaseFileAdapter from './FirebaseFileAdapter';
import RealtimeManager from './RealtimeManager';
import MonacoAdapter from './MonacoAdapter';
import MonacoBridge from './MonacoBridge';
import MonacoFileAdapter from './MonacoFileAdapter';
import TextOp from './TextOp';
import TextOperation from './TextOperation';
import UndoManager from './UndoManager';
import * as utils from './utils';
import WrappedOperation from './WrappedOperation';

import { FileAdapterEvent } from './declarations/FileAdapterEvent';
import { FileEditorClientEvent } from './declarations/FileEditorClientEvent';
import { FirebaseAdapterEvent } from './declarations/FirebaseAdapterEvent';
import { RealtimeManagerEvent } from './declarations/RealtimeManagerEvent';

// MODULES
export {
  Client,
  Cursor,
  EditorClient,
  FileEditorClient,
  FirebaseAdapter,
  FirebaseFileAdapter,
  RealtimeManager,
  MonacoAdapter,
  MonacoBridge,
  MonacoFileAdapter,
  TextOp,
  TextOperation,
  UndoManager,
  utils,
  WrappedOperation
};

// TYPES
export {
  FileAdapterEvent,
  FileEditorClientEvent,
  FirebaseAdapterEvent,
  RealtimeManagerEvent
};

export default RealtimeManager;
