import styled, { keyframes } from 'styled-components';

type OverlayProps = {
  light?: boolean;
};

const modalAnimation = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

export const Overlay = styled.div<OverlayProps>`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background: ${props => (props.light ? 'rgba(0,0,0,0.3)' : 'rgba(0,0,0,0.5)')};
  z-index: 300;
  animation: ${modalAnimation} 0.16s;
`;
