import { useState, useEffect, useContext } from 'react';
import { Folder } from '../declarations/Folder';
import { FirebaseContext } from '../Firebase';
import { DRAFTS_FOLDER_ID, INTERVIEWS_FOLDER_ID } from '../constants/keys';
import { FileType } from '../declarations/FileType';
import { getBaseTeamFolders } from '../utilities/TeamFoldersUtility';
import { PlaygroundPermissions } from '../declarations/PlaygroundPermissions';
import analytics from '../Analytics/Analytics';

const useHomeFolders = (
  user: firebase.User,
  foldersCollectionRef?: firebase.firestore.CollectionReference,
  isPersonal?: boolean
) => {
  const firebase = useContext(FirebaseContext);

  const [folders, setFolders] = useState<Array<Folder>>([]);

  useEffect(() => {
    if (!firebase || !foldersCollectionRef || !user.uid) {
      return;
    }

    const teamId = foldersCollectionRef.parent?.id;
    if (!teamId) {
      return;
    }

    const firestore = firebase.firestore();

    const unsubscribeFromFoldersCollection = foldersCollectionRef.onSnapshot(
      snapshot => {
        const folders = snapshot.docs.map(doc => {
          const data = doc.data();
          const folderId = doc.id;

          return {
            id: doc.id,
            name: data.name,
            attributes: data.attributes,
            playgroundCollectionRef: firestore
              .collection('playgrounds')
              .where('teamId', '==', teamId)
              .where('folderId', '==', folderId)
              .where('type', '==', FileType.Playground),
            templateCollectionRef: firestore
              .collection('playgrounds')
              .where('teamId', '==', teamId)
              .where('folderId', '==', folderId)
              .where('type', '==', FileType.Template)
          };
        });

        const draftsRef = firestore
          .collection(`playgrounds`)
          .where('teamId', '==', teamId)
          .where('folderId', '==', DRAFTS_FOLDER_ID)
          .where(
            `members.${user.uid}.permissions`,
            '==',
            PlaygroundPermissions.Owner
          );

        const recentsRef = firestore
          .collection(`users/${user.uid}/recents`)
          .where('teamId', '==', teamId)
          .orderBy('lastViewed')
          .limitToLast(25);

        const interviewsRef = isPersonal
          ? undefined
          : firestore
              .collection(`playgrounds`)
              .where('teamId', '==', teamId)
              .where('folderId', '==', INTERVIEWS_FOLDER_ID);

        setFolders([
          ...getBaseTeamFolders({ draftsRef, recentsRef, interviewsRef }),
          ...folders
        ]);
      },
      error => {
        analytics.track('useHomeFolder.homeFoldersObserver.error', error);
      }
    );

    return () => {
      unsubscribeFromFoldersCollection();
      setFolders([]);
    };
  }, [firebase, foldersCollectionRef, user.uid, isPersonal]);

  return folders;
};

export default useHomeFolders;
