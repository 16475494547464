import { useCallback, useContext } from 'react';
import { FirebaseContext } from '../Firebase';
import { PlaygroundInfo } from '../declarations/PlaygroundInfo';

const usePlaygroundInfo = () => {
  const firebase = useContext(FirebaseContext);

  const fetchPlaygroundInfo = useCallback(
    async (playgroundId: string) => {
      if (!firebase) {
        return;
      }

      const firestore = firebase.firestore();

      const playground = await firestore
        .doc(`playgrounds/${playgroundId}`)
        .get();

      return playground.data() as PlaygroundInfo; // TODO: Safely transform
    },
    [firebase]
  );

  return fetchPlaygroundInfo;
};
export default usePlaygroundInfo;
