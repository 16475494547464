import { useCallback, useContext } from 'react';
import { FirebaseContext } from '../Firebase';
import { Team } from '../declarations/Team';

const useUpdateTeam = (teamId: string) => {
  const firebase = useContext(FirebaseContext);

  const updateTeamInfo = useCallback(
    (newInfo: Partial<Team>) => {
      if (!firebase) {
        return;
      }

      const firestore = firebase.firestore();

      const { id, ...fieldsToUpdate } = newInfo;

      const teamRef = firestore.doc(`teams/${teamId}`);
      teamRef.update(fieldsToUpdate);
    },
    [firebase, teamId]
  );

  const updateTeamName = useCallback(
    async (newName: string) => {
      if (!firebase) {
        return;
      }

      try {
        await updateTeamInfo({ name: newName });
      } catch (error) {
        //
      }
    },
    [firebase, updateTeamInfo]
  );

  const updateTeamAvatar = useCallback(
    async (newAvatar: File) => {
      if (!firebase) {
        return;
      }

      const storage = firebase.storage();

      try {
        const avatarStorageRef = storage.ref(`TEAM_AVATARS/${teamId}`);
        await avatarStorageRef.put(newAvatar);

        const avatarUrl = await avatarStorageRef.getDownloadURL();
        if (!avatarUrl) {
          return;
        }

        await updateTeamInfo({ avatarUrl });
      } catch (e) {
        //
      }
    },
    [firebase, teamId, updateTeamInfo]
  );

  return { updateTeamName, updateTeamAvatar };
};

export default useUpdateTeam;
