import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';

import analytics from './Analytics/Analytics';

import { useHistory, useLocation, useParams } from 'react-router-dom';

import { HomeLeftSideBar } from './components/home/home-left-sidebar/HomeLeftSideBar';
import { BetaFeedback } from './components/general/BetaFeedback';
import HomeMainContent from './components/home/HomeMainContent';
import { HomeNewFolderModal } from './components/home/HomeNewFolderModal';
import { HomeNewTeamModal } from './components/home/HomeNewTeamModal';
import { PlaygroundLoadingScreen } from './components/playground/PlaygroundLoadingScreen';
import { Toast } from './components/general/Toast';

import { usePlaygroundCreationUtility } from './hooks/usePlaygroundCreationUtility';
import { useUserInfo } from './hooks/useUserInfo';
import useDeletePlayground from './hooks/useDeletePlayground';
import useTeamCreationUtilities from './hooks/useTeamCreationUtilities';
import { useToggle } from './hooks/useToggle';
import { useHomeManager } from './hooks/useHomeManager';

import { PlaygroundInfo } from './declarations/PlaygroundInfo';
import { FolderPermissions } from './declarations/FolderPermissions';
import { PlaygroundPermissions } from './declarations/PlaygroundPermissions';
import { hasPermissionToEdit } from './utilities/TeamPermissionsUtilities';
import { INTERVIEWS_FOLDER_ID } from './constants/keys';

type HomeProps = {
  user: firebase.User;
};

const Home = ({ user }: HomeProps) => {
  const history = useHistory();
  const location = useLocation();
  const { teamId, folderId } = useParams();

  const isViewingSettings = location.pathname === '/settings'; // TODO: Route constants
  const isViewingTeamPage = location.pathname === `/${teamId}/settings`;

  const {
    activeTeam,
    teams,
    folders,
    activeFolder,
    playgrounds,
    templates
  } = useHomeManager(user, teamId, folderId);

  const userData = useUserInfo(user.uid);

  const {
    createNewPlayground,
    createNewTemplate
  } = usePlaygroundCreationUtility(
    user,
    activeTeam?.id,
    activeFolder?.id,
    activeTeam?.attributes?.isPersonal,
    activeTeam?.subscriptionId
  );

  const { createNewTeam } = useTeamCreationUtilities(user.uid);

  const deletePlayground = useDeletePlayground();

  const {
    value: isHomeNewFolderModalVisible,
    setTrue: showHomeNewFolderModal,
    setFalse: hideHomeNewFolderModal
  } = useToggle(false);

  const {
    value: isHomeNewTeamModalVisible,
    setTrue: showHomeNewTeamModal,
    setFalse: hideHomeNewTeamModal
  } = useToggle(false);

  const {
    value: isLoadingScreenVisible,
    setTrue: showLoadingScreen,
    setFalse: hideLoadingScreen
  } = useToggle(false);

  const {
    value: isToastVisible,
    setTrue: showToast,
    setFalse: hideToast
  } = useToggle(false);

  const handleCreateNewTeam = async (teamName: string) => {
    try {
      const newTeamId = await createNewTeam({ teamName });
      analytics.track('home.newTeamCreated');
      if (newTeamId) {
        history.push(`/${newTeamId}/settings`);
      }
    } catch (error) {
      analytics.track('home.handleCreateNewTeam.error', error);
    }
  };

  const handleCreateNewFolder = useCallback(
    async (fileName: string) => {
      if (user.isAnonymous || !activeTeam) {
        return;
      }

      const firestoreNewFolderRef = activeTeam.folderCollectionRef.doc();

      try {
        await firestoreNewFolderRef.set({
          name: fileName,
          members: { [user.uid]: FolderPermissions.Owner }
        });
        analytics.track('home.newFolderCreated');

        history.push(`/${activeTeam.id}/${firestoreNewFolderRef.id}`);
      } catch (error) {
        analytics.track('home.handleCreateNewFolder.error', error);
      }
    },
    [user.uid, user.isAnonymous, activeTeam, history]
  );

  const handleCreateNewTemplate = useCallback(
    async (playgroundId: string) => {
      const playground = playgrounds.find(
        playground => playground.id === playgroundId
      );

      if (!playground) {
        return;
      }

      await createNewTemplate(playground);

      analytics.track('home.newTemplateCreated', {
        isInterview: activeFolder?.id === INTERVIEWS_FOLDER_ID
      });
    },
    [activeFolder, createNewTemplate, playgrounds]
  );

  const handleCreateNewPlayground = useCallback(
    async (template?: PlaygroundInfo) => {
      showLoadingScreen();

      try {
        const newPlaygroundUniqueId = await createNewPlayground(template);

        if (!newPlaygroundUniqueId) {
          hideLoadingScreen();
          return;
        }

        analytics.track('home.newPlaygroundCreated', {
          id: newPlaygroundUniqueId,
          fromTemplate: !!template,
          isInterview: activeFolder?.id === INTERVIEWS_FOLDER_ID
        });

        history.push(`/p/${newPlaygroundUniqueId}`);
      } catch (error) {
        hideLoadingScreen();
        showToast();
      }
    },
    [
      activeFolder,
      createNewPlayground,
      history,
      showLoadingScreen,
      hideLoadingScreen,
      showToast
    ]
  );

  const handleDeletePlayground = useCallback(
    (playground: PlaygroundInfo) => {
      const member = playground.members[user.uid];
      if (member?.permissions !== PlaygroundPermissions.Owner) {
        return;
      }

      return deletePlayground(playground);
    },
    [user.uid, deletePlayground]
  );

  const currentUserTeamMemberPermissions = useMemo(
    () =>
      activeTeam?.members.find(member => member.id === user.uid)?.permissions,
    [activeTeam, user.uid]
  );

  const hasEditPermissions = useMemo(
    () => hasPermissionToEdit(currentUserTeamMemberPermissions),
    [currentUserTeamMemberPermissions]
  );

  if (!activeTeam) {
    return <div></div>;
  }

  return (
    <styles.pageContainer>
      <HomeLeftSideBar
        teams={teams}
        currentUserHasEditPermissions={hasEditPermissions}
        activeTeam={activeTeam}
        currentUserData={userData}
        isViewingSettings={isViewingSettings}
        folders={folders}
        showHomeNewFolderModal={showHomeNewFolderModal}
        showHomeNewTeamModal={showHomeNewTeamModal}
        activeFolderId={activeFolder?.id || ''}
      />
      <HomeMainContent
        user={user}
        userData={userData}
        currentUserHasEditPermissions={hasEditPermissions}
        activeTeam={activeTeam}
        activeFolder={activeFolder}
        isViewingSettings={isViewingSettings}
        isViewingTeamPage={isViewingTeamPage}
        playgrounds={playgrounds}
        templates={templates}
        handleCreateNewPlayground={handleCreateNewPlayground}
        handleCreateNewTemplate={handleCreateNewTemplate}
        handleDeletePlayground={handleDeletePlayground}
      />
      {isHomeNewFolderModalVisible && (
        <HomeNewFolderModal
          onClose={hideHomeNewFolderModal}
          onSubmit={handleCreateNewFolder}
        />
      )}
      {isHomeNewTeamModalVisible && (
        <HomeNewTeamModal
          onClose={hideHomeNewTeamModal}
          onSubmit={handleCreateNewTeam}
        />
      )}
      <BetaFeedback currentUser={userData} />
      {isLoadingScreenVisible && <PlaygroundLoadingScreen />}
      {isToastVisible && (
        <Toast
          isDestructive
          text={'Unable to create playground'}
          hideSelf={hideToast}
          action={{ text: 'Close', handler: hideToast }}
        />
      )}
    </styles.pageContainer>
  );
};

const styles = {
  pageContainer: styled.div`
    display: grid;
    grid-template-columns: 240px auto;
    grid-template-rows: 100vh;
  `
};

export default Home;
