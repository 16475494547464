import { editor as monaco } from 'monaco-editor';
import { MonacoAdapter, FirebaseAdapter } from '.';

export default class MonacoBridge {
  firebaseAdapter: FirebaseAdapter;
  monacoAdapter: MonacoAdapter;

  constructor(firebaseAdapter: FirebaseAdapter, monacoAdapter: MonacoAdapter) {
    this.firebaseAdapter = firebaseAdapter;
    this.monacoAdapter = monacoAdapter;

    this.monacoAdapter.registerCallbacks({
      change: (event: monaco.IModelContentChangedEvent) => {
        // todo: maybe pass the intended model along?
        this.onChange(event);
      }
    });
  }

  onChange = (event: monaco.IModelContentChangedEvent) => {
    this.firebaseAdapter.activeAdapter()?.monacoFileAdapter?.onChange(event);
  };
}
