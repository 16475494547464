import { useContext, useCallback } from 'react';

import { FirebaseContext } from '../Firebase';

const useDeleteFolder = (teamId: string) => {
  const firebase = useContext(FirebaseContext);

  const deleteFolder = useCallback(
    async (folderId: string) => {
      if (!firebase || !teamId) {
        return;
      }

      const firestore = firebase.firestore();

      return firestore.doc(`teams/${teamId}/folders/${folderId}`).delete();
    },
    [firebase, teamId]
  );

  return deleteFolder;
};

export default useDeleteFolder;
