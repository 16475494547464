import React from 'react';
import styled from 'styled-components';

import { device } from '../../constants/deviceBreakpoints';
import { colors } from '../../constants/colors';
import { RefreshIcon } from '../../icons/RefreshIcon';

type PlaygroundRefreshButtonProps = {
  onRefreshPlayground: () => void;
};

export const PlaygroundRefreshButton = (
  props: PlaygroundRefreshButtonProps
) => {
  return (
    <styles.container onClick={props.onRefreshPlayground}>
      <RefreshIcon />
    </styles.container>
  );
};

const styles = {
  container: styled.button`
    display: flex;
    place-content: center;
    position: absolute;
    top: 58px;
    right: calc(50% - 36px);
    height: 30px;
    width: 30px;
    background: ${colors.clear};
    opacity: 0.5;
    z-index: 20;
    outline: none;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    transition: transform 0s, background 0.16s;
    transform: rotate(360deg);

    :hover {
      background: ${colors.W6};
      opacity: 1;
      transition: transform 0.32s cubic-bezier(0.18, 0.9, 0.32, 1.16),
        background 0.16s;
    }

    :hover:active {
      background: ${colors.W8};
      transform: rotate(0deg);
      transition: transform 0s, background 0.16s;
    }

    ${device.mobile} {
      display: none;
    }
  `
};
