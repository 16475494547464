import amplitude from 'amplitude-js';

class Analytics {
  constructor() {
    if (process.env.REACT_APP_AMPLITUDE_KEY) {
      amplitude.getInstance().init(process.env.REACT_APP_AMPLITUDE_KEY);
    }
  }

  getAmplitudeInstance = () => {
    return amplitude.getInstance();
  };

  identify = (
    attibuteName: string,
    attributeData: string | number | boolean | object | any[]
  ) => {
    const identify = new amplitude.Identify().set(attibuteName, attributeData);
    this.getAmplitudeInstance()?.identify(identify);
  };

  track = (eventName: string, eventData?: any) => {
    this.getAmplitudeInstance()?.logEvent(eventName, eventData);
  };
}

const analytics = new Analytics();

export default analytics;
