import { useEffect, useContext, useState, useCallback } from 'react';
import { FirebaseContext } from '../Firebase';
import analytics from '../Analytics/Analytics';
import { FirestoreProjectFile } from '../declarations/FirestoreProjectFile';
import { ProjectDependency } from '../declarations/ProjectDependency';
import { PlaygroundInfo } from '../declarations/PlaygroundInfo';
import { BackendPlaygroundInfoMembers } from '../declarations/BackendPlaygroundInfo';
import { PlaygroundPermissions } from '../declarations/PlaygroundPermissions';
import { isValidFileName } from '../utilities/ValidationUtilities';
import { FileType } from '../declarations/FileType';
import { IDependencies } from '../declarations/Preview';

const useFirestorePlayground = (
  userId: string,
  playgroundId: string,
  setFilesystemDependencies?: (dependencies: IDependencies) => void
) => {
  const firebase = useContext(FirebaseContext);

  const [files, setFiles] = useState<Array<FirestoreProjectFile>>([]);
  const [dependencies, setDependencies] = useState<ProjectDependency>({});
  const [playgroundInfo, setPlaygroundInfo] = useState<
    PlaygroundInfo | undefined
  >();

  useEffect(() => {
    if (setFilesystemDependencies) {
      setFilesystemDependencies(dependencies);
    }
  }, [dependencies, setFilesystemDependencies]);

  /*
   *
   * Add Subscribers to Firestore Playground Document and Files Collection
   *
   */

  useEffect(() => {
    if (!firebase || !playgroundId) {
      return;
    }

    const firestore = firebase.firestore();

    const basePlaygroundRef = `playgrounds/${playgroundId}`;

    const unsubscribePlaygroundFilesObserver = firestore
      .collection(`${basePlaygroundRef}/files`)
      .onSnapshot(
        snapshot => {
          const { docs } = snapshot;
          if (!docs) {
            return;
          }

          setFiles(
            docs.map(doc => {
              const fileData = doc.data();

              const file = {
                id: doc.id,
                realtimeDatabaseRef: firebase
                  .database()
                  .ref(
                    `playgrounds/${playgroundId}/files/${fileData.realtimeDatabaseId}`
                  ),
                name: fileData.name,
                createdBy: fileData.createdBy
              };

              return file;
            })
          );
        },
        error => {
          analytics.track(
            'useFirestorePlayground.playgroundFilesObserver.error',
            error
          );
        }
      );

    const unsubscribePlaygroundObserver = firestore
      .doc(basePlaygroundRef)
      .onSnapshot(
        async snapshot => {
          const data = snapshot.data();

          if (!data) {
            return;
          }

          const {
            defaultPermissions,
            dependencies,
            name,
            members,
            teamId,
            type,
            folderId,
            isInterview,
            isPersonal,
            isPrivate,
            interviewCompletedAt
          } = data as {
            defaultPermissions: PlaygroundPermissions;
            dependencies?: IDependencies;
            name: string;
            teamId: string;
            folderId: string;
            type: FileType;
            members?: BackendPlaygroundInfoMembers['members'];
            isInterview: boolean;
            isPersonal: boolean;
            isPrivate?: boolean;
            interviewCompletedAt?: firebase.firestore.Timestamp;
          };

          let transformedMembers: PlaygroundInfo['members'] = [];
          if (members) {
            transformedMembers = await Promise.all(
              Object.keys(members).map(async memberId => {
                const user = await firestore.doc(`users/${memberId}`).get();

                const userData = user.data();

                return {
                  id: memberId,
                  name: userData?.name || '',
                  email: userData?.email || '',
                  avatarUrl: userData?.avatarUrl,
                  permissions: members[memberId]?.permissions
                };
              })
            );
          }

          if (dependencies) {
            setDependencies(dependencies);
          }

          setPlaygroundInfo({
            defaultPermissions,
            name,
            teamId,
            type,
            folderId,
            isInterview,
            isPersonal,
            isPrivate,
            interviewCompletedAt,
            id: snapshot.id,
            members: transformedMembers
          });
        },
        error => {
          analytics.track(
            'useFirestorePlayground.playgroundObserver.error',
            error
          );
        }
      );

    return () => {
      unsubscribePlaygroundFilesObserver();
      unsubscribePlaygroundObserver();
    };
  }, [firebase, playgroundId]);

  /*
   *
   * Update current users recents with this playground
   *
   */

  const teamId = playgroundInfo?.teamId;
  useEffect(() => {
    if (!firebase || !playgroundId || !userId || !teamId) {
      return;
    }

    firebase
      .firestore()
      .doc(`users/${userId}/recents/${playgroundId}`)
      .set({
        id: playgroundId,
        teamId,
        lastViewed: firebase.app().firestore.Timestamp.now()
      });
  }, [firebase, playgroundId, userId, teamId]);

  const createFile = async (
    name: string
  ): Promise<FirestoreProjectFile | undefined> => {
    if (!firebase || !playgroundId) {
      return;
    }

    const basePlaygroundRef = `playgrounds/${playgroundId}`;

    const realtimeDatabaseRef = firebase
      .database()
      .ref(`playgrounds/${playgroundId}/files`)
      .push();

    const newFileRef = firebase
      .firestore()
      .collection(`${basePlaygroundRef}/files`)
      .doc();

    await newFileRef.set({
      name,
      realtimeDatabaseId: realtimeDatabaseRef.key
    });

    return { id: newFileRef.id, realtimeDatabaseRef, name, createdBy: userId };
  };

  const updateDependencies = useCallback(
    async (dependencies: IDependencies) => {
      if (!firebase || !playgroundId) {
        return;
      }

      const basePlaygroundRef = `playgrounds/${playgroundId}`;

      return firebase
        .firestore()
        .doc(basePlaygroundRef)
        .update({ dependencies });
    },
    [firebase, playgroundId]
  );

  const updatePlaygroundName = async (name: string) => {
    if (!firebase || !playgroundId) {
      return;
    }

    const basePlaygroundRef = `playgrounds/${playgroundId}`;

    return firebase
      .firestore()
      .doc(basePlaygroundRef)
      .update({ name }); // TODO: @Forest add logic if the name if the same as before don't update
  };

  const updateFileName = async (fileId: string, name: string) => {
    if (!firebase || !playgroundId) {
      return false;
    }

    if (!isValidFileName(name)) {
      return false;
    }

    if (files.find(file => file.name === name)) {
      return false;
    }

    const playgroundFileRef = `playgrounds/${playgroundId}/files/${fileId}`;

    await firebase
      .firestore()
      .doc(playgroundFileRef)
      .update({ name }); // TODO: @Forest add logic if the name if the same as before don't update

    setFiles(prevFiles => {
      return prevFiles.map(file =>
        file.id === fileId ? { ...file, name } : file
      );
    });

    return true;
  };

  return {
    dependencies,
    files,
    playgroundInfo,
    createFile,
    updateDependencies,
    updatePlaygroundName,
    updateFileName
  };
};

export { useFirestorePlayground };
