import { useRef, useEffect, useCallback } from 'react';

export enum KeyboardShortcutKey {
  Escape = 'Escape',
  Enter = 'Enter',
  ArrowDown = 'ArrowDown',
  ArrowUp = 'ArrowUp',
  Meta = 'Meta', // command
  p = 'p',
  r = 'r'
}

export const useKeyboardShortcut = (
  targetKeys: [KeyboardShortcutKey, KeyboardShortcutKey] | KeyboardShortcutKey,
  handler: (event: KeyboardEvent | undefined) => void
) => {
  const savedHandler: React.MutableRefObject<(
    event?: KeyboardEvent
  ) => void> = useRef(() => null);

  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  const downHandler = useCallback(
    (event: KeyboardEvent) => {
      if (targetKeys[0] === 'Meta') {
        if (event?.metaKey && event?.key === targetKeys[1]) {
          return savedHandler?.current(event);
        }
      }

      if (event.key === targetKeys) {
        savedHandler.current();
      }
    },
    [targetKeys]
  );

  useEffect(() => {
    window.addEventListener('keydown', downHandler);
    return () => {
      window.removeEventListener('keydown', downHandler);
    };
  }, [downHandler]);
};
