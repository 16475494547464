import { keyframes, css } from 'styled-components';

const TranslateUp = keyframes`
from {
  transform: translateY(8px);
}

to {
  transform: translateY(0px);
}
`;

const TranslateDown = keyframes`
from {
  transform: translateY(-8px);
}

to {
  transform: translateY(0px);
}
`;

const ScaleUp = keyframes`
  from {
    transform: scale(0.94);
  }

  to {
    transform: scale(1);
  }
`;

const Appear = keyframes`
 from {
   opacity: 0;
 }

 to {
   opacity: 1
 }
`;

export const Animations = {
  BounceDown: css`
    animation: ${TranslateDown} 0.24s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  `,
  BounceUp: css`
    animation: ${TranslateUp} 0.24s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  `,
  BounceIn: css`
    animation: ${ScaleUp} 0.24s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  `,
  FadeIn: css`
    animation: ${Appear} 0.24s;
  `
};
