import { Uri, editor } from 'monaco-editor';
import { editorModelOptions } from '../constants/editorStyling';

export const getFileType = (fileName: string) => {
  const pattern = /\.[0-9a-z]+$/i;

  const match = fileName.match(pattern);

  return match && match[0];
};

// TODO: add better types
const typescriptFileEndings = ['.tsx', '.ts', '.jsx', '.js'];
export const getModelType = (fileType: string | null) => {
  if (fileType && typescriptFileEndings.includes(fileType)) {
    return 'typescript';
  } else if (fileType === '.css') {
    return 'css';
  } else if (fileType === '.json') {
    return 'json';
  }
  return 'javascript'; // TODO: maybe not default this?
};

export const createModel = (name: string, code?: string) => {
  const fileType = getFileType(name);
  const modelType = getModelType(fileType);
  try {
    const newModel: editor.ITextModel = editor.createModel(
      code || '',
      modelType,
      Uri.parse(`file:///${name}`)
    );
    newModel.updateOptions(editorModelOptions);
    return newModel;
  } catch (error) {
    //
  }
};
