import { TeamPermissions } from '../declarations/TeamPermissions';

export const createTeam = async ({
  newTeamRef,
  isPersonal,
  teamName,
  userId,
  timestamp
}: {
  newTeamRef: firebase.firestore.DocumentReference;
  userId: string;
  isPersonal?: boolean;
  teamName?: string;
  timestamp: firebase.firestore.Timestamp;
}) => {
  if (!isPersonal && !teamName) {
    return;
  }

  const teamInfo: {
    name: string;
    members: Record<string, { permissions: TeamPermissions }>;
    attributes: { isPersonal?: boolean };
    createdAt: firebase.firestore.Timestamp;
  } = {
    name: !teamName || isPersonal ? 'Personal' : teamName,
    members: { [userId]: { permissions: TeamPermissions.Owner } },
    attributes: {},
    createdAt: timestamp
  };

  if (isPersonal) {
    teamInfo.attributes.isPersonal = true;
  }

  try {
    await Promise.all([newTeamRef.set(teamInfo)]);

    return newTeamRef.id;
  } catch (error) {
    //
  }
};
