import { useContext, useCallback } from 'react';
import { FirebaseContext } from '../Firebase';
import { PlaygroundInfo } from '../declarations/PlaygroundInfo';
import { PlaygroundPermissions } from '../declarations/PlaygroundPermissions';

const useInterviewUtilities = (playgroundId: string) => {
  const firebase = useContext(FirebaseContext);
  const endInterview = useCallback(
    (members: PlaygroundInfo['members']) => {
      if (!firebase) {
        return;
      }

      const firestore = firebase.firestore();

      const playgroundMembers: Record<
        string,
        { permissions: PlaygroundPermissions }
      > = {};
      members.forEach(member => {
        playgroundMembers[member.id] = {
          permissions: PlaygroundPermissions.View
        };
      });

      firestore.doc(`playgrounds/${playgroundId}`).update({
        interviewCompletedAt: firebase.app().firestore.Timestamp.now(),
        defaultPermissions: PlaygroundPermissions.View,
        members: playgroundMembers
      });
    },
    [firebase, playgroundId]
  );

  return { endInterview };
};

export default useInterviewUtilities;
