import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Playground from './Playground';
import { FirebaseContext } from './Firebase';

type PlaygroundRouterProps = {
  user: firebase.User;
};

const PlaygroundRouter = ({ user }: PlaygroundRouterProps) => {
  const { playgroundId } = useParams();

  //   useEffect(() => {
  //     if (!firebase || !playgroundId) {
  //       return;
  //     }

  //     const firestore = firebase.firestore();
  //     const playgroundDoc = firestore.doc(`users/${user.uid}`);
  //     firestore.runTransaction(async transaction => {
  //       const documentResult = await transaction.get(playgroundDoc);
  //       const documentData = documentResult.data();

  //       const existingRecents = documentData?.recentFiles || [];

  //       const newFile = { [playgroundId]: { name: 'h' } };
  //       const newRecents = [...existingRecents];
  //       while (existingRecents && newRecents.length <= 20) {
  //         //
  //       }
  //     });
  //   }, [firebase, playgroundId, user.uid]);

  if (!playgroundId) {
    return <div>no id</div>;
  }

  //

  return <Playground user={user} playgroundId={playgroundId} />;
};

export default PlaygroundRouter;
