import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { colors } from '../../../constants/colors';
import { Logo } from '../../../icons/Logo';
import { CloseIcon } from '../../../icons/CloseIcon';
import { Button } from '../general/Button';
import { useLockBodyScroll } from '../../../hooks/useLockBodyScroll';

type MarketingLandingMobileMenuProps = {
  hideMenu: () => void;
  onSignUp: () => void;
};

export const MarketingLandingMobileMenu = (
  props: MarketingLandingMobileMenuProps
) => {
  useLockBodyScroll();

  return (
    <styles.container>
      <styles.header>
        <Link to={'/'}>
          <Logo size={32} color={colors.base} />
        </Link>
        <styles.closeIconContainer onClick={props.hideMenu}>
          <CloseIcon color={colors.base} />
        </styles.closeIconContainer>
      </styles.header>
      <styles.link to={'/features'}>Features</styles.link>
      <styles.link to={'/teams'}>Teams</styles.link>
      <styles.link to={'/pricing'}>Pricing</styles.link>
      <Button primary XL margin={'40px 0px 0px 28px'} onClick={props.onSignUp}>
        Sign up
      </Button>
    </styles.container>
  );
};

const styles = {
  header: styled.div`
    top: 0px;
    left: 0px;
    width: 100%;
    height: 64px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 24px;
    margin-bottom: 64px;
    box-sizing: border-box;
  `,
  closeIconContainer: styled.div`
    cursor: pointer;
  `,
  link: styled(Link)`
    display: block;
    font-size: 32px;
    line-height: 40px;
    color: ${colors.base};
    margin-left: 28px;
    margin-top: 24px;
    cursor: pointer;
    text-decoration: none;

    :hover {
      text-decoration: underline;

      ::after {
        content: ' →';
      }
    }
  `,
  container: styled.div`
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    background: ${colors.white};

    @media only screen and (min-width: 600px) {
      display: none;
    }
  `
};
