import React from 'react';
import styled from 'styled-components';

import { colors } from '../../constants/colors';
import { TooltipArrowIcon } from '../../icons/TooltipArrowIcon';
import { CloseIcon } from '../../icons/CloseIcon';
import { Text } from '../general/Text';

type FirstTimeTooltipProps = {
  title: string;
  description: string;
  width?: number;
  top?: number;
  right?: number;
  bottom?: number;
  left?: number;
  hideSelf: () => void;
};

export const FirstTimeTooltip = (props: FirstTimeTooltipProps) => {
  return (
    <styles.container
      top={props.top}
      right={props.top}
      bottom={props.bottom}
      left={props.left}
      width={props.width}
    >
      <styles.closeIconContainer onClick={props.hideSelf}>
        <CloseIcon size={20} />
      </styles.closeIconContainer>
      <Text wrapText fontWeight={700} color={'white'}>
        {props.title}
      </Text>
      <Text
        wrapText
        margin={'2px 0px 0px 0px'}
        fontWeight={500}
        style={{ color: 'rgba(255,255,255,0.6)' }}
      >
        {props.description}
      </Text>
      <styles.iconContainer>
        <TooltipArrowIcon color={colors.blueberry} />
      </styles.iconContainer>
    </styles.container>
  );
};

const styles = {
  container: styled.div<
    Pick<FirstTimeTooltipProps, 'top' | 'right' | 'bottom' | 'left' | 'width'>
  >`
    position: fixed;
    top: ${props => props.top && `${props.top}px`};
    bottom: ${props => props.bottom && `${props.bottom}px`};
    left: ${props => props.left && `${props.left}px`};
    background: ${colors.blueberry};
    padding: 14px 16px;
    box-sizing: border-box;
    border-radius: 10px;
    box-shadow: 0px 8px 24px ${colors.dropShadow};
    max-width: ${props => (props.width ? `${props.width}px` : '200px')};
  `,
  iconContainer: styled.div`
    position: absolute;
    width: 24px;
    height: 10px;
    bottom: -14px;
    left: 13px;
    transform: rotate(180deg);
  `,
  closeIconContainer: styled.div`
    position: absolute;
    top: 12px;
    right: 12px;
    height: 20px;
    width: 20px;
    opacity: 0.6;
    cursor: pointer;
    transition: opacity 0.2s;

    :hover {
      opacity: 0.8;
    }
  `
};
