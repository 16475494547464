import { useContext, useEffect, useState } from 'react';
import { FirebaseContext } from '../Firebase';
import { Team } from '../declarations/Team';
import { TeamPermissions } from '../declarations/TeamPermissions';
import transformToTeam from '../transforms/TeamTransform';
import analytics from '../Analytics/Analytics';

const useHomeTeams = (userId: string) => {
  const firebase = useContext(FirebaseContext);

  const [teams, setTeams] = useState<Array<Team>>([]);

  useEffect(() => {
    if (!firebase) {
      return;
    }

    const firestore = firebase.firestore();

    const unsubscribeTeamsCollection = firestore
      .collection('teams')
      .where(`members.${userId}.permissions`, 'in', [
        TeamPermissions.Owner,
        TeamPermissions.Edit,
        TeamPermissions.View
      ])
      .onSnapshot(
        async snap => {
          try {
            const teams: Array<Team> = await Promise.all(
              snap.docs.map(async doc =>
                transformToTeam(doc, firestore.collection('users'))
              )
            );
            setTeams(teams);
          } catch (e) {
            console.log('ERROR HOMETEAMS: ', e);
          }
        },
        error => {
          analytics.track('useHomeTeams.teamsObserver.error', error);
        }
      );

    return () => {
      unsubscribeTeamsCollection();
    };
  }, [firebase, userId]);

  return teams;
};

export { useHomeTeams };
