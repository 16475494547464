import React, { useState } from 'react';
import styled from 'styled-components';

import { colors } from '../../constants/colors';

import { Text } from '../general/Text';
import { Input } from '../general/Input';
import { Button } from '../general/Button';
import { Logo } from '../../icons/Logo';
import {
  useKeyboardShortcut,
  KeyboardShortcutKey
} from '../../hooks/useKeyboardShortcut';

type HomeNewTeamModalProps = {
  onClose: () => void;
  onSubmit: (fileName: string) => void;
};

export const HomeNewTeamModal = (props: HomeNewTeamModalProps) => {
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleSubmit = () => {
    if (inputValue) {
      props.onSubmit(inputValue);
      props.onClose();
    }
  };

  useKeyboardShortcut(KeyboardShortcutKey.Escape, props.onClose);
  useKeyboardShortcut(KeyboardShortcutKey.Enter, handleSubmit);

  return (
    <styles.container>
      <styles.header>
        <styles.logoContainer onClick={props.onClose}>
          <Logo size={32} />
        </styles.logoContainer>
        <Button XL onClick={props.onClose}>
          Cancel
        </Button>
      </styles.header>
      <styles.mainArea>
        <Text size={'title'}>New team</Text>
        <Input
          autoFocus
          autoCorrect={'off'}
          autoComplete={'off'}
          spellCheck={false}
          placeholder={'Name'}
          margin={'24px 0px 0px 0px'}
          value={inputValue}
          onChange={handleInputChange}
        />

        <styles.createTeamButton
          XL
          primary
          isDisabled={!inputValue}
          onClick={handleSubmit}
        >
          Create team
        </styles.createTeamButton>
      </styles.mainArea>
    </styles.container>
  );
};

const styles = {
  container: styled.div`
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    background: ${colors.base};
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 400;
  `,
  createTeamButton: styled(Button)`
    margin-top: 16px;
    width: 100%;
    height: 48px;
    border-radius: 10px;
    font-size: 14px;
    line-height: 24px;
  `,
  mainArea: styled.div`
    width: 100%;
    max-width: 420px;
    max-height: calc(100% - 48px);
    margin: 24px;
    box-sizing: border-box;
    padding: 40px;
    overflow: auto;
  `,
  header: styled.div`
    position: absolute;
    display: flex;
    justify-content: space-between;
    align-items: center;
    top: 0px;
    left: 0px;
    width: 100%;
    padding: 24px;
    box-sizing: border-box;
  `,
  logoContainer: styled.div`
    height: 32px;
    width: 32px;
    margin-left: 4px;
    cursor: pointer;
  `
};
