import React, { useState, useCallback, useRef } from 'react';
import styled from 'styled-components';

import { colors } from '../../constants/colors';
import { Overlay } from '../general/Overlay';
import { DEPENDENCIES_FILE_ID } from '../../constants/keys';
import { FirestoreProjectFile } from '../../declarations/FirestoreProjectFile';
import {
  useKeyboardShortcut,
  KeyboardShortcutKey
} from '../../hooks/useKeyboardShortcut';
import { isValidFileName } from '../../utilities/ValidationUtilities';

type PlaygroundFileSwitcherProps = {
  files: Array<FirestoreProjectFile>;
  activeEditingFile: FirestoreProjectFile;
  viewOnly: boolean;
  onChangeFile: (id: string, isDependencies?: boolean) => void;
  onAddFile: (name?: string) => Promise<void>;
  hideSelf: () => void;
};

type StyledMenuItemProps = {
  isSelected: boolean;
};

export const PlaygroundFileSwitcher = (props: PlaygroundFileSwitcherProps) => {
  const fileData: Array<FirestoreProjectFile> = [
    ...props.files,
    { id: DEPENDENCIES_FILE_ID, name: 'Dependencies', createdBy: '' }
  ];
  const [files, setFiles] = useState(fileData);
  const [selectedItem, setSelectedItem] = useState(0);
  const searchInput = useRef<HTMLInputElement>(null);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const filteredList = fileData.filter(file =>
      file.name.toUpperCase().includes(event.target.value.toUpperCase())
    );
    setSelectedItem(0);
    setFiles(filteredList);
  };

  const nextItem = useCallback(() => {
    // TODO: see if it doesn't cause duplicate renders
    setSelectedItem(prev => (prev + 1) % files.length);
  }, [files]);

  const previousItem = useCallback(() => {
    // TODO: see if it doesn't cause duplicate renders
    setSelectedItem(prev => (files.length + prev - 1) % files.length);
  }, [files]);

  const changeFile = (id: string, isDependencies?: boolean) => {
    props.onChangeFile(id, isDependencies);
    props.hideSelf();
  };

  const handleAddFile = async () => {
    const newFileName = searchInput.current?.value;
    if (!props.viewOnly && newFileName && isValidFileName(newFileName)) {
      await props.onAddFile(newFileName);
      props.hideSelf();
    }
  };

  useKeyboardShortcut(KeyboardShortcutKey.ArrowDown, nextItem);
  useKeyboardShortcut(KeyboardShortcutKey.ArrowUp, previousItem);
  useKeyboardShortcut(KeyboardShortcutKey.Escape, props.hideSelf);
  useKeyboardShortcut(KeyboardShortcutKey.Enter, () =>
    files.length === 0
      ? handleAddFile()
      : changeFile(
          files[selectedItem]?.id,
          files[selectedItem]?.id === DEPENDENCIES_FILE_ID
        )
  );

  return (
    <styles.container>
      <Overlay onMouseDown={props.hideSelf} light />
      <styles.modal>
        <styles.fileSearchInput
          spellCheck={false}
          autoFocus
          ref={searchInput}
          placeholder={'Search'}
          onChange={handleSearch}
        />
        <styles.scrollRegion>
          {files.map((file, index) => (
            <styles.menuItem
              key={file.id}
              isSelected={selectedItem === index}
              onMouseDown={() =>
                changeFile(file.id, file.id === DEPENDENCIES_FILE_ID)
              }
            >
              {file.name}
            </styles.menuItem>
          ))}
          {!props.viewOnly && files.length === 0 && (
            <styles.menuItem
              onMouseDown={handleAddFile}
              isSelected={files.length === 0}
            >
              New file
            </styles.menuItem>
          )}
        </styles.scrollRegion>
      </styles.modal>
    </styles.container>
  );
};

const styles = {
  container: styled.div`
    position: absolute;
    top: 0px;
    left: 0px;
  `,
  modal: styled.div`
    width: 420px;
    background: ${colors.base};
    box-shadow: inset 0px 0px 0px 1px ${colors.W8},
      0px 8px 24px ${colors.dropShadow};
    border-radius: 8px;
    position: absolute;
    top: 52px;
    left: calc(50vw - 210px);
    z-index: 400;
  `,
  menuItem: styled.div<StyledMenuItemProps>`
    padding: 8px 28px;
    font-size: 13px;
    line-height: 18px;
    color: ${props => (props.isSelected ? colors.white : colors.secondaryText)};
    cursor: pointer;
    user-select: none;
    font-weight: ${props => (props.isSelected ? 500 : 400)};
    background: ${props => (props.isSelected ? colors.blueberry : 'none')};

    :hover {
      color: ${colors.primaryText};
    }
  `,
  fileSearchInput: styled.input`
    outline: none;
    background: none;
    width: 100%;
    box-sizing: border-box;
    border: none;
    font-size: 16px;
    line-height: 24px;
    padding: 20px 28px;
    color: ${colors.primaryText};

    ::placeholder {
      color: rgba(255, 255, 255, 0.16);
    }
  `,
  scrollRegion: styled.div`
    max-height: 600px;
    overflow: auto;
    border-radius: 0px 0px 8px 8px;
    padding-bottom: 10px;
  `
};
