import { colors } from '../../../constants/colors';
import styled from 'styled-components';

type InputProps = {
  margin?: string;
  width?: number;
  error?: boolean;
};

export const Input = styled.input<InputProps>`
  box-sizing: border-box;
  color: ${colors.base};
  border-radius: 8px;
  background: none;
  border: ${props =>
    props.error ? `3px solid ${colors.strawberry}` : '3px solid #ccc'};
  padding: 10px 14px;
  font-size: 14px;
  line-height: 24px;
  outline: none;
  -webkit-appearance: none;

  width: ${props => props.width || '100%'};
  margin: ${props => props.margin || 0};
  transition: all 0.16s;

  ::placeholder {
    color: #777;
  }

  :focus {
    border: ${props =>
      props.error
        ? `3px solid ${colors.strawberry}`
        : `3px solid ${colors.blueberry}`};
  }

  @media (max-width: 560px) {
    font-size: 16px;
  }
`;
