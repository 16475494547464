import firebase from 'firebase';
import {
  FirebaseFileAdapter,
  FirebaseAdapterEvent,
  Cursor,
  TextOperation
} from '.';

export default class FirebaseAdapter {
  fileAdapters: Record<string, { adapter: FirebaseFileAdapter }>;
  activeFileId: string;

  // Adapter state
  ready_ = false;
  zombie_ = false;

  firebaseCallbacks_: Array<{
    ref: firebase.database.Reference;
    eventType: any;
    callback: VoidFunction;
    context: any;
  }> = [];
  removeFirestoreFileObserver?: () => void;

  playgroundRef_: firebase.database.Reference;
  filesRef_: firebase.firestore.CollectionReference;
  userRef_?: firebase.database.Reference;
  userId_: string;
  color_?: string;
  cursor_?: Cursor;

  usersData_: Record<string, { color?: string; activeFile?: string }> = {};

  // Event handlers
  allowedEvents_: Array<FirebaseAdapterEvent>;
  eventListeners_ = {};

  constructor(
    realtimePlaygroundRef: firebase.database.Reference,
    firestoreFilesRef: firebase.firestore.CollectionReference,
    activeFileId: string,
    userId: string,
    userColor: string
  ) {
    this.activeFileId = activeFileId;
    this.fileAdapters = {};

    this.filesRef_ = firestoreFilesRef;
    this.playgroundRef_ = realtimePlaygroundRef;

    this.userId_ = userId;

    // We store the current document state as a TextOperation so we can write checkpoints to Firebase occasionally.
    // TODO: Consider more efficient ways to do this. (composing text operations is ~linear in the length of the document).
    // this.document_ = new TextOperation();

    var self = this;

    if (userId) {
      this.setUserId(userId);
      this.setColor(userColor);

      if (this.playgroundRef_) {
        var connectedRef = this.playgroundRef_.root.child('.info/connected');

        this.firebaseOn_(
          connectedRef,
          'value',
          function(snapshot) {
            if (snapshot.val() === true) {
              self.initializeUserData_();
            }
          },
          this
        );
      }

      // Once we're initialized, start tracking users' cursors
      this.monitorCursors_();
      this.monitorFiles_();
    }

    this.allowedEvents_ = [
      FirebaseAdapterEvent.ready,
      FirebaseAdapterEvent.cursor,
      FirebaseAdapterEvent.operation,
      FirebaseAdapterEvent.ack,
      FirebaseAdapterEvent.retry,
      FirebaseAdapterEvent.change,
      FirebaseAdapterEvent.presenceChange
    ];
  }

  dispose = () => {
    Object.values(this.fileAdapters).forEach(({ adapter }) => {
      adapter.dispose();
    });

    this.removeFirebaseCallbacks_();
    this.removeFirestoreFileObserver && this.removeFirestoreFileObserver();

    if (!this.ready_) {
      this.on(FirebaseAdapterEvent.ready, () => {
        this.dispose();
      });
      return;
    }

    if (this.userRef_) {
      this.userRef_.child('cursor').remove();
      this.userRef_.child('color').remove();
    }

    this.zombie_ = true;
  };

  activeAdapter = (): FirebaseFileAdapter | undefined => {
    return this.fileAdapters[this.activeFileId]?.adapter;
  };

  setUserId = userId => {
    if (this.userRef_) {
      // Clean up existing data.  Avoid nuking another user's data
      // (if a future user takes our old name).
      this.userRef_.child('cursor').remove();
      this.userRef_
        .child('cursor')
        .onDisconnect()
        .cancel();
      this.userRef_.child('color').remove();
      this.userRef_
        .child('color')
        .onDisconnect()
        .cancel();
    }

    this.userId_ = userId;
    this.userRef_ = this.playgroundRef_?.child('users').child(userId);

    this.initializeUserData_();
  };

  sendCursor = (cursor?: Cursor) => {
    this.cursor_ = cursor;
    if (cursor) {
      this.userRef_
        ?.child('cursor')
        .set({ position: cursor.position, selectionEnd: cursor.selectionEnd });
    }
  };

  setColor = color => {
    if (color) {
      this.userRef_?.child('color').set(color);
    }
    this.color_ = color;
  };

  setActiveFileId = (fileId?: string) => {
    this.userRef_?.child('activeFile').set(fileId);

    if (fileId) {
      this.activeFileId = fileId;
    }
  };

  onCreateNewFile = (ref: firebase.database.Reference, fileName: string) => {
    if (!ref.key) {
      return;
    }

    const adapter = new FirebaseFileAdapter(ref, fileName, this.userId_);

    adapter.registerCallbacks({
      change: (fileId: string, fileName: string, document?: TextOperation) => {
        this.trigger(FirebaseAdapterEvent.change, fileName, document);
      }
    });

    this.fileAdapters[ref.key] = { adapter };

    setTimeout(() => {
      adapter.monitorHistory_();
    }, 0);
  };

  initializeUserData_ = () => {
    this.userRef_
      ?.child('cursor')
      .onDisconnect()
      .remove();
    this.userRef_
      ?.child('color')
      .onDisconnect()
      .remove();
    this.userRef_
      ?.child('activeFile')
      .onDisconnect()
      .remove();

    this.sendCursor(this.cursor_);
    this.setColor(this.color_ || null);
    this.setActiveFileId(this.activeFileId);
  };

  registerCallbacks = (
    callbacks: {
      [k in FirebaseAdapterEvent]?: (
        arg1?: any,
        arg2?: any,
        arg3?: any
      ) => void;
    }
  ) => {
    Object.keys(callbacks).forEach(eventType => {
      this.on(eventType as FirebaseAdapterEvent, callbacks[eventType]);
    });
  };

  monitorCursors_ = () => {
    var usersRef = this.playgroundRef_?.child('users'),
      self = this;

    if (!usersRef) {
      return;
    }

    function updatePresence(
      userId: string,
      color?: string,
      activeFile?: string
    ) {
      self.usersData_[userId] = {
        color,
        activeFile
      };
      self.trigger(
        FirebaseAdapterEvent.presenceChange,
        userId,
        color,
        activeFile
      );
    }

    const childChanged = (childSnap: firebase.database.DataSnapshot) => {
      var userId = childSnap.key;
      var userData = childSnap.val();

      if (!userId) {
        return;
      }

      self.trigger(
        FirebaseAdapterEvent.cursor,
        userId,
        userData.cursor,
        userData.color,
        userData.activeFile
      );

      const existingUser = userId && self.usersData_[userId];
      if (existingUser) {
        if (
          existingUser.color !== userData.color ||
          existingUser.activeFile !== userData.activeFile
        ) {
          updatePresence(userId, userData.color, userData.activeFile);
        }
      } else {
        updatePresence(userId, userData.color, userData.activeFile);
      }
    };

    this.firebaseOn_(usersRef, 'child_added', childChanged);
    this.firebaseOn_(usersRef, 'child_changed', childChanged);

    this.firebaseOn_(usersRef, 'child_removed', function(childSnap) {
      var userId = childSnap.key;

      if (!userId) {
        return; // This should never happen
      }

      delete self.usersData_[userId];

      self.trigger(FirebaseAdapterEvent.cursor, userId, null);
      self.trigger(FirebaseAdapterEvent.presenceChange, userId);
    });
  };

  monitorFiles_ = () => {
    this.removeFirestoreFileObserver = this.filesRef_.onSnapshot(
      snap => {
        snap.docChanges().forEach(change => {
          const docData = change.doc.data();
          const { name, realtimeDatabaseId } = docData;
          switch (change.type) {
            case 'added':
              if (!name || !realtimeDatabaseId) {
                return;
              }

              const newRef = this.playgroundRef_
                .child('files')
                .child(realtimeDatabaseId);

              if (newRef) {
                this.onCreateNewFile(newRef, name);
              }

              break;
            case 'modified':
              if (!name) {
                return;
              }
              const fileAdapterToModify = this.fileAdapters[realtimeDatabaseId];
              fileAdapterToModify?.adapter.updateFileName(name);
              break;
            case 'removed':
              const fileAdapterToDelete = this.fileAdapters[realtimeDatabaseId];
              fileAdapterToDelete?.adapter.dispose();

              delete this.fileAdapters[realtimeDatabaseId];
              break;
            default:
              break;
          }
        });
      },
      error => {
        // do nothing
      }
    );

    setTimeout(() => {
      this.trigger(FirebaseAdapterEvent.ready);
    }, 0);
  };

  firebaseOn_ = (
    ref: firebase.database.Reference,
    eventType,
    callback,
    context?
  ) => {
    this.firebaseCallbacks_.push({
      ref: ref,
      eventType: eventType,
      callback: callback,
      context: context
    });
    ref.on(eventType, callback, context);
    return callback;
  };

  firebaseOff_ = (ref, eventType, callback, context) => {
    ref.off(eventType, callback, context);
    for (var i = 0; i < this.firebaseCallbacks_.length; i++) {
      var l = this.firebaseCallbacks_[i];
      if (
        l.ref === ref &&
        l.eventType === eventType &&
        l.callback === callback &&
        l.context === context
      ) {
        this.firebaseCallbacks_.splice(i, 1);
        break;
      }
    }
  };

  removeFirebaseCallbacks_ = () => {
    for (var i = 0; i < this.firebaseCallbacks_.length; i++) {
      var l = this.firebaseCallbacks_[i];
      l.ref.off(l.eventType, l.callback, l.context);
    }
    this.firebaseCallbacks_ = [];
  };

  // Throws an error if the first argument is falsy. Useful for debugging.
  static assert = (b, msg?: string) => {
    if (!b) {
      throw new Error(msg || 'assertion error');
    }
  };

  // EVENT EMIITER STUFF

  on = (
    eventType: FirebaseAdapterEvent,
    callback: (...args: Array<any>) => void,
    context?
  ) => {
    this.validateEventType_(eventType);
    this.eventListeners_ = this.eventListeners_ || {};
    this.eventListeners_[eventType] = this.eventListeners_[eventType] || [];
    this.eventListeners_[eventType].push({
      callback: callback,
      context: context
    });
  };

  off = (eventType: FirebaseAdapterEvent, callback: VoidFunction) => {
    this.validateEventType_(eventType);
    this.eventListeners_ = this.eventListeners_ || {};
    var listeners = this.eventListeners_[eventType] || [];
    for (var i = 0; i < listeners.length; i++) {
      if (listeners[i].callback === callback) {
        listeners.splice(i, 1);
        return;
      }
    }
  };

  trigger = (eventType: FirebaseAdapterEvent, ...args) => {
    this.eventListeners_ = this.eventListeners_ || {};
    var listeners = this.eventListeners_[eventType] || [];
    for (var i = 0; i < listeners.length; i++) {
      listeners[i].callback.apply(listeners[i].context, args);
    }
  };

  validateEventType_ = (eventType: FirebaseAdapterEvent) => {
    if (this.allowedEvents_) {
      var allowed = false;
      for (var i = 0; i < this.allowedEvents_.length; i++) {
        if (this.allowedEvents_[i] === eventType) {
          allowed = true;
          break;
        }
      }
      if (!allowed) {
        throw new Error('Unknown event "' + eventType + '"');
      }
    }
  };
}
