import React from 'react';
import { Elements, StripeProvider } from 'react-stripe-elements';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import './App.css';

import { useUserSubscription } from './hooks/useUserSubscription';
import useStripeInstance from './hooks/useStripeInstance';

import PlaygroundRouter from './PlaygroundRouter';
import RootRouter from './RootRouter';
import Payment from './Payment';

const App: React.FC = () => {
  const { user, isAnonymous } = useUserSubscription();
  const stripe = useStripeInstance();

  return (
    <StripeProvider stripe={stripe}>
      <Router>
        <Switch>
          <Route path="/p/:playgroundId">
            {user && <PlaygroundRouter user={user} />}
          </Route>
          <Route path="/payment/payment">
            {user && (
              <Elements>
                <Payment userId={user.uid} />
              </Elements>
            )}
          </Route>
          <Route path={['/:teamId?/:folderId?', '/settings']} exact>
            <RootRouter user={user} isUserAnonymous={isAnonymous} />
          </Route>
        </Switch>
      </Router>
    </StripeProvider>
  );
};

export default App;
