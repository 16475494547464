import { useContext, useState, useEffect } from 'react';
import { FilesystemContext } from '../Filesystem';
import { useFirestorePlayground } from './useFirestorePlayground';
import { FirestoreProjectFile } from '../declarations/FirestoreProjectFile';
import useTeamInfo from './useTeamInfo';
import { usePlaygroundMembership } from './usePlaygroundMembership';

export type FirebaseCursorData = {
  fileName: string;
  id: string;
  positionData?: { position: number; selectionEnd: number };
  color: string;
};

const usePlaygroundManager = (playgroundId: string, userId: string) => {
  const filesystem = useContext(FilesystemContext);

  const {
    files,
    dependencies,
    playgroundInfo,
    createFile,
    updateDependencies,
    updateFileName,
    updatePlaygroundName
  } = useFirestorePlayground(
    userId,
    playgroundId,
    filesystem ? filesystem.setDependencies : undefined
  );

  const team = useTeamInfo(playgroundInfo?.teamId || '');

  const { updateMemberAccess } = usePlaygroundMembership(
    userId,
    playgroundInfo,
    team
  );

  const [
    activeEditingFile,
    setActiveEditingFile
  ] = useState<FirestoreProjectFile | null>(null);

  useEffect(() => {
    if (files.length < 1 || !filesystem) {
      return;
    }
    setActiveEditingFile(prevActive => {
      if (prevActive) {
        return files.find(file => file.id === prevActive.id) || prevActive;
      } else {
        const sortedFiles = files.sort((l, r) => (l.name > r.name ? 1 : -1));
        return (
          sortedFiles.find(
            file => file.name.includes('.jsx') || file.name.includes('.tsx')
          ) || sortedFiles[0]
        );
      }
    });
  }, [files, filesystem]);

  return {
    // local states
    activeEditingFile,
    dependencies,
    files,
    playgroundInfo,
    team,
    // set local states
    setActiveEditingFile,
    // Update in Firestore
    createFile,
    updateDependencies,
    updateFileName,
    updateMemberAccess,
    updatePlaygroundName
  };
};

export { usePlaygroundManager };
