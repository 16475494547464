import { colors } from '../../../constants/colors';
import styled from 'styled-components';

import { ColorTypes } from '../../../constants/colors';

enum TypeScale {
  bodySmall = 'bodySmall',
  body = 'body',
  bodyXL = 'bodyXL',
  header = 'header',
  title = 'title',
  titleXL = 'titleXL',
  titleXXL = 'titleXXL'
}

type TextSizeProps = {
  fontSize: string;
  lineHeight: string;
  fontWeight: number;
};

const textSizes: {
  [key in TypeScale]: TextSizeProps;
} = {
  bodySmall: { fontSize: '14px', lineHeight: '18px', fontWeight: 400 },
  body: { fontSize: '16px', lineHeight: '24px', fontWeight: 400 },
  bodyXL: { fontSize: '18px', lineHeight: '28px', fontWeight: 400 },
  header: { fontSize: '30px', lineHeight: '36px', fontWeight: 700 },
  title: { fontSize: '72px', lineHeight: '80px', fontWeight: 500 },
  titleXL: { fontSize: '96px', lineHeight: '104px', fontWeight: 500 },
  titleXXL: { fontSize: '120px', lineHeight: '144px', fontWeight: 500 }
};

export type TextCursor = 'auto' | 'default' | 'pointer' | 'text';

type TextProps = {
  size?:
    | 'bodySmall'
    | 'body'
    | 'bodyXL'
    | 'header'
    | 'title'
    | 'titleXL'
    | 'titleXXL';
  fontWeight?: 400 | 500 | 600 | 700;
  textAlign?: 'left' | 'center' | 'right';
  color?: ColorTypes;
  margin?: string;
  capitalize?: boolean;
  userSelect?: 'auto' | 'text' | 'none' | 'all';
  cursor?: TextCursor;
  wrapText?: boolean;
  ellipsis?: boolean;
  flexShrink?: number;
  fancyFont?: boolean;
  maxWidth?: number;
};

export const Text = styled.p<TextProps>`
  font-size: ${props =>
    props.size ? textSizes[props.size].fontSize : textSizes.body.fontSize};
  line-height: ${props =>
    props.size ? textSizes[props.size].lineHeight : textSizes.body.lineHeight};
  font-weight: ${props =>
    props.fontWeight
      ? props.fontWeight
      : textSizes[props.size || 'body'].fontWeight};
  text-align: ${props => props.textAlign || 'left'};
  color: ${props => (props.color ? colors[props.color] : colors.base)};
  margin: ${props => props.margin || '0px'};
  user-select: ${props => props.userSelect || 'text'};
  white-space: ${props => (!props.wrapText ? 'nowrap' : 'normal')};
  overflow: ${props => (!props.wrapText ? 'hidden' : 'visible')};
  text-overflow: ${props => (props.ellipsis ? 'ellipsis' : 'default')};
  flex-shrink: ${props => props.flexShrink || 0};
  text-decoration: none;
  max-width: ${props => (props.maxWidth ? `${props.maxWidth}px` : undefined)};

  font-family: ${props =>
    props.fancyFont ? 'RoslindaleVariableDisplay' : 'inherit'};
  font-display: fallback;

  cursor: ${props => props.cursor || 'initial'};

  ::first-letter {
    text-transform: ${props => (props.capitalize ? 'uppercase' : 'default')};
  }

  @media (max-width: 560px) {
    font-size: ${props => getMobileTextSize(props.size).fontSize};
    line-height: ${props => getMobileTextSize(props.size).lineHeight};
  }
`;

const getMobileTextSize = (
  size?:
    | 'bodySmall'
    | 'body'
    | 'bodyXL'
    | 'header'
    | 'title'
    | 'titleXL'
    | 'titleXXL'
) => {
  switch (size) {
    case 'title':
      return { fontSize: '56px', lineHeight: '64px' };
    case 'titleXL':
      return { fontSize: '72px', lineHeight: '80px' };
    case 'titleXXL':
      return { fontSize: '88px', lineHeight: '96px' };
    default:
      return {
        fontSize: textSizes[size || 'body'].fontSize,
        lineHeight: textSizes[size || 'body'].lineHeight
      };
  }
};
