import { useEffect, useRef } from 'react';

import { editor } from 'monaco-editor';

import RealtimeManager from '../RealtimeManager';

import { handleCursorWidgets } from '../utilities/CursorWidgetUtility';

import { useCursors } from './useCursors';
import { UserPresenceInfo } from './usePresence';

const useCursorWidgets = (
  presences: Array<UserPresenceInfo>,
  realtimeManager: RealtimeManager | null,
  monacoEditor: editor.IStandaloneCodeEditor | null,
  currentUserId: string
) => {
  const cursors = useCursors(realtimeManager);

  const widgetTimeouts = useRef<Record<string, number | undefined>>({});
  const widgets = useRef<Record<string, editor.IContentWidget | undefined>>({});

  useEffect(() => {
    if (!monacoEditor) {
      return;
    }

    handleCursorWidgets({
      activeCursors: cursors,
      playgroundPresences: presences,
      widgets: widgets.current,
      widgetTimeouts: widgetTimeouts.current,
      currentUserId,
      editor: monacoEditor
    });
  }, [cursors, currentUserId, monacoEditor, presences]);

  useEffect(() => {
    const timeouts = widgetTimeouts.current;
    return () => {
      Object.values(timeouts).forEach(clearTimeout);
    };
  }, []);
};

export { useCursorWidgets };
