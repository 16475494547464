import React, { useRef } from 'react';
import styled from 'styled-components';
import { colors } from '../../constants/colors';

import { useOnClickOutside } from '../../hooks/useOnClickOutside';
import { useToggle } from '../../hooks/useToggle';
import {
  useKeyboardShortcut,
  KeyboardShortcutKey
} from '../../hooks/useKeyboardShortcut';

import { Text } from '../general/Text';
// import { DropdownIcon } from '../../icons/DropdownIcon';
import { Menu } from '../general/Menu';
import { SortingMethod } from '../../declarations/SortingMethod';

type HomeSectionHeaderProps = {
  sortingBy: string;
  setSortingBy: (value: SortingMethod) => void;
  isRecentsFolder: boolean;
};

export const HomeSectionHeader = (props: HomeSectionHeaderProps) => {
  const headerRef = useRef<HTMLDivElement | null>(null);

  const defaultSortingOption = props.isRecentsFolder
    ? SortingMethod.RecentlyViewed
    : SortingMethod.RecentlyModified;

  const {
    value: isMenuVisible,
    setFalse: hideMenu
    // toggle: toggleIsMenuVisible
  } = useToggle(false);

  useOnClickOutside(headerRef, hideMenu);
  useKeyboardShortcut(KeyboardShortcutKey.Escape, hideMenu);

  return (
    <styles.containerWrapper>
      <styles.container>
        <StyledHeaderWrapper ref={headerRef}>
          <StyledHeader
            size={'header'}
            // onMouseDown={toggleIsMenuVisible}
          >
            {props.sortingBy}
            {/* <StyledIconContainer isMenuVisible={isMenuVisible}>
              <DropdownIcon color={colors.inactive} />
            </StyledIconContainer> */}
          </StyledHeader>
          {isMenuVisible && (
            <Menu
              showCheckFor={props.sortingBy}
              top={40}
              x={-26}
              onHide={hideMenu}
              actions={[
                {
                  title: defaultSortingOption,
                  onClick: () => {
                    props.setSortingBy(defaultSortingOption);
                  }
                },
                {
                  title: SortingMethod.Alphabetical,
                  onClick: () => {
                    props.setSortingBy(SortingMethod.Alphabetical);
                  }
                },
                {
                  title: SortingMethod.DateCreated,
                  onClick: () => {
                    props.setSortingBy(SortingMethod.DateCreated);
                  }
                },
                {
                  title: SortingMethod.Deleted,
                  onClick: () => {
                    props.setSortingBy(SortingMethod.Deleted);
                  }
                }
              ]}
            />
          )}
        </StyledHeaderWrapper>
      </styles.container>
    </styles.containerWrapper>
  );
};

const styles = {
  container: styled.div`
    border-bottom: 1px solid ${colors.W8};
    padding-bottom: 16px;
  `,
  containerWrapper: styled.div`
    margin-top: 40px;
    background: ${colors.base};
    padding: 8px 18px 0px 18px;
    margin-left: -18px;
    margin-right: -18px;
    position: sticky;
    top: 120px;
    margin-bottom: 8px;
  `
};

const StyledHeaderWrapper = styled.div`
  display: inline-block;
`;

const StyledHeader = styled(Text)`
  display: inline-block;
  /* cursor: pointer; */
  user-select: none;
`;

type StyledIconContainerProps = {
  isMenuVisible: boolean;
};

// const StyledIconContainer = styled.span<StyledIconContainerProps>`
//   display: inline-block;
//   padding-left: 4px;
//   transition: transform 0.12s;
//   transform: ${props =>
//     props.isMenuVisible ? 'translateY(3px)' : 'translateY(0px)'};

//   ${StyledHeaderWrapper}:hover & {
//     transform: translateY(3px);
//   }
// `;
