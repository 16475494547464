import React from 'react';
import styled, { keyframes } from 'styled-components';
import { SpinnerIcon } from '../../icons/SpinnerIcon';

type LoadingSpinnerProps = {
  size?: number;
  background?: string;
};

export const LoadingSpinner = (props: LoadingSpinnerProps) => (
  <styles.base size={props.size} background={props.background}>
    <SpinnerIcon size={props.size} />
  </styles.base>
);

const LoadingAnimation = keyframes`
  from {
    transform: rotate(0deg)
  }

  to {
    transform: rotate(360deg)
  }
`;

const styles = {
  base: styled.div<LoadingSpinnerProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${props => (props.size ? `${props.size}px` : '30px')};
    height: ${props => (props.size ? `${props.size}px` : '30px')};
    background: ${props => (props.background ? props.background : undefined)};
    animation: ${LoadingAnimation} 0.6s linear infinite;
    border-radius: 50%;
    flex-shrink: 0;
  `
};
