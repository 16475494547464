import React from 'react';
import styled from 'styled-components';

import { Text } from '../../general/Text';
import { TooltipArrowIcon } from '../../../icons/TooltipArrowIcon';
import { Animations } from '../../../constants/animations';

type PlaygroundFacepileDropdownProps = {
  color: string;
  name: string;
  isCurrentUser: boolean;
};

export const PlaygroundFacepileDropdown = (
  props: PlaygroundFacepileDropdownProps
) => (
  <styles.tooltip color={props.color}>
    <styles.iconContainer>
      <TooltipArrowIcon color={props.color} />
    </styles.iconContainer>

    <Text
      size={'bodySmall'}
      fontWeight={600}
      textAlign={'center'}
      color={'white'}
    >
      {props.name} {props.isCurrentUser && '(You)'}
    </Text>
  </styles.tooltip>
);

const styles = {
  tooltip: styled.div<Pick<PlaygroundFacepileDropdownProps, 'color'>>`
    position: absolute;
    display: flex;
    align-items: center;
    flex-direction: column;
    top: 54px;
    z-index: 21;
    background: ${props => props.color || 'grey'};
    padding: 8px 12px;
    border-radius: 8px;
    box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.16);
    ${Animations.FadeIn}
  `,
  iconContainer: styled.div`
    position: absolute;
    top: -14px;
    width: 24px;
    height: 10px;
  `
};
