import React from 'react';

import { colors } from '../constants/colors';
import { IconProps } from './IconProps';

export const RefreshIcon = ({ margin, color, size }: IconProps) => (
  <svg
    style={{ margin, flexShrink: 0 }}
    width={size || 24}
    height={size || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.8159 10.5698C12 10.5698 12.146 10.5127 12.2603 10.3921L14.8628 7.77051C15.0024 7.63086 15.0659 7.47852 15.0659 7.30078C15.0659 7.12939 14.9961 6.96436 14.8628 6.8374L12.2603 4.19043C12.146 4.06348 12 4 11.8159 4C11.4731 4 11.2002 4.28564 11.2002 4.63477C11.2002 4.80615 11.2637 4.9458 11.3716 5.06641L12.8887 6.55176C12.5903 6.50732 12.2856 6.48193 11.981 6.48193C8.83887 6.48193 6.3252 8.99561 6.3252 12.144C6.3252 15.2925 8.85791 17.8252 12 17.8252C15.1484 17.8252 17.6748 15.2925 17.6748 12.144C17.6748 11.7632 17.4082 11.4902 17.0273 11.4902C16.6592 11.4902 16.4116 11.7632 16.4116 12.144C16.4116 14.5942 14.4502 16.562 12 16.562C9.55615 16.562 7.58838 14.5942 7.58838 12.144C7.58838 9.6748 9.53711 7.71973 11.981 7.71973C12.3936 7.71973 12.7744 7.75146 13.1108 7.80859L11.3779 9.52246C11.2637 9.63672 11.2002 9.77637 11.2002 9.94775C11.2002 10.2969 11.4731 10.5698 11.8159 10.5698Z"
      fill={color || colors.primaryText}
    />
  </svg>
);
