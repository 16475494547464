import { PlaygroundInfo } from '../declarations/PlaygroundInfo';
import { useContext, useCallback } from 'react';
import { FirebaseContext } from '../Firebase';

import {
  RECENTS_FOLDER_ID,
  DRAFTS_FOLDER_ID,
  INTERVIEWS_FOLDER_ID
} from '../constants/keys';

import { FirestorePlaygroundFile } from '../declarations/FirestorePlaygroundFile';
import { PlaygroundPermissions } from '../declarations/PlaygroundPermissions';
import { BackendPlaygroundInfo } from '../declarations/BackendPlaygroundInfo';
import { FileType } from '../declarations/FileType';

import { createNewPlaygroundOrTemplate } from '../utilities/PlaygroundCreationUtilities';

export const usePlaygroundCreationUtility = (
  user: firebase.User,
  activeTeamId?: string,
  activeFolderId?: string,
  isPersonal?: boolean,
  subscriptionId?: string
) => {
  const firebase = useContext(FirebaseContext);

  //TODO: no firebase default

  /**
   *
   * Base method to create a new Playground or Template
   *
   */

  const createNew = async (
    template: {
      info: BackendPlaygroundInfo;
      files: Array<{
        name: string;
        data: { history?: any; checkpoint?: any };
      }>;
    },
    type: FileType
  ) => {
    if (!firebase || !activeTeamId || !activeFolderId) {
      return;
    }

    const isInterview = activeFolderId === INTERVIEWS_FOLDER_ID;
    if (isInterview && (await teamHasReachedInterviewLimit())) {
      return;
    }

    const firestore = firebase.firestore();
    const database = firebase.database();

    const playgroundCollectionRef = firestore.collection('playgrounds');
    const playgroundRealtimeRef = database.ref().child('playgrounds');

    template.info.teamId = activeTeamId;
    template.info.folderId =
      activeFolderId === RECENTS_FOLDER_ID ? DRAFTS_FOLDER_ID : activeFolderId;
    template.info.type = type;
    template.info.isInterview = isInterview;
    if (isPersonal) {
      template.info.isPersonal = true;
    }

    return createNewPlaygroundOrTemplate({
      playgroundCollectionRef,
      playgroundRealtimeRef,
      timestamp: firebase.app().firestore.Timestamp.now(),
      template
    });
  };

  /**
   *
   * Copy the files from a Playground or Template and return new references
   *
   */

  const copyPlaygroundFiles = async (source: PlaygroundInfo) => {
    if (!firebase || !activeTeamId || !activeTeamId) {
      return;
    }

    const firestore = firebase.firestore();
    const database = firebase.database();

    const sourceFilesRef = firestore.collection(
      `playgrounds/${source.id}/files`
    );

    const files = await sourceFilesRef.get();

    // TODO: Delete playground
    // if (activeFolder.id !== RECENTS_FOLDER_ID) {
    //   return files.docs.map(doc => doc.data() as FirestorePlaygroundFile);
    // }

    const newFiles = await Promise.all(
      files.docs.map(async doc => {
        const {
          name,
          realtimeDatabaseId
        }: FirestorePlaygroundFile = doc.data() as FirestorePlaygroundFile;

        const firepadFileRef = database.ref(
          `playgrounds/${source.id}/files/${realtimeDatabaseId}`
        );

        const fileSnapshot = await firepadFileRef.once('value');

        const fileData = fileSnapshot.val();

        if (!fileData) {
          return {
            name,
            data: {}
          };
        }

        const { checkpoint, history } = fileData;

        return {
          name,
          data: { checkpoint: checkpoint || {}, history: history || {} }
        };
      })
    );

    return newFiles;
  };

  /**
   *
   * Create a new Base Playground
   *
   */

  const getNewBaseTemplate = async (): Promise<
    | {
        info: BackendPlaygroundInfo;
        files: Array<{
          name: string;
          data: { history?: any; checkpoint?: any };
        }>;
      }
    | undefined
  > => {
    if (!firebase || !activeTeamId || !activeFolderId) {
      return;
    }

    const fileData = {
      history: {
        A0: {
          a: '',
          o: {
            0: "import React from 'react';\n\nexport const Card = () => {\n  return (\n    <div style={{ padding: 40, backgroundColor: 'ghostwhite' }}>\n      Start building\n    </div>\n  );\n};\n"
          },
          t: firebase.app().database.ServerValue.TIMESTAMP
        }
      }
    };

    return {
      info: {
        name: 'Playground',
        dependencies: {},
        folderId: activeFolderId,
        teamId: activeTeamId,
        type: FileType.Playground,
        members: { [user.uid]: { permissions: PlaygroundPermissions.Owner } },
        defaultPermissions: PlaygroundPermissions.View
      },
      files: [
        {
          name: 'App.jsx',
          data: fileData
        }
      ]
    };
  };

  /**
   *
   * Create a playground, optionally supplying a template
   *
   */

  const createNewPlayground = async (customTemplate?: PlaygroundInfo) => {
    if (user.isAnonymous) {
      return;
    }

    let templateWithFiles:
      | {
          info: BackendPlaygroundInfo;
          files: Array<{
            name: string;
            data: { history?: any; checkpoint?: any };
          }>;
        }
      | undefined;

    if (customTemplate) {
      const files = await copyPlaygroundFiles(customTemplate);

      if (!files) {
        // TODO: log "cant create from template with no files"
        return;
      }

      templateWithFiles = {
        info: {
          ...customTemplate,
          members: { [user.uid]: { permissions: PlaygroundPermissions.Owner } }
        },
        files: files
      };
    } else {
      templateWithFiles = await getNewBaseTemplate();
    }

    if (!templateWithFiles) {
      return;
    }

    return createNew(templateWithFiles, FileType.Playground);
  };

  /**
   *
   * Transform and copy/replace a Playground as a Template
   *
   */

  const createNewTemplate = async (playground: PlaygroundInfo) => {
    if (!playground || !firebase || !activeFolderId) {
      return;
    }

    const files = await copyPlaygroundFiles(playground);

    if (!files) {
      return;
    }

    await createNew(
      {
        info: {
          dependencies: {},
          ...playground,
          type: FileType.Template,
          members: { [user.uid]: { permissions: PlaygroundPermissions.Owner } }
        },
        files
      },
      FileType.Template
    );

    // TODO: Delete playground
    // if (activeFolder.id !== RECENTS_FOLDER_ID) {
    //   firebase.firestore().doc(`folders/${activeFolder.id}/`)
    // }
  };

  const teamHasReachedInterviewLimit = useCallback(async () => {
    if (subscriptionId) {
      return false;
    }

    if (!firebase || !activeTeamId) {
      return true;
    }

    const interviewLimitResult = await firebase
      .functions()
      .hasTeamReachedInterviewLimit({ teamId: activeTeamId });

    return !interviewLimitResult.data.hasRemainingInterviews;
  }, [firebase, subscriptionId, activeTeamId]);

  return { createNewPlayground, createNewTemplate };
};
