export enum PlaygroundPermissions {
  Edit = 'PERMISSIONS_EDIT',
  View = 'PERMISSIONS_VIEW',
  Owner = 'PERMISSIONS_OWNER'
}

export type FrontEndPlaygroundPermissions = 'owner' | 'can edit' | 'can view';

export const PLAYGROUND_PERMISSIONS_TITLES: Record<
  PlaygroundPermissions,
  FrontEndPlaygroundPermissions
> = {
  [PlaygroundPermissions.Edit]: 'can edit',
  [PlaygroundPermissions.View]: 'can view',
  [PlaygroundPermissions.Owner]: 'owner'
};
