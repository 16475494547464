import { useContext, useState, useEffect } from 'react';
import { FirebaseContext } from '../Firebase';
import analytics from '../Analytics/Analytics';

const useUserSubscription = () => {
  const firebase = useContext(FirebaseContext);

  const [user, setUser] = useState<firebase.User | null | undefined>();
  const [isAnonymous, setIsAnonymous] = useState<boolean>(true);

  useEffect(() => {
    if (!firebase) {
      return;
    }

    const unsubscribeFromTokenChanges = firebase
      .auth()
      .onIdTokenChanged(user => {
        setUser(user);
        setIsAnonymous(!!user?.isAnonymous);
        if (user) {
          analytics.identify('userId', user.uid);
          analytics.identify('isAnonymous', user.isAnonymous);
        }
      });

    return () => {
      unsubscribeFromTokenChanges();
    };
  }, [firebase]);

  useEffect(() => {
    if (user !== null || !firebase) {
      return;
    }

    let isUnmounted = false;
    const signInAnonymously = async () => {
      const userCredential = await firebase.auth().signInAnonymously();
      !isUnmounted && setUser(userCredential.user);

      if (userCredential.additionalUserInfo?.isNewUser) {
        analytics.track('sign_up', {
          method: 'anonymous'
        });
      } else {
        analytics.track('login', {
          method: 'anonymous'
        });
      }

      analytics.identify('method', 'anonymous');
      if (userCredential.user?.uid) {
        analytics.identify('userId', userCredential.user?.uid);
      }
    };

    signInAnonymously();

    return () => {
      isUnmounted = true;
    };
  }, [user, firebase]);

  return { user, isAnonymous };
};

export { useUserSubscription };
