import RealtimeManager, { RealtimeManagerEvent } from '../RealtimeManager';
import { useState, useEffect, useMemo } from 'react';

const useCursors = (realtimeManager: RealtimeManager | null) => {
  const [cursors, setCursors] = useState<
    Record<string, { userId: string; position?: number }>
  >({});

  const cursorArray = useMemo(() => {
    return Object.values(cursors);
  }, [cursors]);

  useEffect(() => {
    if (!realtimeManager) {
      return;
    }

    const handleCursor = (userId: string, cursor?: { position: number }) => {
      setCursors(prevCursors => {
        let newCursors = { ...prevCursors };

        if (cursor?.position) {
          newCursors[userId] = { userId, position: cursor?.position };
        } else {
          const { [userId]: cursorToDelete, ...restCursors } = newCursors;
          newCursors = restCursors;
        }

        return newCursors;
      });
    };

    realtimeManager.on(RealtimeManagerEvent.cursor, handleCursor);

    return () => {
      realtimeManager.off(RealtimeManagerEvent.cursor, handleCursor);
    };
  }, [realtimeManager]);

  return cursorArray;
};

export { useCursors };
