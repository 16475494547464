import React from 'react';

import { useToggle } from './hooks/useToggle';

import { SignUpModal } from './marketing/components/sign-up/SignUpModal';
import { MarketingLandingHeader } from './marketing/components/landing/MarketingLandingHeader';
import { MarketingLandingHero } from './marketing/components/landing/MarketingLandingHero';
import { MarketingLandingFooter } from './marketing/components/landing/MarketingLandingFooter';
import { MarketingLandingBasicCard } from './marketing/components/landing/MarketingLandingBasicCard';
import { PageContainer } from './marketing/components/general/PageContainer';
import { useMarketingBodyStyle } from './hooks/useMarketingBodyStyle';

const MarketingFeatures = () => {
  useMarketingBodyStyle();

  const {
    value: isSignUpModalVisible,
    setTrue: showSignUpModal,
    setFalse: hideSignUpModal
  } = useToggle(false);

  return (
    <PageContainer>
      {isSignUpModalVisible && <SignUpModal onClose={hideSignUpModal} />}
      <MarketingLandingHeader onSignUp={showSignUpModal} />
      <MarketingLandingHero
        showSignUp
        onSignUp={showSignUpModal}
        title={'How sharing code should be'}
        description={
          'Variable is the best way to get started on a new React project and share with others.'
        }
      />
      <MarketingLandingBasicCard
        title={'Real-time editing'}
        description={
          'Work in the same playground at the same time with as many teammates as you want.'
        }
        marginTop={240}
        image={
          'https://www.dropbox.com/s/x1vahak7f9w3hkq/Real%20Time%20Editing.png?raw=1'
        }
      />
      <MarketingLandingBasicCard
        title={'Templates'}
        description={
          'Save all your favorite dependencies, components, and utilities in a template.'
        }
        image={'https://www.dropbox.com/s/hllr69obqtkjwrl/Templates.png?raw=1'}
      />
      <MarketingLandingBasicCard
        title={'Live preview'}
        description={
          'See all of your changes instantly next to the code, just how it should be.'
        }
        video={
          'https://www.dropbox.com/s/67osqe9gnh8u79s/LivePreview.mp4?raw=1'
        }
      />

      <MarketingLandingBasicCard
        title={'Dependencies'}
        description={'Enough said.'}
        image={
          'https://www.dropbox.com/s/0jv66h8yexv5l4s/Dependencies.png?raw=1'
        }
      />
      <MarketingLandingBasicCard
        title={'Visual Studio Code & Prettier'}
        description={
          'Everything you love about Visual Studio Code is baked right in. We automatically save everything, but press ⌘S to format your code.'
        }
        image={
          'https://www.dropbox.com/s/t5gq1lyr2si4itv/VScode%20%26%20Prettier.png?raw=1'
        }
      />
      <MarketingLandingBasicCard
        title={'Organize your playgrounds'}
        description={
          'Create folders to organize your playgrounds, keeping everything easy to find.'
        }
        image={'https://www.dropbox.com/s/4snnlwgo13nptur/Folders.png?raw=1'}
      />
      <MarketingLandingBasicCard
        title={'GitHub integration'}
        description={'Sign up using your GitHub account. More coming soon.'}
        image={
          'https://www.dropbox.com/s/m3zmvnfnrpib1jk/GitHub%20Integration.png?raw=1'
        }
      />
      <MarketingLandingFooter showSignUpForm />
    </PageContainer>
  );
};

export default MarketingFeatures;
