import { colors } from '../../constants/colors';
import styled from 'styled-components';

type InputProps = {
  margin?: string;
  width?: number;
};

export const Input = styled.input<InputProps>`
  box-sizing: border-box;
  color: ${colors.primaryText};
  border-radius: 10px;
  background: none;
  border: 1px solid ${colors.W12};
  padding: 11px 16px;
  font-size: 14px;
  line-height: 24px;
  outline: none;

  width: ${props => props.width || '100%'};
  margin: ${props => props.margin || 0};

  ::placeholder {
    color: ${colors.inactive};
  }

  :focus {
    border: 1px solid ${colors.blueberry};
  }
`;
