import React from 'react';
import { colors } from '../constants/colors';
import { IconProps } from './IconProps';

export const TooltipArrowIcon = ({ margin, color }: IconProps) => (
  <svg
    style={{ margin }}
    width="24"
    height="10"
    viewBox="0 0 24 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.32052 8H0V10H24V8H21.6795C19.3441 8 17.1253 6.9795 15.6054 5.20633L13.1389 2.3287C12.5402 1.63028 11.4598 1.63028 10.8611 2.3287L8.39457 5.20633C6.87472 6.9795 4.65592 8 2.32052 8Z"
      fill={color || colors.primaryText}
    />
  </svg>
);
