import app from 'firebase/app';

import 'firebase/firestore';
import 'firebase/database';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/analytics';
import 'firebase/functions';

var firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
};

class Firebase {
  private _app: typeof app;
  private _firestore: firebase.firestore.Firestore;
  private _database: firebase.database.Database;
  private _auth: firebase.auth.Auth;
  private _storage: firebase.storage.Storage;
  private _analytics: firebase.analytics.Analytics;
  private _functions: firebase.functions.Functions;

  constructor() {
    app.initializeApp(firebaseConfig);

    this._app = app;
    this._firestore = app.firestore();
    this._database = app.database();
    this._auth = app.auth();
    this._storage = app.storage();
    this._analytics = app.analytics();
    this._functions = app.functions();
  }

  app = () => this._app;
  firestore = () => this._firestore;
  database = () => this._database;
  auth = () => this._auth;
  storage = () => this._storage;
  analytics = () => this._analytics;
  functions = () => ({
    ...this._functions,
    onInviteToTeam: this._functions.httpsCallable('onInviteToTeam'),
    onInviteToPlayground: this._functions.httpsCallable('onInviteToPlayground'),
    onDowngradeTeamMemberPermissions: this._functions.httpsCallable(
      'onDowngradeTeamMemberPermissions'
    ),
    onUpgradeTeamMemberPermissions: this._functions.httpsCallable(
      'onUpgradeTeamMemberPermissions'
    ),
    onCreateUser: this._functions.httpsCallable('onCreateUser'),
    onAddPaymentMethod: this._functions.httpsCallable('onAddPaymentMethod'),
    onNewSubscription: this._functions.httpsCallable('onNewSubscription'),
    hasTeamReachedInterviewLimit: this._functions.httpsCallable(
      'hasTeamReachedInterviewLimit'
    )
  });
}

export default Firebase;
