import React from 'react';

import { useToggle } from './hooks/useToggle';

import { SignUpModal } from './marketing/components/sign-up/SignUpModal';
import { MarketingLandingHeader } from './marketing/components/landing/MarketingLandingHeader';
import { MarketingLandingHero } from './marketing/components/landing/MarketingLandingHero';
import { MarketingLandingFooter } from './marketing/components/landing/MarketingLandingFooter';
import { MarketingLandingPricingCards } from './marketing/components/landing/MarketingLandingPricingCards';
import { PageContainer } from './marketing/components/general/PageContainer';
import { useMarketingBodyStyle } from './hooks/useMarketingBodyStyle';

const MarketingPricing = () => {
  useMarketingBodyStyle();

  const {
    value: isSignUpModalVisible,
    setTrue: showSignUpModal,
    setFalse: hideSignUpModal
  } = useToggle(false);

  return (
    <PageContainer>
      {isSignUpModalVisible && <SignUpModal onClose={hideSignUpModal} />}
      <MarketingLandingHeader onSignUp={showSignUpModal} />
      <MarketingLandingHero title={'Pricing'} />
      <MarketingLandingPricingCards onSignUp={showSignUpModal} />
      <MarketingLandingFooter showSignUpForm />
    </PageContainer>
  );
};

export default MarketingPricing;
