import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { Text } from '../general/Text';
import { Button } from '../general/Button';

type MarketingLandingBasicCardProps = {
  subheading?: string;
  title: string;
  description: string;
  image?: string;
  video?: string;
  flip?: boolean;
  action?: { title: string; linkUrl: string };
  marginTop?: number;
};

export const MarketingLandingBasicCard = (
  props: MarketingLandingBasicCardProps
) => {
  return (
    <styles.container marginTop={props.marginTop} flip={props.flip}>
      <styles.textSection flip={props.flip}>
        {props.subheading && (
          <Text margin={'16px 0px 24px 0px'} fontWeight={600}>
            {props.subheading}
          </Text>
        )}
        <Text wrapText fancyFont size={'title'} margin={'8px 0px 0px 0px'}>
          {props.title}
        </Text>
        <Text wrapText margin={'24px 0px 0px 0px'}>
          {props.description}
        </Text>
        {props.action && (
          <styles.link to={`/${props.action.linkUrl}`}>
            <Button XL primary>
              {props.action.title}
            </Button>
          </styles.link>
        )}
      </styles.textSection>
      <styles.imageSection>
        {props.image && (
          <styles.templateCardsImage
            draggable={false}
            alt={'Template cards'}
            src={props.image}
          />
        )}
        {props.video && (
          <styles.video playsInline webkit-playsInline autoPlay loop muted>
            <source src={props.video} type="video/mp4" />
          </styles.video>
        )}
      </styles.imageSection>
    </styles.container>
  );
};

type StyledContainerProps = {
  flip: boolean | undefined;
  marginTop?: number;
};

const styles = {
  container: styled.div<StyledContainerProps>`
    display: flex;
    margin-top: ${props =>
      props.marginTop ? `${props.marginTop}px` : '400px'};
    align-items: center;
    flex-direction: ${props => (props.flip ? 'row-reverse' : 'row')};

    @media only screen and (max-width: 800px) {
      flex-direction: column-reverse;
      margin-top: 240px;
      align-items: flex-start;
    }
  `,
  templateCardsImage: styled.img`
    width: 100%;
    max-width: 600px;
    user-select: none;
    position: absolute;

    @media only screen and (max-width: 800px) {
      max-width: none;
    }
  `,
  textSection: styled.div<StyledContainerProps>`
    padding: ${props => (props.flip ? '0% 0% 0% 8%' : '0% 8% 0% 0%')};
    width: 50%;

    @media only screen and (max-width: 800px) {
      padding: 0%;
      width: 100%;
    }
  `,
  imageSection: styled.div`
    min-width: 50%;
    border-radius: 12px;
    overflow: hidden;
    position: relative;

    display: block;
    background: #111111;
    padding-bottom: 50%;

    @media only screen and (max-width: 800px) {
      margin-bottom: 32px;
      width: 100%;
      padding-bottom: 100%;
    }
  `,
  video: styled.video`
    width: 100%;
    max-width: 600px;
    user-select: none;
    position: absolute;
    border-radius: 12px;

    @media only screen and (max-width: 800px) {
      max-width: none;
    }
  `,
  link: styled(Link)`
    text-decoration: none;
    display: inline-block;
    margin-top: 32px;
    border-radius: 80px;
  `
};
