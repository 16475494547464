import React from 'react';

import { Modal } from '../general/Modal';
import { SignUpForm } from './SignUpForm';
import { useLockBodyScroll } from '../../../hooks/useLockBodyScroll';

type SignUpModalProps = {
  onClose: () => void;
  stayOnPage?: boolean;
};

export const SignUpModal = (props: SignUpModalProps) => {
  useLockBodyScroll();
  return (
    <Modal onClose={props.onClose}>
      <SignUpForm
        autoFocus
        onSuccess={props.onClose}
        stayOnPage={props.stayOnPage}
      />
    </Modal>
  );
};
