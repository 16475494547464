import React from 'react';
import styled from 'styled-components';

import { colors } from '../../constants/colors';
import { Button } from '../general/Button';
import { Text } from '../general/Text';
import { device } from '../../constants/deviceBreakpoints';

type PlaygroundSignUpBarProps = {
  isCodeActiveMobileView: boolean;
  onSignUp: () => void;
};

export const PlaygroundSignUpBar = (props: PlaygroundSignUpBarProps) => {
  return (
    <styles.container isCodeActiveMobileView={props.isCodeActiveMobileView}>
      <styles.bar>
        <Text color={'white'} fontWeight={500} margin={'0px 16px 0px 0px'}>
          Create an account to edit
        </Text>
        <styles.SignUpButton primary onClick={props.onSignUp}>
          Sign Up
        </styles.SignUpButton>
      </styles.bar>
    </styles.container>
  );
};

const styles = {
  container: styled.div<
    Pick<PlaygroundSignUpBarProps, 'isCodeActiveMobileView'>
  >`
    position: fixed;
    left: 0px;
    bottom: 24px;
    display: flex;
    justify-content: center;
    z-index: 40;
    width: 50%;
    user-select: none;
    pointer-events: none;

    ${device.mobile} {
      width: 100%;
      display: ${props => (props.isCodeActiveMobileView ? 'flex' : 'none')};
    }
  `,
  bar: styled.div`
    display: flex;
    background: ${colors.blueberry};
    padding: 8px 8px 8px 20px;
    border-radius: 100px;
    align-items: center;
    justify-self: center;
    flex-shrink: 0;
  `,
  SignUpButton: styled(Button)`
    background: rgba(255, 255, 255, 0.16);
    pointer-events: auto;

    :hover {
      background: rgba(255, 255, 255, 0.24);
    }

    :hover:active {
      background: rgba(255, 255, 255, 0.32);
    }
  `
};
