import React from 'react';
import { colors } from '../constants/colors';
import { IconProps } from './IconProps';

export const HamburgerIcon = ({ margin, color, size }: IconProps) => (
  <svg
    style={{ margin }}
    width={size || 32}
    height={size || 32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 16H24"
      stroke={color || colors.primaryText}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M8 22H24"
      stroke={color || colors.primaryText}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M8 10H24"
      stroke={color || colors.primaryText}
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);
