import { useContext, useCallback } from 'react';
import { FirebaseContext } from '../Firebase';

const useUserUtils = () => {
  const firebase = useContext(FirebaseContext);

  const signOut = useCallback(() => {
    if (!firebase) {
      return;
    }

    const auth = firebase.auth();

    return auth.signOut();
  }, [firebase]);

  return { signOut };
};

export { useUserUtils };
