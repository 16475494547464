import { Team } from '../declarations/Team';

const transformToTeam = async (
  doc: firebase.firestore.DocumentSnapshot,
  usersRef: firebase.firestore.CollectionReference
): Promise<Team> => {
  const data = doc.data();

  let transformedMembers: Team['members'] = [];
  if (data?.members) {
    transformedMembers = await Promise.all(
      Object.keys(data.members).map(async memberId => {
        const user = await usersRef.doc(memberId).get();

        const userData = user.data();

        return {
          id: memberId,
          name: userData?.name || '',
          email: userData?.email || '',
          avatarUrl: userData?.avatarUrl,
          permissions: data.members[memberId]?.permissions
        };
      })
    );
  }
  let transformedEditors: Team['editors'] = [];
  if (data?.editors) {
    Object.keys(data.editors).forEach(editorId => {
      const editorPlaygrounds = data.editors[editorId];
      const editorPlaygroundIds = Object.keys(editorPlaygrounds);

      if (editorPlaygroundIds && editorPlaygroundIds.length > 0) {
        transformedEditors.push({
          id: editorId,
          playgrounds: editorPlaygroundIds
        });
      }
    });
  }

  return {
    id: doc.id,
    name: data?.name || '',
    members: transformedMembers || [],
    editors: transformedEditors || [],
    attributes: data?.attributes,
    subscriptionId: data?.subscriptionId,
    folderCollectionRef: doc.ref.collection('folders'),
    avatarUrl: data?.avatarUrl
  };
};

export default transformToTeam;
