import { utils } from '.';

export default class TextOp {
  type;
  chars;
  text;
  attributes;

  constructor(type, text?, attributes?) {
    this.type = type;
    this.chars = null;
    this.text = null;
    this.attributes = null;

    if (type === 'insert') {
      this.text = text;
      utils.assert(typeof this.text === 'string');
      this.attributes = attributes || {};
      utils.assert(typeof this.attributes === 'object');
    } else if (type === 'delete') {
      this.chars = text;
      utils.assert(typeof this.chars === 'number');
    } else if (type === 'retain') {
      this.chars = text;
      utils.assert(typeof this.chars === 'number');
      this.attributes = attributes || {};
      utils.assert(typeof this.attributes === 'object');
    }
  }

  isInsert = () => {
    return this.type === 'insert';
  };
  isDelete = () => {
    return this.type === 'delete';
  };
  isRetain = () => {
    return this.type === 'retain';
  };

  equals = (other) => {
    return (
      this.type === other.type &&
      this.text === other.text &&
      this.chars === other.chars &&
      this.attributesEqual(other.attributes)
    );
  };

  attributesEqual = (otherAttributes) => {
    for (var attr in this.attributes) {
      if (this.attributes[attr] !== otherAttributes[attr]) {
        return false;
      }
    }

    for (attr in otherAttributes) {
      if (this.attributes[attr] !== otherAttributes[attr]) {
        return false;
      }
    }

    return true;
  };

  hasEmptyAttributes = () => {
    var empty = true;
    for (var attr in this.attributes) {
      empty = false;
      break;
    }

    return empty;
  };
}
