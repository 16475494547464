export const assert = (b, msg?: string) => {
  if (!b) {
    throw new Error(msg || 'assertion error');
  }
};

export const on = (emitter, type, f, capture?) => {
  if (emitter.addEventListener) {
    emitter.addEventListener(type, f, capture || false);
  } else if (emitter.attachEvent) {
    emitter.attachEvent('on' + type, f);
  }
};

export const preventDefault = (e) => {
  if (e.preventDefault) {
    e.preventDefault();
  } else {
    e.returnValue = false;
  }
};

export const stopPropagation = (e) => {
  if (e.stopPropagation) {
    e.stopPropagation();
  } else {
    e.cancelBubble = true;
  }
};

export const stopEvent = (e) => {
  preventDefault(e);
  stopPropagation(e);
};
