import React, { useState } from 'react';
import styled from 'styled-components';

import { colors } from '../../constants/colors';
import { Text } from '../general/Text';
import { TemplateIcon } from '../../icons/TemplateIcon';
import { Menu } from '../general/Menu';
import { useToggle } from '../../hooks/useToggle';
import { PlaygroundInfo } from '../../declarations/PlaygroundInfo';

type HomeTemplateCardProps = {
  index: number;
  template: PlaygroundInfo;
  onCreateNewFromTemplate?: (template: PlaygroundInfo) => void;
  onEditTemplate: (template: PlaygroundInfo) => void;
  onDeleteTemplate: (template: PlaygroundInfo) => void;
};

type StyledContainerProps = {
  index: number;
  isContextMenuVisible: boolean;
};

const bgColors = [
  '#80f',
  '#70f',
  '#60f',
  '#50f',
  '#40f',
  '#50f',
  '#60f',
  '#70f'
];

export const HomeTemplateCard = (props: HomeTemplateCardProps) => {
  const {
    value: isContextMenuVisible,
    setTrue: showContextMenu,
    setFalse: hideContextMenu
  } = useToggle(false);
  const [coords, setCoords] = useState({ x: 0, y: 0 });

  const handleRightClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    event.preventDefault();
    event.stopPropagation();
    setCoords({ x: event.pageX, y: event.pageY });
    showContextMenu();
  };

  const handleOnClick = () => {
    props.onCreateNewFromTemplate &&
      props.onCreateNewFromTemplate(props.template);
  };

  const handleEditTemplate = () => {
    props.onEditTemplate(props.template);
  };

  const handleDeleteTemplate = () => {
    props.onDeleteTemplate(props.template);
  };

  return (
    <div>
      {isContextMenuVisible && (
        <Menu
          top={coords.y}
          left={coords.x}
          onHide={hideContextMenu}
          actions={[
            {
              title: 'Edit template',
              onClick: handleEditTemplate
            },
            { title: 'divider' },
            {
              title: 'Delete',
              onClick: handleDeleteTemplate,
              isDestructive: true
            }
          ]}
        />
      )}
      <styles.container
        index={props.index % bgColors.length}
        isContextMenuVisible={isContextMenuVisible}
        onClick={handleOnClick}
        onContextMenu={handleRightClick}
      >
        <TemplateIcon color={colors.white} />
        <Text
          ellipsis
          color={'white'}
          cursor={'pointer'}
          fontWeight={700}
          margin={'6px 0px 0px 0px'}
        >
          {props.template.name}
        </Text>
        <Text cursor={'pointer'} size={'label'} color={'secondaryText'}>
          Template
        </Text>
      </styles.container>
    </div>
  );
};

const styles = {
  container: styled.div<StyledContainerProps>`
    height: 112px;
    width: 160px;
    box-sizing: border-box;
    margin-top: 16px;
    margin-right: 16px;
    padding: 20px 18px 0px 18px;
    border-radius: 14px;
    transition: transform 0.32s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    cursor: pointer;
    user-select: none;
    flex-shrink: 0;

    background: ${props => bgColors[props.index] || 'grey'};
    transform: ${props =>
      props.isContextMenuVisible ? 'translateY(-4px)' : 'translateY(0px)'};

    :hover {
      transform: translateY(-4px);
    }

    :active {
      transform: translateY(0px);
    }
  `
};
