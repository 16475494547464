import { colors } from '../../../constants/colors';
import styled from 'styled-components';

type ButtonProps = {
  margin?: string;
  primary?: boolean;
  inactive?: boolean;
  XL?: boolean;
  fullWidth?: boolean;
  borderRadius?: number;
};

export const Button = styled.button<ButtonProps>`
  display: flex;
  justify-content: center;
  font-size: ${props => (props.XL ? '16px' : '14px')};
  line-height: ${props => (props.XL ? '24px' : '16px')};
  font-weight: 700;
  color: ${props => (props.primary ? colors.white : colors.base)};

  border-radius: ${props =>
    props.borderRadius ? `${props.borderRadius}px` : '100px'};
  border: 3px solid ${colors.base};
  outline: none;
  background: ${props => (props.primary ? colors.base : colors.white)};

  padding: ${props => (props.XL ? '9px 20px' : '6px 12px')};
  margin: ${props => props.margin || 0};

  user-select: none;
  transition: all 0.16s;
  pointer-events: ${props => (props.inactive ? 'none' : 'default')};
  filter: ${props => (props.inactive ? 'saturate(0%)' : null)};
  background: ${props => (props.inactive ? colors.W8 : null)};
  opacity: ${props => (props.inactive ? 0.5 : 1)};
  cursor: pointer;

  width: ${props => (props.fullWidth ? '100%' : 'auto')};
  flex-shrink: 0;

  :hover {
    background: ${props => (props.primary ? '#000' : colors.W8)};
    border: 3px solid #000;
  }

  :hover:active {
    background: ${props => (props.primary ? colors.base : colors.W4)};
  }
`;
