import React from 'react';
import { colors } from '../constants/colors';
import { IconProps } from './IconProps';

export const GitHubIcon = ({ margin, color, size }: IconProps) => (
  <svg
    style={{ margin }}
    width={size || 24}
    height={size || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.9989 0.331055C5.37327 0.331055 0 5.7036 0 12.3314C0 17.6332 3.43804 22.1306 8.20651 23.7174C8.8069 23.8279 9.02569 23.4574 9.02569 23.1391C9.02569 22.8548 9.01538 22.0997 9.00948 21.0985C5.67163 21.8234 4.96737 19.4897 4.96737 19.4897C4.4215 18.1032 3.63473 17.7342 3.63473 17.7342C2.54519 16.9901 3.71723 17.0049 3.71723 17.0049C4.92169 17.0896 5.55522 18.2417 5.55522 18.2417C6.62561 20.0753 8.36416 19.5456 9.04779 19.2385C9.15682 18.4635 9.46695 17.9345 9.80951 17.6347C7.14497 17.3319 4.34341 16.3021 4.34341 11.7038C4.34341 10.394 4.8112 9.32212 5.57881 8.48379C5.45505 8.18028 5.04324 6.95962 5.69667 5.308C5.69667 5.308 6.7037 4.98534 8.99622 6.53824C9.95315 6.27157 10.9801 6.13897 12.0004 6.13381C13.0199 6.13897 14.0461 6.27157 15.0045 6.53824C17.2956 4.98534 18.3011 5.308 18.3011 5.308C18.956 6.95962 18.5442 8.18028 18.4212 8.48379C19.1903 9.32212 19.6544 10.394 19.6544 11.7038C19.6544 16.3139 16.8484 17.3283 14.1758 17.6251C14.606 17.9957 14.9898 18.7279 14.9898 19.8477C14.9898 21.4514 14.975 22.7457 14.975 23.1391C14.975 23.4603 15.1916 23.8338 15.8001 23.7167C20.5649 22.1262 24 17.6318 24 12.3314C24 5.7036 18.6267 0.331055 11.9989 0.331055Z"
      fill={color || colors.primaryText}
    />
  </svg>
);
