import React, { useState } from 'react';
import { colors } from '../../../constants/colors';
import styled from 'styled-components';

import { useToggle } from '../../../hooks/useToggle';

import { Text } from '../../general/Text';
import { Menu } from '../../general/Menu';
import { Modal } from '../../general/Modal';

type HomeLeftSideBarItemProps = {
  id: string;
  activeFolderId: string;
  name: string;
  isDrafts: boolean | undefined;
  isRecents: boolean;
  isInterviews: boolean;
  hasEditPermissions: boolean;
  navigateToRecents: () => void;
  onDeleteFolder: (folderId: string) => Promise<void>;
  onChangeFolder: (folderId: string) => void;
};

export const HomeLeftSideBarItem = (props: HomeLeftSideBarItemProps) => {
  const [coords, setCoords] = useState({ x: 0, y: 0 });

  const {
    value: isContextMenuVisible,
    setTrue: showContextMenu,
    setFalse: hideContextMenu
  } = useToggle(false);

  const {
    value: isConfirmationModalVisible,
    setTrue: showConfirmationModal,
    setFalse: hideConfirmationModal
  } = useToggle(false);

  const handleRightClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    event.preventDefault();
    event.stopPropagation();
    if (!props?.isDrafts && !props.isRecents && !props.isInterviews) {
      setCoords({ x: event.pageX, y: event.pageY });
      showContextMenu();
    } else {
      props.onChangeFolder(props.id);
    }
  };

  const handleChangeFolder = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    if (event.button === 0) {
      props.onChangeFolder(props.id);
    }
  };

  const handleDeleteFolder = async () => {
    if (props.hasEditPermissions) {
      await props.onDeleteFolder(props.id);
    }
    hideConfirmationModal();
    props.navigateToRecents();
  };

  return (
    <>
      {props.hasEditPermissions && isContextMenuVisible && (
        <Menu
          top={coords.y}
          left={coords.x}
          onHide={hideContextMenu}
          actions={[
            {
              title: 'Delete',
              onClick: showConfirmationModal,
              isDestructive: true
            }
          ]}
        />
      )}
      <styles.listItem
        isContextMenuVisible={isContextMenuVisible}
        onContextMenu={handleRightClick}
        onMouseDown={handleChangeFolder}
        capitalize
        ellipsis
        color={
          props.id === props.activeFolderId ? 'primaryText' : 'secondaryText'
        }
        fontWeight={props.id === props.activeFolderId ? 600 : 400}
      >
        {props.name}
      </styles.listItem>

      {isConfirmationModalVisible && (
        <Modal
          title={`Delete "${props.name}"`}
          description={
            'Permanently delete this folder, including all playgrounds and templates inside.'
          }
          hideSelf={hideConfirmationModal}
          action={{
            title: 'Delete folder',
            handler: handleDeleteFolder,
            isDestructive: true
          }}
        />
      )}
    </>
  );
};

type StyledListItemProps = {
  isContextMenuVisible: boolean;
};

const styles = {
  listItem: styled(Text)<StyledListItemProps>`
    cursor: pointer;
    padding: 8px 16px;
    border-radius: 6px;
    border: ${props =>
      props.isContextMenuVisible
        ? `1px solid ${colors.blueberry}`
        : `1px solid ${colors.clear}`};

    color: ${props =>
      props.isContextMenuVisible ? colors.primaryText : undefined};

    :hover {
      color: ${colors.primaryText};
    }
  `
};
