import React from 'react';
import styled from 'styled-components';
import { colors, ColorTypes } from '../../constants/colors';

type AvatarProps = {
  src: string | undefined;
  name: string;
  size?: number;
  margin?: string;
  zIndex?: number;
  boxShadow?: string;
  background?: ColorTypes;
  cursor?: string;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
};

const avatarColors = {
  a: colors.plum,
  b: colors.plum,
  c: colors.plum,
  d: colors.plum,

  e: colors.kiwi,
  f: colors.kiwi,
  g: colors.kiwi,
  h: colors.kiwi,

  i: colors.strawberry,
  j: colors.strawberry,
  k: colors.strawberry,
  l: colors.strawberry,

  m: colors.dragonfruit,
  n: colors.dragonfruit,
  o: colors.dragonfruit,
  p: colors.dragonfruit,

  q: colors.blueberry,
  r: colors.blueberry,
  s: colors.blueberry,
  t: colors.blueberry,

  u: colors.tangerine,
  v: colors.tangerine,
  w: colors.tangerine,
  x: colors.tangerine,
  y: colors.tangerine,
  z: colors.tangerine
};

export const Avatar = (props: AvatarProps) => {
  if (props.src) {
    return (
      <styles.avatarImg
        src={props.src}
        size={props.size}
        margin={props.margin}
        zIndex={props.zIndex}
        boxShadow={props.boxShadow}
        cursor={props.cursor}
        alt={'Avatar'}
        draggable={false}
        onMouseEnter={props.onMouseEnter}
        onMouseLeave={props.onMouseLeave}
      />
    );
  }

  const initial = props.name
    ?.toLowerCase()
    .charAt(0)
    .toString();

  return (
    <styles.defaultAvatar
      size={props.size}
      margin={props.margin}
      zIndex={props.zIndex}
      boxShadow={props.boxShadow}
      cursor={props.cursor}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
      background={avatarColors[initial]}
    >
      {initial?.toUpperCase()}
    </styles.defaultAvatar>
  );
};

const styles = {
  avatarImg: styled.img<
    Pick<AvatarProps, 'size' | 'margin' | 'zIndex' | 'boxShadow' | 'cursor'>
  >`
    height: ${props => (props.size ? `${props.size}px` : '30px')};
    width: ${props => (props.size ? `${props.size}px` : '30px')};
    margin: ${props => props.margin || '0px'};
    z-index: ${props => props.zIndex || 'auto'};
    box-shadow: ${props => props.boxShadow || `0px 0px 0px 2px ${colors.base}`};
    cursor: ${props => props.cursor || 'default'};
    border-radius: 50%;
    flex-shrink: 0;
    user-select: none;
  `,
  defaultAvatar: styled.div<
    Pick<
      AvatarProps,
      'size' | 'margin' | 'zIndex' | 'boxShadow' | 'background' | 'cursor'
    >
  >`
    height: ${props => (props.size ? `${props.size}px` : '30px')};
    width: ${props => (props.size ? `${props.size}px` : '30px')};
    margin: ${props => props.margin || '0px'};
    z-index: ${props => props.zIndex || 'auto'};
    box-shadow: ${props => props.boxShadow || `0px 0px 0px 2px ${colors.base}`};
    background: ${props => props.background || 'blue'};
    color: white;
    border-radius: 50%;
    flex-shrink: 0;
    font-size: ${props =>
      props.size ? `${Math.floor(props.size / 1.5)}px` : '16px'};
    line-height: ${props => (props.size ? `${props.size}px` : '30px')};
    cursor: ${props => props.cursor || 'default'};
    text-align: center;
    font-weight: 600;
    user-select: none;
  `
};
