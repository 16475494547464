import firebase from 'firebase';
import { editor, Uri } from 'monaco-editor';

import {
  TextOperation,
  FileAdapterEvent,
  FileEditorClient,
  MonacoFileAdapter
} from '.';

// Save a checkpoint every 100 edits.
const CHECKPOINT_FREQUENCY = 100;
const CHARACTERS =
  '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';

export default class FirebaseFileAdapter {
  fileRef_: firebase.database.Reference | null;
  fileId_: string;
  fileName_: string;
  currentUserId_: string;

  monacoFileAdapter?: MonacoFileAdapter;
  fileEditorClient?: FileEditorClient;

  ready_: boolean;
  zombie_: boolean;
  sent_;

  firebaseCallbacks_: Array<{
    ref: firebase.database.Reference;
    eventType: any;
    callback: VoidFunction;
    context: any;
  }> = [];

  // The next expected revision.
  revision_ = 0;

  // This is used for two purposes:
  // 1) On initialization, we fill this with the latest checkpoint and any subsequent operations and then
  //      process them all together.
  // 2) If we ever receive revisions out-of-order (e.g. rev 5 before rev 4), we queue them here until it's time
  //    for them to be handled. [this should never happen with well-behaved clients; but if it /does/ happen we want
  //    to handle it gracefully.]
  pendingReceivedRevisions_ = {};

  checkpointRevision_;
  document_?: TextOperation;

  allowedEvents_: Array<FileAdapterEvent>;
  eventListeners_ = {};

  constructor(
    ref: firebase.database.Reference,
    fileName: string,
    userId: string
  ) {
    this.fileRef_ = ref;
    this.zombie_ = false;
    this.ready_ = false;
    this.currentUserId_ = userId;
    this.fileId_ = ref.key || '';
    this.fileName_ = fileName;

    this.revision_ = 0;

    this.document_ = new TextOperation();

    this.allowedEvents_ = [
      FileAdapterEvent.ready,
      FileAdapterEvent.operation,
      FileAdapterEvent.ack,
      FileAdapterEvent.retry,
      FileAdapterEvent.change
    ];

    this.monacoFileAdapter = new MonacoFileAdapter(
      this.fileName_,
      this.document_?.apply('')
    );

    this.fileEditorClient = new FileEditorClient(this, this.monacoFileAdapter);
  }

  dispose = () => {
    var self = this;
    this.removeFirebaseCallbacks_();
    this.handleInitialRevisions_ = () => {};

    this.monacoFileAdapter?.detach();

    if (!this.ready_) {
      this.on(FileAdapterEvent.ready, function() {
        self.dispose();
      });
      return;
    }

    const existingModel = editor.getModel(
      Uri.parse(`file:///${this.fileName_}`)
    );

    if (existingModel) {
      existingModel.dispose();
    }

    this.eventListeners_ = {};
    this.fileRef_ = null;
    this.document_ = undefined;
    this.zombie_ = true;
  };

  /*
   * Send operation, retrying on connection failure. Takes an optional callback with signature:
   * function(error, committed).
   * An exception will be thrown on transaction failure, which should only happen on
   * catastrophic failure like a security rule violation.
   */
  sendOperation = (
    operation,
    callback?: (error: Error | null, success: boolean) => void
  ) => {
    var self = this;

    // If we're not ready yet, do nothing right now, and trigger a retry when we're ready.
    if (!this.ready_) {
      console.log('send operation not ready');
      this.on(FileAdapterEvent.ready, function() {
        self.trigger(FileAdapterEvent.retry);
      });
      return;
    }

    // Sanity check that this operation is valid.
    FirebaseFileAdapter.assert(
      this.document_?.targetLength === operation.baseLength,
      'sendOperation() called with invalid operation.'
    );

    // Convert revision into an id that will sort properly lexicographically.
    var revisionId = FirebaseFileAdapter.revisionToId(this.revision_);

    function doTransaction(revisionId, revisionData) {
      self.fileRef_
        ?.child('history')
        .child(revisionId)
        .transaction(
          function(current) {
            if (current === null) {
              return revisionData;
            }
          },
          function(error, committed, snapshot) {
            if (error) {
              if (error.message === 'disconnect') {
                if (self.sent_ && self.sent_.id === revisionId) {
                  // We haven't seen our transaction succeed or fail.  Send it again.
                  setTimeout(function() {
                    doTransaction(revisionId, revisionData);
                  }, 0);
                } else if (callback) {
                  callback(error, false);
                }
              } else {
                // utils.log('Transaction failure!', error);
                throw error;
              }
            } else {
              if (callback) callback(null, committed);
            }
          },
          /*applyLocally=*/ false
        );
    }

    this.sent_ = { id: revisionId, op: operation };
    doTransaction(revisionId, {
      a: self.currentUserId_,
      o: operation.toJSON(),
      t: firebase.database.ServerValue.TIMESTAMP
    });
  };

  isHistoryEmpty = () => {
    FirebaseFileAdapter.assert(this.ready_, 'Not ready yet.');
    return this.revision_ === 0;
  };

  monitorHistory_ = () => {
    var self = this;
    // Get the latest checkpoint as a starting point so we don't have to re-play entire history.
    this.fileRef_?.child('checkpoint').once('value', function(s) {
      if (self.zombie_) {
        return;
      } // just in case we were cleaned up before we got the checkpoint data.
      var revisionId = s.child('id').val(),
        op = s.child('o').val(),
        author = s.child('a').val();
      if (op != null && revisionId != null && author !== null) {
        self.pendingReceivedRevisions_[revisionId] = { o: op, a: author };
        self.checkpointRevision_ = FirebaseFileAdapter.revisionFromId(
          revisionId
        );
        self.monitorHistoryStartingAt_(self.checkpointRevision_ + 1);
      } else {
        self.checkpointRevision_ = 0;
        self.monitorHistoryStartingAt_(self.checkpointRevision_);
      }
    });
  };

  monitorHistoryStartingAt_ = revision => {
    var historyRef = this.fileRef_
      ?.child('history')
      .startAt(null, FirebaseFileAdapter.revisionToId(revision));

    setTimeout(() => {
      this.firebaseOn_(historyRef, 'child_added', revisionSnapshot => {
        var revisionId = revisionSnapshot.key;
        this.pendingReceivedRevisions_[revisionId] = revisionSnapshot.val();
        if (this.ready_) {
          this.handlePendingReceivedRevisions_();
        }
      });

      historyRef?.once('value', () => {
        this.handleInitialRevisions_();
      });
    }, 0);
  };

  handleInitialRevisions_ = () => {
    FirebaseFileAdapter.assert(
      !this.ready_,
      'Should not be called multiple times.'
    );

    // Compose the checkpoint and all subsequent revisions into a single operation to apply at once.
    this.revision_ = this.checkpointRevision_;
    var revisionId = FirebaseFileAdapter.revisionToId(this.revision_),
      pending = this.pendingReceivedRevisions_;
    while (pending[revisionId] != null) {
      var revision = this.parseRevision_(pending[revisionId]);
      if (!revision) {
        // If a misbehaved client adds a bad operation, just ignore it.
        // utils.log(
        //     'Invalid operation.',
        //     this.ref_.toString(),
        //     revisionId,
        //     pending[revisionId]
        // );
      } else {
        this.document_ = this.document_?.compose(revision.operation);
      }

      delete pending[revisionId];
      this.revision_++;
      revisionId = FirebaseFileAdapter.revisionToId(this.revision_);
    }

    this.trigger(FileAdapterEvent.operation, this.document_, this.document_);

    this.ready_ = true;

    setTimeout(() => {
      this.trigger(FileAdapterEvent.ready);
    }, 0);
  };

  handlePendingReceivedRevisions_ = () => {
    var pending = this.pendingReceivedRevisions_;
    var revisionId = FirebaseFileAdapter.revisionToId(this.revision_);
    var triggerRetry = false;

    while (pending[revisionId] != null) {
      this.revision_++;

      var revision = this.parseRevision_(pending[revisionId]);
      if (!revision) {
        // If a misbehaved client adds a bad operation, just ignore it.
        // utils.log(
        //     'Invalid operation.',
        //     this.ref_.toString(),
        //     revisionId,
        //     pending[revisionId]
        // );
      } else {
        this.document_ = this.document_?.compose(revision.operation);
        if (this.sent_ && revisionId === this.sent_.id) {
          // We have an outstanding change at this revision id.

          if (
            this.sent_.op.equals(revision.operation) &&
            revision.author === this.currentUserId_
          ) {
            // This is our change; it succeeded.
            if (this.revision_ % CHECKPOINT_FREQUENCY === 0) {
              this.saveCheckpoint_();
            }
            this.sent_ = null;
            this.trigger(FileAdapterEvent.ack);
          } else {
            // our op failed.  Trigger a retry after we're done catching up on any incoming ops.
            triggerRetry = true;
            this.trigger(
              FileAdapterEvent.operation,
              revision.operation,
              this.document_
            );
          }
        } else {
          this.trigger(
            FileAdapterEvent.operation,
            revision.operation,
            this.document_
          );
        }
      }
      delete pending[revisionId];

      revisionId = FirebaseFileAdapter.revisionToId(this.revision_);
    }

    if (triggerRetry) {
      this.sent_ = null;
      this.trigger(FileAdapterEvent.retry);
    }
  };

  parseRevision_ = data => {
    // We could do some of this validation via security rules.  But it's nice to be robust, just in case.
    if (typeof data !== 'object') {
      return null;
    }
    if (typeof data.a !== 'string' || typeof data.o !== 'object') {
      return null;
    }
    var op: TextOperation;
    try {
      op = TextOperation.fromJSON(data.o);
    } catch (e) {
      return null;
    }

    if (op?.baseLength !== this.document_?.targetLength) {
      return null;
    }
    return { author: data.a, operation: op };
  };

  saveCheckpoint_ = () => {
    return this.fileRef_?.child('checkpoint').set({
      a: this.currentUserId_,
      o: this.document_?.toJSON(),
      id: FirebaseFileAdapter.revisionToId(this.revision_ - 1) // use the id for the revision we just wrote.
    });
  };

  firebaseOn_ = (ref, eventType, callback, context?) => {
    this.firebaseCallbacks_.push({
      ref: ref,
      eventType: eventType,
      callback: callback,
      context: context
    });
    ref.on(eventType, callback, context);
    return callback;
  };

  firebaseOff_ = (ref, eventType, callback, context) => {
    ref.off(eventType, callback, context);
    for (var i = 0; i < this.firebaseCallbacks_.length; i++) {
      var l = this.firebaseCallbacks_[i];
      if (
        l.ref === ref &&
        l.eventType === eventType &&
        l.callback === callback &&
        l.context === context
      ) {
        this.firebaseCallbacks_.splice(i, 1);
        break;
      }
    }
  };

  getDocument = () => {
    return this.document_;
  };

  removeFirebaseCallbacks_ = () => {
    for (var i = 0; i < this.firebaseCallbacks_.length; i++) {
      var l = this.firebaseCallbacks_[i];
      l.ref.off(l.eventType, l.callback, l.context);
    }
    this.firebaseCallbacks_ = [];
  };

  updateFileName = (newName: string) => {
    this.fileName_ = newName;
    this.monacoFileAdapter?.onFileNameChanged(newName);
  };

  // EVENT HANDLERS

  on = (eventType: FileAdapterEvent, callback: VoidFunction, context?) => {
    this.validateEventType_(eventType);
    this.eventListeners_ = this.eventListeners_ || {};
    this.eventListeners_[eventType] = this.eventListeners_[eventType] || [];
    this.eventListeners_[eventType].push({
      callback: callback,
      context: context
    });
  };

  off = (eventType: FileAdapterEvent, callback: VoidFunction) => {
    this.validateEventType_(eventType);
    this.eventListeners_ = this.eventListeners_ || {};
    var listeners = this.eventListeners_[eventType] || [];
    for (var i = 0; i < listeners.length; i++) {
      if (listeners[i].callback === callback) {
        listeners.splice(i, 1);
        return;
      }
    }
  };

  trigger = (eventType: FileAdapterEvent, ...args) => {
    this.trigger_(eventType, ...[this.fileRef_?.key, this.fileName_, ...args]);
  };

  trigger_ = (eventType: FileAdapterEvent, ...args) => {
    this.eventListeners_ = this.eventListeners_ || {};
    var listeners = this.eventListeners_[eventType] || [];
    for (var i = 0; i < listeners.length; i++) {
      listeners[i].callback.apply(listeners[i].context, args);
    }
  };

  validateEventType_ = (eventType: FileAdapterEvent) => {
    if (this.allowedEvents_) {
      var allowed = false;
      for (var i = 0; i < this.allowedEvents_.length; i++) {
        if (this.allowedEvents_[i] === eventType) {
          allowed = true;
          break;
        }
      }
      if (!allowed) {
        throw new Error('Unknown event "' + eventType + '"');
      }
    }
  };

  registerCallbacks = (
    callbacks: {
      [k in FileAdapterEvent]?: (
        adapterFileId: string,
        adapterFileName?: any,
        ...args: Array<any>
      ) => void;
    }
  ) => {
    Object.keys(callbacks).forEach(eventType => {
      this.on(eventType as FileAdapterEvent, callbacks[eventType]);
    });
  };
  // Throws an error if the first argument is falsy. Useful for debugging.
  static assert = (b, msg?: string) => {
    if (!b) {
      throw new Error(msg || 'assertion error');
    }
  };

  // Based off ideas from http://www.zanopha.com/docs/elen.pdf

  static revisionToId = revision => {
    if (revision === 0) {
      return 'A0';
    }

    var str = '';
    while (revision > 0) {
      var digit = revision % CHARACTERS.length;
      str = CHARACTERS[digit] + str;
      revision -= digit;
      revision /= CHARACTERS.length;
    }

    // Prefix with length (starting at 'A' for length 1) to ensure the id's sort lexicographically.
    var prefix = CHARACTERS[str.length + 9];
    return prefix + str;
  };

  static revisionFromId = revisionId => {
    FirebaseFileAdapter.assert(
      revisionId.length > 0 &&
        revisionId[0] === CHARACTERS[revisionId.length + 8]
    );
    var revision = 0;
    for (var i = 1; i < revisionId.length; i++) {
      revision *= CHARACTERS.length;
      revision += CHARACTERS.indexOf(revisionId[i]);
    }
    return revision;
  };
}
