import { useContext, useEffect } from 'react';

import { FilesystemContext } from '../Filesystem';
import RealtimeManager, { RealtimeManagerEvent } from '../RealtimeManager';

export const useRealtimeChanges = (realtimeManager: RealtimeManager | null) => {
  const filesystem = useContext(FilesystemContext);

  useEffect(() => {
    if (!realtimeManager || !filesystem) {
      return;
    }

    const handleFileChange = (fileName: string, value: string) => {
      // TODO: Maybe debounce this
      filesystem.setFile(`/src/${fileName}`, value);
    };

    realtimeManager.on(RealtimeManagerEvent.change, handleFileChange);

    return () => {
      realtimeManager.off(RealtimeManagerEvent.change, handleFileChange);
    };
  }, [filesystem, realtimeManager]);
};
