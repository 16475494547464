import React, { useState } from 'react';

import { Modal } from '../general/Modal';
import { Input } from '../general/Input';

type PlaygroundInterviewModalProps = {
  handleJoinInterview: (name: string) => void;
};

export const PlaygroundInterviewModal = (
  props: PlaygroundInterviewModalProps
) => {
  const [nameInputValue, setNameInputValue] = useState('');

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNameInputValue(event.target.value);
  };

  const handleJoinInterview = async () => {
    if (nameInputValue) {
      props.handleJoinInterview(nameInputValue);
    }
  };

  return (
    <Modal
      title={"You're in the right place"}
      description={
        'Welcome to the interview. Enter your first and last name and you’re all set. Good luck!'
      }
      hideSelf={() => null}
      hideCancel={true}
      action={{
        title: 'Join interview',
        handler: handleJoinInterview,
        isDisabled: !nameInputValue
      }}
    >
      <Input
        placeholder={'Jessica Owens'}
        autoFocus
        margin={'32px 0px 0px 0px'}
        value={nameInputValue}
        onChange={handleInputChange}
      />
    </Modal>
  );
};
