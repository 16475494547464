import { useCallback, useContext } from 'react';
import { FirebaseContext } from '../Firebase';
import { FirestoreProjectFile } from '../declarations/FirestoreProjectFile';

const useDeleteFile = (
  playgroundId: string,
  files: Array<FirestoreProjectFile>
) => {
  const firebase = useContext(FirebaseContext);

  const deleteFile = useCallback(
    async (fileId: string) => {
      if (!firebase || files.length <= 1) {
        return;
      }

      const firestore = firebase.firestore();
      const database = firebase.database();

      await firestore
        .doc(`playgrounds/${playgroundId}/files/${fileId}`)
        .delete();

      const fileToDelete = files.find(file => file.id === fileId);

      if (fileToDelete?.realtimeDatabaseRef?.key) {
        await database
          .ref()
          .child('playgrounds')
          .child(playgroundId)
          .child('files')
          .child(fileToDelete.realtimeDatabaseRef.key)
          .remove();
      }
    },
    [firebase, playgroundId, files]
  );

  return deleteFile;
};

export default useDeleteFile;
