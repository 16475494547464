import { editor as monaco, Range, Selection, IDisposable } from 'monaco-editor';

import { Cursor, TextOperation } from '.';

/**
 * @function getCSS - For Internal Usage Only
 * @param {String} clazz - CSS Class Name
 * @param {String} bgColor - Background Color
 * @param {String} color - Font Color
 * @returns CSS Style Rules according to Parameters
 */
const getCSS = function getCSS(clazz, bgColor, color) {
  return (
    '.' +
    clazz +
    ' {\n  position: relative;\n' +
    'background-color: ' +
    bgColor +
    ';\n' +
    'border-left: 2px solid ' +
    color +
    ';\n}'
  );
};

var addedStyleRules: Array<any> = [];

/**
 * @function addStyleRule - For Internal Usage Only
 * @desc Creates style element in document head and pushed all the style rules
 * @param {String} clazz - CSS Class Name
 * @param {String} css - CSS Style Rules
 */
var addStyleRule = function addStyleRule(clazz, css) {
  /** House Keeping */
  if (typeof document === 'undefined' || document === null) {
    return false;
  }

  /** Add style rules only once */
  if (addedStyleRules.indexOf(clazz) === -1) {
    var styleElement = document.createElement('style');
    var styleSheet = document.createTextNode(css);
    styleElement.appendChild(styleSheet);
    document.head.appendChild(styleElement);
    addedStyleRules.push(clazz);
  }
};

export default class MonacoAdapter {
  /** Monaco Member Variables */
  monaco: monaco.IStandaloneCodeEditor;
  monacoModel: monaco.ITextModel | null;
  lastCursorRange: Selection | null;

  /** Monaco Editor Configurations */
  callbacks;
  otherCursors;
  addedStyleRules;
  ignoreChanges;

  /** Editor Callback Handler */
  changeHandler: IDisposable;
  didBlurHandler: IDisposable;
  didFocusHandler: IDisposable;
  didChangeCursorPositionHandler: IDisposable;
  didChangeModelHandler: IDisposable;

  constructor(monacoInstance: monaco.IStandaloneCodeEditor) {
    /** House Keeping */

    // Make sure this looks like a valid monaco instance.
    if (!monacoInstance || typeof monacoInstance.getModel !== 'function') {
      throw new Error(
        'MonacoAdapter: Incorrect Parameter Recieved in constructor, ' +
          'expected valid Monaco Instance'
      );
    }

    /** Monaco Member Variables */
    this.monaco = monacoInstance;
    this.monacoModel = this.monaco.getModel();
    this.lastCursorRange = this.monaco.getSelection();

    /** Monaco Editor Configurations */
    this.callbacks = {};
    this.otherCursors = [];
    this.addedStyleRules = [];
    this.ignoreChanges = false;

    /** Editor Callback Handler */
    this.changeHandler = this.monaco.onDidChangeModelContent(this.onChange);
    this.didBlurHandler = this.monaco.onDidBlurEditorWidget(this.onBlur);
    this.didFocusHandler = this.monaco.onDidFocusEditorWidget(this.onFocus);
    this.didChangeCursorPositionHandler = this.monaco.onDidChangeCursorPosition(
      this.onCursorActivity
    );
    this.didChangeModelHandler = this.monaco.onDidChangeModel(
      this.onModelChanged
    );
  }

  /**
   * @method detach - Clears an Instance of Editor Adapter
   */
  detach = () => {
    this.changeHandler.dispose();
    this.didBlurHandler.dispose();
    this.didFocusHandler.dispose();
    this.didChangeCursorPositionHandler.dispose();
    this.didChangeModelHandler.dispose();
    this.monacoModel = null;
    this.callbacks = {};
  };

  /**
   * @method getCursor - Get current cursor position
   * @returns Firepad Cursor object
   */
  getCursor = () => {
    var selection = this.monaco.getSelection();

    /** Fallback to last cursor change */
    if (typeof selection === 'undefined' || selection === null) {
      selection = this.lastCursorRange;
    }

    /** Obtain selection indexes */
    var startPos = selection?.getStartPosition();
    var endPos = selection?.getEndPosition();
    var start =
      startPos && this.monacoModel
        ? this.monacoModel?.getOffsetAt(startPos)
        : 0;
    var end =
      endPos && this.monacoModel ? this.monacoModel?.getOffsetAt(endPos) : 0;

    /** If Selection is Inversed */
    if (start > end) {
      var _ref = [end, start];
      start = _ref[0];
      end = _ref[1];
    }

    /** Return cursor position */
    return new Cursor(start, end);
  };

  /**
   * @method setCursor - Set Selection on Monaco Editor Instance
   * @param {Object} cursor - Cursor Position (start and end)
   * @param {Number} cursor.position - Starting Position of the Cursor
   * @param {Number} cursor.selectionEnd - Ending Position of the Cursor
   */
  setCursor = cursor => {
    var position = cursor.position;
    var selectionEnd = cursor.selectionEnd;
    var start = this.monacoModel?.getPositionAt(position);
    var end = this.monacoModel?.getPositionAt(selectionEnd);

    /** If selection is inversed */
    if (position > selectionEnd) {
      var _ref = [end, start];
      start = _ref[0];
      end = _ref[1];
    }

    if (!(start && end)) {
      return;
    }

    /** Create Selection in the Editor */
    this.monaco.setSelection(
      new Range(start.lineNumber, start.column, end.lineNumber, end.column)
    );
  };

  /**
   * @method setOtherCursor - Set Remote Selection on Monaco Editor
   * @param {Number} cursor.position - Starting Position of the Selection
   * @param {Number} cursor.selectionEnd - Ending Position of the Selection
   * @param {String} color - Hex Color codes for Styling
   * @param {any} clientID - ID number of the Remote Client
   */
  setOtherCursor = (cursor: Cursor, color: string, clientID?: string) => {
    /** House Keeping */
    if (
      typeof cursor !== 'object' ||
      typeof cursor.position !== 'number' ||
      typeof cursor.selectionEnd !== 'number'
    ) {
      return false;
    }

    if (typeof color !== 'string' || !color.match(/^#[a-fA-F0-9]{3,6}$/)) {
      return false;
    }

    /** Extract Positions */
    var position = cursor.position;
    var selectionEnd = cursor.selectionEnd;

    if (position < 0 || selectionEnd < 0) {
      return false;
    }

    /** Fetch Client Cursor Information */
    var otherCursor = this.otherCursors.find(function(cursor) {
      return cursor.clientID === clientID;
    });

    /** Initialize empty array, if client does not exist */
    if (!otherCursor) {
      otherCursor = {
        clientID: clientID,
        decoration: []
      };
      this.otherCursors.push(otherCursor);
    }

    /** Remove Earlier Decorations, if any, or initialize empty decor */
    // otherCursor.decoration = this.monaco.deltaDecorations(
    otherCursor.decoration = this.monacoModel?.deltaDecorations(
      otherCursor.decoration,
      []
    );
    var clazz = 'other-client-selection-' + color.replace('#', '');
    var css, ret;

    if (position === selectionEnd) {
      /** Show only cursor */
      clazz = clazz.replace('selection', 'cursor');

      /** Generate Style rules and add them to document */
      css = getCSS(clazz, 'transparent', color);
      ret = addStyleRule.call(this, clazz, css);
    } else {
      /** Generate Style rules and add them to document */
      css = getCSS(clazz, color, color);
      ret = addStyleRule.call(this, clazz, css);
    }

    /** Return if failed to add css */
    if (ret == false) {
      console.log(
        'Monaco Adapter: Failed to add some css style.\n' +
          "Please make sure you're running on supported environment."
      );
    }

    /** Get co-ordinate position in Editor */
    var start = this.monacoModel?.getPositionAt(position);
    var end = this.monacoModel?.getPositionAt(selectionEnd);

    if (!start || !end) {
      return;
    }

    /** Selection is inversed */
    if (position > selectionEnd) {
      var _ref = [end, start];
      start = _ref[0];
      end = _ref[1];
    }

    /** Add decoration to the Editor */
    // otherCursor.decoration = this.monaco.deltaDecorations(
    otherCursor.decoration = this.monacoModel?.deltaDecorations(
      otherCursor.decoration,
      [
        {
          range: new Range(
            start.lineNumber,
            start.column,
            end.lineNumber,
            end.column
          ),
          options: {
            className: clazz
          }
        }
      ]
    );

    /** Clear cursor method */
    var _this = this;
    return {
      clear: function clear() {
        otherCursor.decoration = _this.monacoModel?.deltaDecorations(
          otherCursor.decoration,
          []
        );
      }
    };
  };

  /**
   * @method registerCallbacks - Assign callback functions to internal property
   * @param {function[]} callbacks - Set of callback functions
   */
  registerCallbacks = callbacks => {
    this.callbacks = Object.assign({}, this.callbacks, callbacks);
  };

  /**
   * @method convertChangeEventToOperation - Convert Monaco Changes to OT.js Ops
   * @param {Object} change - Change in Editor
   * @param {string} content - Last Editor Content
   * @returns Pair of Operation and Inverse
   * Note: OT.js Operation expects the cursor to be at the end of content
   */

  static convertChangeEventToOperation = (
    changeEvent: monaco.IModelContentChangedEvent,
    code: string
  ) => {
    let operation: TextOperation | undefined;
    let inverseOperation: TextOperation | undefined;

    let composedCode = code;

    // eslint-disable-next-line no-restricted-syntax
    for (const change of [...changeEvent.changes]) {
      const newOt = new TextOperation();
      const inverseOt = new TextOperation();
      const cursorStartOffset = MonacoAdapter.lineAndColumnToIndex(
        composedCode.split(/\n/),
        change.range.startLineNumber,
        change.range.startColumn
      );

      const retain = cursorStartOffset - newOt.targetLength;

      if (retain !== 0) {
        newOt.retain(retain);
        inverseOt.retain(retain);
      }

      if (change.rangeLength > 0) {
        const replaced_text = code.slice(
          cursorStartOffset,
          cursorStartOffset + change.rangeLength
        );

        newOt.delete(change.rangeLength);
        inverseOt.insert(replaced_text);
      }

      if (change.text) {
        newOt.insert(change.text);
        inverseOt.delete(change.text);
      }

      const remaining = composedCode.length - newOt.baseLength;
      if (remaining > 0) {
        newOt.retain(remaining);
        inverseOt.retain(remaining);
      }

      operation = operation ? operation.compose(newOt) : newOt;
      inverseOperation = inverseOperation
        ? inverseOt.compose(inverseOperation)
        : inverseOt;

      composedCode = operation.apply(code);
    }

    return [operation, inverseOperation];
  };

  static lineAndColumnToIndex = (lines, lineNumber, column) => {
    let currentLine = 0;
    let index = 0;

    while (currentLine + 1 < lineNumber) {
      index += lines[currentLine].length;
      index += 1; // Linebreak character
      currentLine += 1;
    }

    index += column - 1;

    return index;
  };

  /**
   * @method onChange - OnChange Event Handler
   * @param {Object} event - OnChange Event Delegate
   */
  onChange = (event: monaco.IModelContentChangedEvent) => {
    if (!this.ignoreChanges) {
      // triggers a change, handled by bridge
      this.trigger('change', event);
    }
  };

  /**
   * @method trigger - Event Handler
   * @param {string} event - Event name
   * @param  {...any} args - Callback arguments
   */
  trigger = (event: string, ...args: Array<any>) => {
    if (!this.callbacks.hasOwnProperty(event)) {
      return;
    }

    var action = this.callbacks[event];

    // TODO: TS Fix this
    if (typeof action !== 'function') {
      return;
    }

    action(...args);
  };

  /**
   * @method onBlur - Blur event handler
   */
  onBlur = () => {
    const selection = this.monaco.getSelection();
    if (selection && selection.isEmpty()) {
      // if (this.monaco.getSelection() .isEmpty()) {
      this.trigger('blur');
    }
  };

  /**
   * @method onFocus - Focus event handler
   */
  onFocus = () => {
    this.trigger('focus');
  };

  /**
   * @method onCursorActivity - CursorActivity event handler
   */
  onCursorActivity = () => {
    var _this = this;

    setTimeout(function() {
      return _this.trigger('cursorActivity');
    }, 1);
  };

  onModelChanged = (e: monaco.IModelChangedEvent) => {
    if (e.newModelUrl) {
      const model = this.monaco.getModel();

      if (model && model.uri.path === e.newModelUrl.path) {
        this.monacoModel = model;
      } else {
        this.monacoModel = monaco.getModel(e.newModelUrl) || null;
      }

      /** Remove Earlier Decorations, if any */
      this.otherCursors.forEach(otherCursor => {
        otherCursor.decoration = this.monacoModel?.deltaDecorations(
          otherCursor.decoration,
          []
        );
      });

      // Dependencies arent 'real-time' so changes if thats the current model
      this.ignoreChanges = this.monacoModel?.uri.path === '/dependencies.json';
    }
  };

  /**
   * @method invertOperation
   * @param {Operation} operation - OT.js Operation Object
   */
  invertOperation = operation => {
    operation.invert(this.monaco.getValue());
  };
}
