export enum TeamPermissions {
  Edit = 'PERMISSIONS_EDIT',
  View = 'PERMISSIONS_VIEW',
  Owner = 'PERMISSIONS_OWNER'
}

export type FrontEndTeamPermissions = 'owner' | 'can edit' | 'can view';

export const TEAM_PERMISSIONS_TITLES: Record<
  TeamPermissions,
  FrontEndTeamPermissions
> = {
  [TeamPermissions.Edit]: 'can edit',
  [TeamPermissions.View]: 'can view',
  [TeamPermissions.Owner]: 'owner'
};

export const RANKED_TEAM_PERMISSIONS: Record<TeamPermissions, number> = {
  [TeamPermissions.View]: 0,
  [TeamPermissions.Edit]: 1,
  [TeamPermissions.Owner]: 2
};
