import React, { useState } from 'react';
import { Menu } from './Menu';

export type ContextMenuProps = {
  showContextMenu: ({ coords, actions, id }: ShowContextMenuProps) => void;
  contextMenuData: ContextMenuData;
};

type ShowContextMenuProps = {
  coords: { x: number; y: number };
  actions: { title: string; onClick: () => void; isDestructive?: boolean }[];
  id: string;
};

type ContextMenuData = null | {
  coords: { x: number; y: number };
  actions: {
    title: string;
    onClick: () => void;
    isDestructive?: boolean;
  }[];
  id: string;
};

export const withContextMenu = ReactNode => {
  const ContextMenu = props => {
    const [contextMenuData, setContextMenuData] = useState<ContextMenuData>(
      null
    );

    const hideContextMenu = () => setContextMenuData(null);

    const showContextMenu = (menuData: ShowContextMenuProps) => {
      setContextMenuData(menuData);
    };

    return (
      <>
        {contextMenuData && (
          <Menu
            actions={contextMenuData.actions}
            top={contextMenuData.coords.y}
            left={contextMenuData.coords.x}
            onHide={hideContextMenu}
          />
        )}
        <ReactNode
          {...props}
          showContextMenu={showContextMenu}
          contextMenuData={contextMenuData}
        />
      </>
    );
  };

  return ContextMenu;
};
