import React from 'react';
import styled from 'styled-components';

import { MarketingLandingPricingCard } from './MarketingLandingPricingCard';

type MarketingLandingPricingCardsProps = {
  professional?: boolean;
  onSignUp: () => void;
};

export const MarketingLandingPricingCards = (
  props: MarketingLandingPricingCardsProps
) => {
  return (
    <styles.container>
      <MarketingLandingPricingCard
        price={'Free'}
        planName={'Personal'}
        features={[
          'Unlimited playgrounds',
          'Unlimited folders',
          'Unlimited cloud storage',
          'Unlimited templates',
          'Unlimited Starter Teams'
        ]}
        onSignUp={props.onSignUp}
      />
      <MarketingLandingPricingCard
        professional
        price={'Coming soon'}
        planName={'Professional Teams'}
        features={[
          'Interviews',
          'Shared workspace',
          'Shared dependencies',
          'More soon...'
        ]}
      />
    </styles.container>
  );
};

const styles = {
  container: styled.div`
    display: flex;
    justify-content: center;
    max-width: 760px;
    margin: 96px auto;

    @media (max-width: 800px) {
      flex-direction: column;
    }
  `
};
