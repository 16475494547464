import { useContext, useCallback } from 'react';
import { FirebaseContext } from '../Firebase';

import { TeamPermissions } from '../declarations/TeamPermissions';
import { Team } from '../declarations/Team';

import {
  shouldUpgradePlaygroundPermissions,
  hasPermissionToEdit
} from '../utilities/TeamPermissionsUtilities';

import { MAX_FREE_EDITORS } from '../constants/team';
import { RequestResult } from '../declarations/RequestResult';
import { UpgradeMemberError } from '../constants/errors/invite_errors';

export const useTeamMembership = (currentUserId: string, team?: Team) => {
  const firebase = useContext(FirebaseContext);

  const updateMemberAccess = useCallback(
    async (
      userId: string,
      newPermissions: TeamPermissions,
      prevPermissions: TeamPermissions
    ): Promise<RequestResult | undefined> => {
      if (!team || !firebase) {
        return { error: '' };
      }

      if (
        !team.attributes?.isPersonal &&
        !team.subscriptionId &&
        hasPermissionToEdit(newPermissions)
      ) {
        // see if we can upgrade
        if (
          team.editors.length >= MAX_FREE_EDITORS &&
          !team.editors.find(editor => editor.id === userId)
        ) {
          return { error: UpgradeMemberError.teamEditorLimitReached };
        }
      }

      const firestore = firebase.firestore();
      const {
        onDowngradeTeamMemberPermissions,
        onUpgradeTeamMemberPermissions
      } = firebase.functions();

      const memberUpdate: Partial<Team> = {
        [`members.${userId}`]: { permissions: newPermissions }
      };

      if (newPermissions === TeamPermissions.Owner) {
        memberUpdate[`members.${currentUserId}`] = {
          permissions: TeamPermissions.Edit
        };
      }

      if (newPermissions === TeamPermissions.View) {
        onDowngradeTeamMemberPermissions({ teamId: team.id, userId });
      } else if (
        shouldUpgradePlaygroundPermissions(newPermissions, prevPermissions)
      ) {
        onUpgradeTeamMemberPermissions({ teamId: team.id, userId });
      }

      firestore.doc(`teams/${team.id}`).update(memberUpdate);

      return { success: true };
    },
    [currentUserId, firebase, team]
  );

  return { updateMemberAccess };
};
