import { FirebaseContext } from '../Firebase';
import { useContext } from 'react';

const useStripeFirestore = () => {
  const firebase = useContext(FirebaseContext);

  const addPaymentMethod = (paymentMethodId: string) => {
    if (!firebase) {
      return;
    }

    const { onAddPaymentMethod } = firebase.functions();

    try {
      return onAddPaymentMethod({ paymentMethodId });
    } catch (error) {
      return { error: 'error adding payment method' };
    }
  };

  const purchaseSubscription = ({
    teamId,
    paymentMethodId
  }: {
    teamId: string;
    paymentMethodId?: string;
  }) => {
    if (!firebase) {
      return;
    }

    const { onNewSubscription } = firebase.functions();

    try {
      return onNewSubscription({ paymentMethodId, teamId });
    } catch (error) {
      return { error: 'error purchasing subscription' };
    }
  };

  return { addPaymentMethod, purchaseSubscription };
};

export default useStripeFirestore;
