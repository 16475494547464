import React from 'react';
import styled from 'styled-components';

import { colors } from '../../../constants/colors';
import { Text } from '../general/Text';
import { Button } from '../general/Button';
import { Logo } from '../../../icons/Logo';

type MarketingLandingHeroProps = {
  onSignUp?: () => void;
  showWordMark?: boolean;
  title?: string;
  description?: string;
  showSignUp?: boolean;
};

export const MarketingLandingHero = (props: MarketingLandingHeroProps) => {
  return (
    <>
      {props.showWordMark && (
        <styles.textWrapper>
          <Text
            margin={'160px 0px 8px 0px'}
            fancyFont
            size={'titleXXL'}
            textAlign={'center'}
            style={{
              position: 'relative',
              overflow: 'visible'
            }}
          >
            Variable
            <styles.logoContainer>
              <Logo color={colors.base} size={64} />
            </styles.logoContainer>
          </Text>
        </styles.textWrapper>
      )}
      {props.title && (
        <Text
          margin={'160px auto 32px auto'}
          fancyFont
          size={'titleXL'}
          textAlign={'center'}
          wrapText
          maxWidth={800}
        >
          {props.title}
        </Text>
      )}
      {props.description && (
        <Text
          size={'bodyXL'}
          textAlign={'center'}
          wrapText
          maxWidth={480}
          margin={'auto'}
        >
          {props.description}
        </Text>
      )}
      {props.showSignUp && (
        <styles.buttonContainer>
          <Button XL onClick={props.onSignUp} primary>
            Try Variable for free
          </Button>
        </styles.buttonContainer>
      )}
    </>
  );
};

const styles = {
  buttonContainer: styled.div`
    display: flex;
    justify-content: center;
    margin-top: 48px;
  `,
  logoContainer: styled.span`
    position: absolute;
    top: -53px;
    right: -48px;
    height: 64px;
    width: 64px;

    @media (max-width: 560px) {
      top: -27px;
      right: -43px;
      transform: scale(0.75);
    }
  `,
  textWrapper: styled.div`
    position: relative;
    display: flex;
    justify-content: center;
  `
};
