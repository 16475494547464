import React from 'react';
import { colors } from '../constants/colors';
import { IconProps } from './IconProps';

export const ArrowUpIcon = ({ margin, color, size }: IconProps) => (
  <svg
    style={{ margin }}
    width={size || 24}
    height={size || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.9883 5.97656C12.3516 5.97656 12.6328 6.11719 12.9141 6.39844L17.1328 10.7109C17.332 10.9219 17.4492 11.168 17.4492 11.4727C17.4492 12.0703 17.0039 12.5156 16.4062 12.5156C16.1016 12.5156 15.8203 12.3867 15.6328 12.1875L14.168 10.6523L13.0078 9.21094L13.125 11.8242V18.0117C13.125 18.6562 12.6445 19.1133 11.9883 19.1133C11.3438 19.1133 10.8516 18.6562 10.8516 18.0117V11.8242L10.9688 9.22266L9.83203 10.6406L8.35547 12.1875C8.17969 12.375 7.88672 12.5156 7.58203 12.5156C6.98438 12.5156 6.55078 12.0703 6.55078 11.4727C6.55078 11.168 6.64453 10.9219 6.84375 10.7109L11.0859 6.39844C11.3672 6.10547 11.6484 5.97656 11.9883 5.97656Z"
      fill={color || colors.primaryText}
    />
  </svg>
);
