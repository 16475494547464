import React, { useState, useRef } from 'react';
import styled from 'styled-components';

import { colors } from '../../../constants/colors';
import { Button } from '../../general/Button';
import { PlusIcon } from '../../../icons/PlusIcon';
import { ArrowUpIcon } from '../../../icons/ArrowUpIcon';
import { LoadingSpinner } from '../../general/LoadingSpinner';

import { useToggle } from '../../../hooks/useToggle';

import {
  useKeyboardShortcut,
  KeyboardShortcutKey
} from '../../../hooks/useKeyboardShortcut';
import useTeamInviteUtility from '../../../hooks/useTeamInviteUtility';
import { Team } from '../../../declarations/Team';

type HomeTeamPageInviteInputProps = {
  activeTeam: Team;
};

export const HomeTeamPageInviteInput = ({
  activeTeam
}: HomeTeamPageInviteInputProps) => {
  const emailInputRef = useRef<HTMLInputElement | null>(null);
  const [emailInputValue, setEmailInputValue] = useState('');
  const { value: isFocused, setTrue: focus, setFalse: blur } = useToggle(false);
  const [isLoading, setIsLoading] = useState(false);

  const inviteToTeam = useTeamInviteUtility(activeTeam.id, activeTeam.name);

  const isInputValid =
    emailInputValue.includes('@') && emailInputValue.includes('.');

  const handleInputFocus = () => {
    emailInputRef?.current?.focus();
  };

  const handleSubmit = async () => {
    if (isInputValid) {
      if (activeTeam.members.find(member => member.email === emailInputValue)) {
        return;
      }

      const email = emailInputValue.toLowerCase();
      setEmailInputValue('');
      setIsLoading(true);

      await inviteToTeam(email);
      setIsLoading(false);
    }
  };

  useKeyboardShortcut(KeyboardShortcutKey.Enter, handleSubmit);

  return (
    <>
      <styles.rowItem>
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <styles.sendInviteButton
            onClick={isInputValid ? handleSubmit : handleInputFocus}
            isDisabled={
              (isFocused && emailInputValue === '') ||
              (emailInputValue !== '' && !isInputValid)
            }
            primary={isInputValid}
          >
            {isFocused || emailInputValue ? (
              <ArrowUpIcon color={colors.white} />
            ) : (
              <PlusIcon />
            )}
          </styles.sendInviteButton>
        )}

        <styles.input
          ref={emailInputRef}
          placeholder={isFocused ? 'name@example.com' : 'Invite'}
          value={emailInputValue}
          onChange={event => setEmailInputValue(event.target.value)}
          spellCheck={false}
          type={'email'}
          onFocus={focus}
          onBlur={blur}
        />
      </styles.rowItem>
    </>
  );
};

const styles = {
  rowItem: styled.div`
    display: grid;
    grid-template-columns: [avatar] 30px [input] 1fr;
    align-items: center;
    grid-gap: 16px;
    height: 50px;
  `,
  input: styled.input`
    color: ${colors.primaryText};
    font-size: 13px;
    height: 30px;
    background: ${colors.base};
    border: none;
    outline: none;
    line-height: 24px;

    ::placeholder {
      color: ${colors.secondaryText};
    }

    :focus::placeholder {
      color: ${colors.inactive};
    }
  `,
  sendInviteButton: styled(Button)`
    height: 30px;
    width: 30px;
    padding: 3px;
    flex-shrink: 0;
  `
};
