import { editor as monaco } from 'monaco-editor';
import {
  FirebaseAdapter,
  FirebaseAdapterEvent,
  MonacoAdapter,
  utils,
  EditorClient,
  TextOperation,
  RealtimeManagerEvent,
  MonacoBridge
} from '.';

export default class RealtimeManager {
  monaco_: monaco.IStandaloneCodeEditor;
  firebaseAdapter_: FirebaseAdapter;
  monacoAdapter: MonacoAdapter;
  monacoBridge: MonacoBridge;
  cursorClient_: EditorClient;
  options_;

  zombie_ = false;
  ready_ = false;

  // Event handlers
  allowedEvents_: Array<RealtimeManagerEvent>;
  eventListeners_ = {};

  constructor(
    realtimePlaygroundRef: firebase.database.Reference,
    firestoreFilesRef: firebase.firestore.CollectionReference,
    activeFileId: string,
    place: monaco.IStandaloneCodeEditor,
    options: {
      userId: string;
    }
  ) {
    this.zombie_ = false;

    this.monaco_ = place;

    // Don't allow drag/drop because it causes issues.  See https://github.com/firebase/firepad/issues/36
    const editorWrapper = this.monaco_.getDomNode();
    utils.on(editorWrapper, 'dragstart', utils.stopEvent);

    this.options_ = options || {};

    var userId = this.getOption('userId', null);
    var userColor = this.getOption(
      'userColor',
      RealtimeManager.colorFromUserId(userId)
    );

    this.monacoAdapter = new MonacoAdapter(this.monaco_);

    this.firebaseAdapter_ = new FirebaseAdapter(
      realtimePlaygroundRef,
      firestoreFilesRef,
      activeFileId,
      userId,
      userColor
    );

    this.cursorClient_ = new EditorClient(
      this.firebaseAdapter_,
      this.monacoAdapter
    );

    this.monacoBridge = new MonacoBridge(
      this.firebaseAdapter_,
      this.monacoAdapter
    );

    // this.client_ = new EditorClient(this.firebaseAdapter_, this.monacoAdapter);

    var self = this;
    this.firebaseAdapter_.on(FirebaseAdapterEvent.cursor, (...args) => {
      // only trigger cursors in active file
      if (args[3] === this.firebaseAdapter_.activeFileId) {
        self.trigger(RealtimeManagerEvent.cursor, ...args);
      } else {
        self.trigger(RealtimeManagerEvent.cursor, args[0]);
      }
    });
    this.firebaseAdapter_.on(FirebaseAdapterEvent.presenceChange, (...args) => {
      self.trigger(RealtimeManagerEvent.presence, ...args);
    });

    this.firebaseAdapter_.on(FirebaseAdapterEvent.ready, function() {
      self.ready_ = true;

      var defaultText = self.getOption('defaultText', null);
      if (defaultText && self.isHistoryEmpty()) {
        self.setText(defaultText);
      }

      self.trigger(RealtimeManagerEvent.ready);
    });

    this.firebaseAdapter_.on(FirebaseAdapterEvent.change, this.onFileChanged);

    this.allowedEvents_ = [
      RealtimeManagerEvent.change,
      RealtimeManagerEvent.cursor,
      RealtimeManagerEvent.ready,
      RealtimeManagerEvent.synced,
      RealtimeManagerEvent.presence
    ];

    // Hack for IE8 to make font icons work more reliably.
    // http://stackoverflow.com/questions/9809351/ie8-css-font-face-fonts-only-working-for-before-content-on-over-and-sometimes
    // if (
    //   navigator.appName == 'Microsoft Internet Explorer' &&
    //   navigator.userAgent.match(/MSIE 8\./)
    // ) {
    //   window.onload = function() {
    //     var head = document.getElementsByTagName('head')[0],
    //       style = document.createElement('style');
    //     style.type = 'text/css';
    //     style.styleSheet.cssText = ':before,:after{content:none !important;}';
    //     head.appendChild(style);
    //     setTimeout(function() {
    //       head.removeChild(style);
    //     }, 0);
    //   };
    // }
  }

  dispose = () => {
    this.zombie_ = true; // We've been disposed.  No longer valid to do anything.

    this.firebaseAdapter_.dispose();
    this.monacoAdapter.detach();
  };

  setUserId = userId => {
    this.firebaseAdapter_.setUserId(userId);
  };

  setUserColor = color => {
    this.firebaseAdapter_.setColor(color);
  };

  getText = () => {
    this.assertReady_('getText');

    return this.monaco_.getModel()?.getValue();
  };

  getOption = (option, def) => {
    return option in this.options_ ? this.options_[option] : def;
  };

  setText = textPieces => {
    this.assertReady_('setText');
    if (this.monaco_) {
      return this.monaco_.getModel()?.setValue(textPieces);
    }

    this.monacoAdapter.setCursor({ position: 0, selectionEnd: 0 });
  };

  onFileChanged = (fileName: string, document?: TextOperation) => {
    try {
      const text = document?.apply('');
      this.trigger(RealtimeManagerEvent.change, fileName, text);
    } catch (e) {
      // ignore errors..
    }
  };

  setActiveFile = (fileId: string) => {
    this.firebaseAdapter_.setActiveFileId(fileId);
  };

  assertReady_ = funcName => {
    if (!this.ready_) {
      throw new Error(
        'You must wait for the "ready" event before calling ' + funcName + '.'
      );
    }
    if (this.zombie_) {
      throw new Error(
        "You can't use a Firepad after calling dispose()!  [called " +
          funcName +
          ']'
      );
    }
  };

  isHistoryEmpty = () => {
    this.assertReady_('isHistoryEmpty');
    return !!this.firebaseAdapter_.activeAdapter()?.isHistoryEmpty();
  };

  saveCheckpoint = async () => {
    // return this.firebaseAdapter_.saveCheckpoint_();
  };

  // EVENT EMIITER STUFF

  on = (
    eventType: RealtimeManagerEvent,
    callback: (...args: Array<any>) => void,
    context?
  ) => {
    this.validateEventType_(eventType);
    this.eventListeners_ = this.eventListeners_ || {};
    this.eventListeners_[eventType] = this.eventListeners_[eventType] || [];
    this.eventListeners_[eventType].push({
      callback: callback,
      context: context
    });
  };

  off = (
    eventType: RealtimeManagerEvent,
    callback: (...args: Array<any>) => void
  ) => {
    this.validateEventType_(eventType);
    this.eventListeners_ = this.eventListeners_ || {};
    var listeners = this.eventListeners_[eventType] || [];
    for (var i = 0; i < listeners.length; i++) {
      if (listeners[i].callback === callback) {
        listeners.splice(i, 1);
        return;
      }
    }
  };

  trigger = (eventType: string, ...args) => {
    this.eventListeners_ = this.eventListeners_ || {};
    var listeners = this.eventListeners_[eventType] || [];
    for (var i = 0; i < listeners.length; i++) {
      listeners[i].callback.apply(listeners[i].context, args);
    }
  };

  validateEventType_ = (eventType: RealtimeManagerEvent) => {
    if (this.allowedEvents_) {
      var allowed = false;
      for (var i = 0; i < this.allowedEvents_.length; i++) {
        if (this.allowedEvents_[i] === eventType) {
          allowed = true;
          break;
        }
      }
      if (!allowed) {
        throw new Error('Unknown event "' + eventType + '"');
      }
    }
  };

  static colorFromUserId = userId => {
    var a = 1;
    for (var i = 0; i < userId.length; i++) {
      a = (17 * (a + userId.charCodeAt(i))) % 360;
    }
    var hue = a / 360;

    return RealtimeManager.hsl2hex(hue, 1, 0.75);
  };

  static rgb2hex = (r, g, b) => {
    function digits(n) {
      var m = Math.round(255 * n).toString(16);
      return m.length === 1 ? '0' + m : m;
    }
    return '#' + digits(r) + digits(g) + digits(b);
  };

  static hsl2hex = (h, s, l) => {
    if (s === 0) {
      return RealtimeManager.rgb2hex(l, l, l);
    }
    var var2 = l < 0.5 ? l * (1 + s) : l + s - s * l;
    var var1 = 2 * l - var2;
    var hue2rgb = function(hue) {
      if (hue < 0) {
        hue += 1;
      }
      if (hue > 1) {
        hue -= 1;
      }
      if (6 * hue < 1) {
        return var1 + (var2 - var1) * 6 * hue;
      }
      if (2 * hue < 1) {
        return var2;
      }
      if (3 * hue < 2) {
        return var1 + (var2 - var1) * 6 * (2 / 3 - hue);
      }
      return var1;
    };
    return RealtimeManager.rgb2hex(
      hue2rgb(h + 1 / 3),
      hue2rgb(h),
      hue2rgb(h - 1 / 3)
    );
  };
}
