import { useContext, useCallback } from 'react';

import { FirebaseContext } from '../Firebase';

const useDeleteTeam = (teamId: string) => {
  const firebase = useContext(FirebaseContext);

  const deleteTeam = useCallback(async () => {
    if (!firebase) {
      return;
    }

    const firestore = firebase.firestore();

    try {
      await firestore.doc(`teams/${teamId}`).delete();
    } catch (error) {
      //
    }
  }, [firebase, teamId]);

  return deleteTeam;
};

export default useDeleteTeam;
