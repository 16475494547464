import React, { useState } from 'react';
import { Modal } from '../general/Modal';
import { Input } from '../general/Input';

type HomeNewFolderModalProps = {
  onClose: () => void;
  onSubmit: (fileName: string) => Promise<void>;
};

export const HomeNewFolderModal = (props: HomeNewFolderModalProps) => {
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleSubmit = async () => {
    if (inputValue) {
      await props.onSubmit(inputValue);
      props.onClose();
    }
  };

  return (
    <Modal
      title={'New folder'}
      action={{
        title: 'Create folder',
        handler: handleSubmit,
        isDisabled: !inputValue
      }}
      hideSelf={props.onClose}
    >
      <Input
        autoFocus
        autoCorrect={'off'}
        autoComplete={'off'}
        spellCheck={false}
        placeholder={'Name'}
        margin={'24px 0px 0px 0px'}
        value={inputValue}
        onChange={handleInputChange}
      />
    </Modal>
  );
};
