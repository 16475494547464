import React, { useState } from 'react';
import { colors } from '../../constants/colors';
import styled from 'styled-components';

import analytics from '../../Analytics/Analytics';

import { useToggle } from '../../hooks/useToggle';
import { useHistory } from 'react-router-dom';

import { Text } from '../general/Text';
import { Menu } from '../general/Menu';
import { PlaygroundInfo } from '../../declarations/PlaygroundInfo';

import { getPlaygroundUrl } from '../../utilities/RouteUtilites';

type HomePlaygroundRowProps = {
  playground: PlaygroundInfo;
  description: string;
  canDeletePlayground: boolean;
  onTurnIntoTemplate?: (id: string) => void;
  onDeletePlayground: (playground: PlaygroundInfo) => void;
};

export const HomePlaygroundRowItem = (props: HomePlaygroundRowProps) => {
  const {
    value: isContextMenuVisible,
    setTrue: showContextMenu,
    setFalse: hideContextMenu
  } = useToggle(false);

  const [coords, setCoords] = useState({ x: 0, y: 0 });
  const history = useHistory();

  const copyToClipboard = () => {
    var textField = document.createElement('textarea');
    textField.innerText = getPlaygroundUrl(props.playground.id);
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
    analytics.track('share', {
      method: 'copy_link',
      location: 'homeRowItem',
      playgroundId: props.playground.id
    });
  };

  const handleOnClick = () => history.push(`/p/${props.playground.id}`);

  const handleRightClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    event.preventDefault();
    event.stopPropagation();
    setCoords({ x: event.pageX, y: event.pageY });
    showContextMenu();
  };

  const handleDeletePlayground = () =>
    props.onDeletePlayground(props.playground);

  const handleTurnIntoTemplate = () =>
    props.onTurnIntoTemplate && props.onTurnIntoTemplate(props.playground.id);

  const menuActionItems: Array<{
    title: string;
    onClick?: () => void;
    isDestructive?: boolean;
  }> = [
    {
      title: 'Copy link',
      onClick: copyToClipboard
    },
    {
      title: 'Create template',
      onClick: handleTurnIntoTemplate
    }
  ];

  if (props.canDeletePlayground) {
    menuActionItems.push(
      { title: 'divider' },
      {
        title: 'Delete',
        onClick: handleDeletePlayground,
        isDestructive: true
      }
    );
  }

  return (
    <>
      {isContextMenuVisible && (
        <Menu
          top={coords.y}
          left={coords.x}
          onHide={hideContextMenu}
          actions={menuActionItems}
        />
      )}
      <styles.container
        isContextMenuVisible={isContextMenuVisible}
        onClick={handleOnClick}
        onContextMenu={handleRightClick}
      >
        <Text ellipsis cursor={'pointer'} fontWeight={700}>
          {props.playground.name}
        </Text>
        <Text
          ellipsis
          cursor={'pointer'}
          size={'bodySmall'}
          color={'inactive'}
          margin={'2px 0px 0px 0px'}
        >
          {props.description}
        </Text>
      </styles.container>
    </>
  );
};

type StylesContainerProps = {
  isContextMenuVisible: boolean;
};

const styles = {
  container: styled.div<StylesContainerProps>`
    padding: 14px 18px;
    margin-left: -18px;
    margin-right: -18px;
    border-radius: 12px;
    box-sizing: border-box;
    cursor: pointer;

    background: ${props => props.isContextMenuVisible && colors.W4};

    :hover {
      background: ${colors.W4};
    }
  `
};
