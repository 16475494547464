import { useContext, useCallback } from 'react';
import { FirebaseContext } from '../Firebase';
import { AppUser } from '../declarations/AppUser';

const useUpdateUser = (user: firebase.User) => {
  const firebase = useContext(FirebaseContext);

  const updateUserInfo = useCallback(
    (newInfo: Partial<AppUser>) => {
      if (!firebase || !user) {
        return;
      }

      const firestore = firebase.firestore();

      const { id, ...fieldsToUpdate } = newInfo;

      const userRef = firestore.doc(`users/${user.uid}`);
      if (user.isAnonymous) {
        userRef.set(fieldsToUpdate);
      } else {
        userRef.update(fieldsToUpdate);
      }
    },
    [firebase, user]
  );

  const updateUserEmail = useCallback(
    async (newEmail: string) => {
      if (!user || user.isAnonymous) {
        return;
      }

      try {
        await user.updateEmail(newEmail);
        await updateUserInfo({ email: newEmail });
      } catch (e) {
        //
      }
    },
    [user, updateUserInfo]
  );

  const updateUserPassword = useCallback(
    async (newPassword: string) => {
      if (!user || user.isAnonymous) {
        return;
      }

      try {
        await user.updatePassword(newPassword);
      } catch (e) {
        //
      }
    },
    [user]
  );

  const updateUserName = useCallback(
    async (newName: string) => {
      if (!user) {
        return;
      }

      try {
        await user.updateProfile({ displayName: newName });
        await updateUserInfo({ name: newName });
      } catch (e) {
        //
      }
    },
    [user, updateUserInfo]
  );

  const updateUserAvatar = useCallback(
    async (newAvatar: File) => {
      if (!firebase || !user || user.isAnonymous) {
        return;
      }

      const storage = firebase.storage();

      try {
        const avatarStorageRef = storage.ref(`USER_AVATARS/${user.uid}`);
        await avatarStorageRef.put(newAvatar);

        const avatarUrl = await avatarStorageRef.getDownloadURL();
        if (!avatarUrl) {
          return;
        }

        await user.updateProfile({ photoURL: avatarUrl });
        await updateUserInfo({ avatarUrl });
      } catch (e) {
        //
      }
    },
    [firebase, user, updateUserInfo]
  );

  return {
    updateUserAvatar,
    updateUserEmail,
    updateUserName,
    updateUserPassword
  };
};

export { useUpdateUser };
